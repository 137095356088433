/* ----------------------------------------------------
    Breadcurmb Area
---------------------------------------------------- */
.ltn__breadcrumb-area {
    background-color: var(--section-bg-1);
    margin-bottom: 120px;
    padding-top: 110px;
    padding-bottom: 110px;
}
.ltn__breadcrumb-list ul {
    margin: 0;
    padding: 0;
}
.ltn__breadcrumb-list ul li {
    display: inline-block;
    margin-right: 30px;
    position: relative;
    font-weight: 700;
}
.ltn__breadcrumb-list ul li:last-child {
    margin-right: 0;
    color: var(--ltn__primary-color);
}
.ltn__breadcrumb-list ul li:after {
    position: absolute;
    content: "\f054";
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 900;
    font-size: 10px;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}
.ltn__breadcrumb-list ul li:last-child:after {
    display: none;
}

/* breadcrumb-area-2 */
.ltn__breadcrumb-area-2 {
    padding-top: 250px;
    padding-bottom: 130px;
}
.ltn__breadcrumb-inner-2 {
    display: flex;
    align-items: center;
    .section-title-area {
        margin-bottom: 0;
    }
    .ltn__section-title-2 .section-title {
        margin-bottom: 0;
    }
    .ltn__breadcrumb-list {
        margin-left: 100px;
    }
    .ltn__breadcrumb-list ul li:after {
        position: absolute;
        content: "";
        right: -20px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 10px;
        width: 2px;
        background-color: var(--ltn__color-1);
        opacity: 0.5;
    }
}

.ltn__breadcrumb-color-white {
    .ltn__breadcrumb-list {
        li {
            color: var(--ltn__color-1);
            &:last-child {
                color: var(--ltn__secondary-color);
            }
        }
    }
}

/* breadcrumb-area-3 */
.ltn__breadcrumb-area-3 {
    padding-top: 350px;
    padding-bottom: 130px;
}

/* breadcrumb-area-4 */
.ltn__breadcrumb-inner-4 {
    .section-title-area {
        margin-bottom: 0;
    }
    .ltn__breadcrumb-list ul li:last-child {
        opacity: 0.5;
    }
}
/* ----------------------------------------------------
    Responsive
---------------------------------------------------- */
@media (max-width: 767px) {
    .ltn__breadcrumb-area-2 {
        padding-top: 240px;
        padding-bottom: 80px;
    }
    .ltn__breadcrumb-inner-2 {
        flex-flow: column;
        text-align: center;
        .ltn__breadcrumb-list {
            margin-left: 0;
        }
    }
    .ltn__breadcrumb-list {
        ul {
            li {
                font-size: 14px;
            }
        }
    }
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */