$orange: #fda94f;

.room-spacing {
  &-card-height {
    height: 433.33px;
  }
}

.prop-container {
  text-align: center;
  &-card-title {
    color: $orange;
  }
}

.DayPickerInput input {
  height: 48px;
  line-height: 48px;
  width: 205px;
  padding: 0 20px;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  font-size: 15px;
}
.day-picker {
  &-input {
    background: #f3f3f3 !important;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    width: 103%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
  }
  &-popup {
    position: absolute;
  }
}

.grayout {
  opacity: 0.6; /* Real browsers */
  filter: alpha(opacity = 60); /* MSIE */
}

.disabled-link {
  pointer-events: none;
}

.fa-home {
  color: white;
  font-size: 56px !important;
  position: relative;
  right: 14px;
  bottom: 16px;
}
.fa-times-circle {
  color: white;
  font-size: 56px !important;
  position: relative;
  right: 12px;
  bottom: 15px;
}

.tour-form {
  &-container {
    width: 500px;
    position: fixed;
    bottom: 50px;
    background-color: grey;
    right: 150px;
    border-radius: 20px;
    background-color: #f6f7fb;
    z-index: 100;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
  }
}

.loader {
  // border: 16px solid #f3f3f3; /* Light grey */
  // border-top: 16px solid #3498db; /* Blue */
  // border-radius: 50%;
  // width: 120px;
  // height: 120px;
  // animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.padding {
  &-bottom {
    &-20 {
      padding-bottom: 20px;
    }
  }
}

.form-error {
  &-message {
    color: red;
    font-size: 0.8rem;
  }
  &-input {
    height: 65px;
    border: red solid 1px !important;
    border-radius: 5px;
    input,
    select,
    input:hover,
    select:hover {
      border: none !important;
    }
    overflow: hidden;
  }
}
.date-form-error {
  &-message {
    color: red;
    font-size: 0.8rem;
  }
  &-input {
    height: 47.99px;
    width: 205px;
    margin-left: 14px;
    border: red solid 1px !important;
    border-radius: 5px;
    input,
    select,
    input:hover,
    select:hover {
      border: none !important;
    }
    overflow: hidden;
  }
}

.amenities {
  &-btn {
    position: absolute;
    bottom: -40px;
    right: 68px;
    margin: 0 auto;
    transform: scale(0.7, 0.7);
    font-size: large;
  }
  &-overflow {
    height: 438px;
    overflow: scroll;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.scroll {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-button {
    height: 24px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $orange;
  }
}

.carousel {
  &-h2 {
    font-size: 16px;
  }
  &-icon-1 {
    padding: 10px 10px 0px 0px;
  }
  &-icon-2 {
    padding: 10px 10px 0px 20px;
  }
  &-ul {
    padding: 2px 0;
  }
}

.padding {
  &-top {
    &-20 {
      padding-top: 39px;
    }
  }
}

.call {
  &-font {
    font-size: 24px;
  }
  &-to-action {
    height: 100px;
  }
  &-to-action-area {
    padding-top: 20px;
    height: 10px !important;
  }
}

.cta-content {
  padding-top: 26px !important;
}

.footer {
  &-icons {
    position: absolute;
    right: -20px;
    bottom: 50px;
  }
  &-top {
    height: 50px !important;
  }
}

footer.footer-area.style-two::after {
  background-color: #f2f3f5 !important;
}

div.footer-top {
  background-color: #f2f3f5 !important;
}

.footer-area.style-two {
  margin-top: 120px !important;
}

.welcome {
  color: white;
  -webkit-text-stroke: 2px black;
  -webkit-font-smoothing: antialiased;
}
.nav-text {
  color: $orange;
  &-bordered {
    color: $orange !important;
    border-radius: 4px;
    // box-shadow: 2px 2px 0px rgba(202, 135, 63, 0.74);
    padding: 2px;
    // background-color: $orange;
  }
  &:hover {
    color: #666666 !important;
    -webkit-text-stroke: 0.2px black;
    -webkit-font-smoothing: antialiased;
  }
}

.appointment {
  display: block;
  width: 200px;
  height: 48px;
  background-color: #fda94f;
  color: white;
  border-radius: 4px;
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
}
