/* --------------------------------------------------------------
# Gutenberg	Default Style
-------------------------------------------------------------- */

.single-post-details-item .entry-content > .alignwide {
	max-width: 1100px;
}

.single-post-details-item .entry-content > .alignfull {
	margin: 1.5em 0;
	max-width: 100%;
}


.wp-block-video video {
	max-width: 636px;
}

.wp-block-image img {
	display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
	width: 100%
}

.wp-block-image.alignfull img {
	width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
	margin: 1.5em auto;
}

.wp-block-cover-text p {
	padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
	padding: 0 14px;
}

.wp-block-table {
	display: block;
	overflow-x: auto;
}

.wp-block-table table {
	border-collapse: collapse;
	width: 100%
}

.wp-block-table td, .wp-block-table th {
	padding: .5em;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.wp-block-quote.is-large {
	margin: 0 auto 16px;
}

.wp-block-pullquote>p:first-child {
	margin-top: 0;
}

.wp-block-separator {
	margin: 3em auto;
	padding: 0;
}

@media screen and (min-width: 768px) {
	.wp-block-cover-text p {
		padding: 1.5em 0;
	}
}
.wp-block-video video {
	max-width: 636px;
}

.wp-block-image img {
	display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
	width: 100%;
}

.wp-block-image.alignfull img {
	width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
	margin: 1.5em auto;
}

.wp-block-cover-text p {
	padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
	padding: 0 14px;
}

.wp-block-table {
	display: block;
	overflow-x: auto;
}

.wp-block-table table {
	border-collapse: collapse;
	width: 100%;
}

.wp-block-table td, .wp-block-table th {
	padding: .5em;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
	position: relative;
	width: 100%;
	height: 0;
	padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.wp-block-quote.is-large {
	margin: 0 auto 16px;
}

.wp-block-pullquote > p:first-child {
	margin-top: 0;
}
.wp-block-quote:not(.is-large):not(.is-style-large) {
	border-left: 4px solid #000;
	padding-left: 1em;
}
.wp-block-separator {
	margin: 3em auto;
	padding: 0;
}

@media screen and (min-width: 768px) {
	.wp-block-cover-text p {
		padding: 1.5em 0;
	}
}
.wp-block-pullquote {
	border-top: 4px solid #555d66;
	border-bottom: 4px solid #555d66;
	color: #40464d;
}
/* --------------------------------------------------------------
## Block Color Palette Colors
-------------------------------------------------------------- */
.has-strong-blue-color {
	color: #0073aa;
}

.has-strong-blue-background-color {
	background-color: #0073aa;
}

.has-lighter-blue-color {
	color: #229fd8;
}

.has-lighter-blue-background-color {
	background-color: #229fd8;
}

.has-very-light-gray-color {
	color: #eee;
}

.has-very-light-gray-background-color {
	background-color: #eee;
}

.has-very-dark-gray-color {
	color: #444;
}

.has-very-dark-gray-background-color {
	background-color: #444;
}
