/* ============================================================
>>> TABLE OF CONTENTS:
===============================================================
# Widgets
# Search Widget
# Newsletter Widget
# Tag Cloud Widget
# Color Widget
# Menu Widget
# Popular Post Widget
# Banner Widget
# Top Rated Product Widget
# Price Filter Widget
# Instagram Widget
# Video Widget
# Author Widget
# Widget Responsive

============================================================= */

/* --------------------------------------------------------------
    Widgets
-------------------------------------------------------------- */
.widget {
    margin-bottom: 40px;
    padding: 35px 30px 40px 35px;
    border: 2px solid var(--border-color-11);
    &:last-child {
        margin-bottom: 0;
    }
    hr {
        margin-top: 35px;
        margin-bottom: 35px;
    }
}
.ltn__widget-title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 25px;
    text-transform: capitalize;
}
.ltn__widget-title-border {
    position: relative;
    padding-left: 45px;
    &::after,
    &::before {
        position: absolute;
        content: "";
        background-color: var(--ltn__secondary-color);
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
    &::before {
        width: 18px;
        height: 4px;
        border-radius: 25px;
    }
    &::after {
        width: 4px;
        height: 4px;
        left: 22px;
        border-radius: 100%;
    }
}
.ltn__widget-title-border-2 {
    border-left: 2px solid;
    border-color: var(--ltn__secondary-color);
    padding-left: 10px;
    position: relative;
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 2px;
        height: 14px;
        background-color: var(--ltn__secondary-color);
        display: none;
    }
}
/* widget-2 */
.widget-2 {
    margin-bottom: 40px;
}

/* ----------------------------------------------------
    Search Widget
---------------------------------------------------- */
.ltn__search-widget form {
    position: relative;
}
.ltn__search-widget input[type="text"] {
    margin-bottom: 0;
    padding-right: 65px;
    padding-left: 20px;
    height: 60px;
    background-color: var(--section-bg-1);
    font-weight: 600;
    font-size: 14px;
}
.ltn__search-widget input[type="text"]::-webkit-input-placeholder {
    color: var(--ltn__primary-color);
    font-size: 12px;
}
.ltn__search-widget button {
    position: absolute;
    right: 0;
    height: 100%;
    padding: 0 20px;
    color: var(--white);
    border: 1px solid;
    background-color: var(--ltn__secondary-color);
    border-color: var(--ltn__secondary-color);
    transition: all 0.3s ease 0s;
}
.ltn__search-widget button:hover {
    color: var(--white);
    background-color: var(--ltn__primary-color);
    border-color: var(--ltn__primary-color);
}

/* ----------------------------------------------------
    Newsletter Widget
---------------------------------------------------- */
.ltn__newsletter-widget {
    background-color: var(--ltn__primary-color);
    padding: 50px 35px;
    position: relative;
    z-index: 2;
    h6 {
        color: var(--ltn__secondary-color);
        margin-bottom: 5px;
    }
    h4 {
        color: var(--white);
        font-size: 36px;
    }
    input[type="text"] {
        background-color: #133236;
        color: var(--white);
        border-color: #133236;
        height: 70px;
        padding-left: 25px;
        padding-right: 70px;
    }
    input[type="text"]::placeholder {
            color: var(--white);
    }
    button {
        background-color: var(--ltn__secondary-color);
        border-color: var(--ltn__secondary-color);
        color: var(--white);
        padding: 0 25px;
        &:hover {
            background-color: var(--ltn__secondary-color-2);
            border-color: var(--ltn__secondary-color-2);
            color: var(--white);
        }
    }
}
.ltn__newsletter-bg-icon {
    position: absolute;
    right: 20px;
    top: 15%;
    font-size: 120px;
    color: var(--ltn__color-1);
    opacity: 0.1;
    z-index: -1;
    line-height: 1;
}

/* ----------------------------------------------------
    Tag Cloud Widget
---------------------------------------------------- */
.ltn__tagcloud-widget {
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            display: inline-block;
            margin: 5px 3px;
            a {
                display: block;
                padding: 8px 24px 6px;
                font-size: 13px;
                text-transform: uppercase;
                background-color: var(--section-bg-1);
                font-weight: 700;
                font-family: var(--ltn__heading-font);
                &:hover {
                    background-color: var(--ltn__secondary-color);
                    color: var(--white);
                }
            }
        }
    }
}

/* ----------------------------------------------------
    Color Widget
---------------------------------------------------- */
.ltn__color-widget {
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            display: inline-block;
            margin-top: 3px;
            margin-right: 5px;
            background-color: var(--section-bg-1);
            border: 1px solid var(--border-color-1);
            a {
                width: 40px;
                height: 22px;
                display: block;
            }
            &:hover {
                outline: 2px solid var(--ltn__secondary-color);
                outline-offset: 1px;
            }
        }
        .black {
            background-color: var(--black);
        }
        .white {
            background-color: var(--white);
        }
        .red {
            background-color: var(--red);
        }
        .silver {
            background-color: var(--silver);
        }
        .gray {
            background-color: var(--gray);
        }
        .maroon {
            background-color: var(--maroon);
        }
        .yellow {
            background-color: var(--yellow);
        }
        .olive {
            background-color: var(--olive);
        }
        .lime {
            background-color: var(--lime);
        }
        .green {
            background-color: var(--green);
        }
        .aqua {
            background-color: var(--aqua);
        }
        .teal {
            background-color: var(--teal);
        }
        .blue {
            background-color: var(--blue);
        }
        .navy {
            background-color: var(--navy);
        }
        .fuchsia {
            background-color: var(--fuchsia);
        }
        .purple {
            background-color: var(--purple);
        }
        .pink {
            background-color: var(--pink);
        }
        .nude {
            background-color: var(--nude);
        }
        .orange {
            background-color: var(--orange);
        }
    }
}

/* ----------------------------------------------------
    Menu Widget
---------------------------------------------------- */
.ltn__menu-widget {
    & > ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            margin-top: 15px;
            display: block;
            font-size: 14px;
            font-weight: 700;
            a {
                display: block;
                font-size: 14px;
                font-weight: 700;
                span {
                    float: right;
                    color: var(--ltn__color-5);
                }
            }
            span {
                float: right;
                color: var(--ltn__color-5);
            }
        }
    }
}

/* menu-widget-2 */
.ltn__menu-widget-2 {
    background-color: transparent;
    ul {
        li {
            a {
                background-color: var(--section-bg-1);
                padding: 20px 10px 20px 30px;
                span {
                    background-color: var(--white);
                    color: var(--ltn__primary-color);
                    position: relative;
                    top: -11px;
                    width: 50px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    transition: all 0.3s ease 0s;
                }
            }
            &.active,
            &:hover > {
                a {
                    background-color: var(--ltn__secondary-color);
                    color: var(--white);
                    span {
                        background-color: var(--ltn__secondary-color-2);
                        color: var(--white);
                        &:hover {
                            background-color: var(--ltn__primary-color);
                            color: var(--white);
                        }
                    }
                }
            }
        }
    }
    & > ul {
        & > li {
            &:first-child {
                margin-top: 0;
            }
        }
    }
    &.ltn__menu-widget-2-color-2 {
        ul {
            li {
                a {
                    background-color: var(--ltn__primary-color);
                    color: var(--white);
                    span {
                        background-color: var(--ltn__primary-color-2);
                        color: var(--white);
                    }
                }
                &.active,
                &:hover > {
                    a {
                        background-color: var(--ltn__secondary-color);
                        color: var(--white);
                        span {
                            background-color: var(--ltn__secondary-color-2);
                            color: var(--white);
                            &:hover {
                                background-color: var(--ltn__primary-color);
                                color: var(--white);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ----------------------------------------------------
    Popular Post Widget
---------------------------------------------------- */
.ltn__popular-post-widget { 
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
        }
    }
}
.ltn__popular-post-widget > ul > li {
    margin-bottom: 30px;
    border-bottom: 1px solid var(--border-color-1);
    padding-bottom: 30px;
}
.ltn__popular-post-widget > ul > li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0px;
}
.popular-post-widget-img {
    float: left;
    margin-right: 20px;
    img {
        max-width: 80px;
    }
}
.popular-post-widget-brief {
    overflow: hidden;
    h6 {
        margin-bottom: 5px;
        font-weight: 500;
        font-size: 14px;
    }
    .ltn__blog-meta {
        margin-bottom: 0;
        li {
            color: var(--ltn__secondary-color);
        }
    }
}

.ltn__twitter-post-widget {
    .popular-post-widget-img {
        float: left;
        margin-right: 20px;
        img {
            max-width: 60px;
            border-radius: 100%;
        }
        a {
            color: var(--ltn__secondary-color);
        }
    }
    .popular-post-widget-brief {
        p {
           margin-bottom: 5px;
            a {
                color: var(--ltn__secondary-color);
            } 
        }
    }
}

/* ----------------------------------------------------
    Banner Widget
---------------------------------------------------- */
.ltn__banner-widget {
    padding: 0 !important;
    border: 0;
}

/* ----------------------------------------------------
    Top Rated Product Widget
---------------------------------------------------- */
.ltn__top-rated-product-widget {
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
        }
    }
} 
.ltn__top-rated-product-widget > ul > li {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid;
    border-color: var(--white-4);
}
.ltn__top-rated-product-widget > ul > li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}
.top-rated-product-img {
    max-width: 90px;
    float: left;
    margin-right: 20px;
}
.top-rated-product-info {
    overflow: hidden;
    h6 {
        margin-bottom: 3px;
        font-size: 14px;
        font-weight: 500;
    }
    .product-ratting {
        margin-bottom: 0;
        li {
            font-size: 10px;
        }
    }
    .product-price {
        margin-bottom: 0;
        font-size: 14px;
        color: var(--ltn__secondary-color);
        font-weight: 700;
    }
    .product-ratting ul {
        line-height: 1;
        margin-bottom: 5px;
    }
}

/* ----------------------------------------------------
    Popular Product Widget
---------------------------------------------------- */
.ltn__popular-product-widget {
    .ltn__product-item {
        margin-bottom: 30px;
        .product-info {
            padding: 25px 25px 1px;
        }
        .product-title {
            font-size: 16px;
        }
        .product-img-location ul li,
        .ltn__plot-brief li {
            font-size: 12px;
        }
    }
    .slick-dots {
        margin-bottom: 0;
    }
}

/* ----------------------------------------------------
    Price Filter Widget
---------------------------------------------------- */
.price_filter {
  padding-bottom: 13px;
}
.price_slider_amount {
  overflow: hidden;
}
.price_slider_amount > input[type="text"],
.price_slider_amount > input[type="submit"] {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  box-shadow: none;
  float: left;
  height: 25px;
  padding: 0;
  text-align: left;
  width: 140px;
  margin-bottom: 20px;
}
.price_slider_amount > input[type="text"] {
  font-weight: 700;
}
.price_filter .ui-widget-content {
  background-color: var(--ltn__primary-color);
  border: medium none;
  color: #222;
  height: 3px;
  width: 98%;
  background-image: none;
}
.price_filter .ui-slider .ui-slider-range {
  background-color: var(--ltn__secondary-color);
  background-image: none;
}
.price_filter .ui-state-default, 
.price_filter .ui-widget-content .ui-state-default, 
.price_filter .ui-widget-header .ui-state-default {
    background-color: var(--ltn__secondary-color);
  border: medium none;
  border-radius: 100%;
  height: 12px;
  margin-left: 0;
  margin-top: -6px;
  top: 50%;
  width: 12px;
  background-image: none;
}

/* ----------------------------------------------------
    Instagram Widget
---------------------------------------------------- */
.ltn__instafeed-grid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
   .instagram_gallery {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        width: 100%;
        a,
        .instagram-image {
            display: block;
            -webkit-box-flex: 1;
            -webkit-flex: 1 0 16.6666%;
            -ms-flex: 1 0 16.6666%;
            flex: 1 0 16.6666%;
            max-width: 16.6666%;
        }
    } 
} 
.ltn__instagram-widget {
    .ltn__instafeed-grid {
       .instagram_gallery {
            a,
            .instagram-image {
                -webkit-flex: 0 0 33.333333%;
                -ms-flex: 0 0 33.333333%;
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
            }
        } 
    }  
}
.ltn__instafeed-slider {
   .slick-list {
        .slick-slide {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
        }
    } 
} 

.insta-slide-gutter {
    .slick-list {
        margin-right: -7.5px !important;
        margin-left: -7.5px !important;
        .slick-slide {
            padding-right: 7.5px;
            padding-left: 7.5px;
        }
    }
}
.insta-grid-gutter {
    .instagram_gallery {
        margin-right: -7.5px !important;
        margin-left: -7.5px !important;
        a,
        .instagram-image {
            padding-right: 7.5px;
            padding-left: 7.5px;
            margin: 7px 0;
        }
    } 
}

/* ----------------------------------------------------
    Video Widget
---------------------------------------------------- */
.ltn__video-icon-1 {
    background-color: transparent;
    color: var(--white);
    height: 80px;
    width: 80px;
    border: 6px solid;
    border-color: var(--white);
    border-radius: 100%;
    font-size: 22px;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
        background-color: var(--ltn__secondary-color);
        color: var(--white);
    }
}
.ltn__video-icon-2 {
    height: 80px;
    width: 80px;
    background-color: var(--white);
    color: var(--ltn__secondary-color);
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    border-radius: 100%;
    i {
        color: var(--ltn__secondary-color);
    }
    &:hover {
        &.ltn__video-icon-2-border {
            outline: outset;
        }
    }
    &.border-radius-no {
        border-radius: 0;
    }
}
.ltn__video-icon-2-border {
    outline-offset: 15px;
    outline: 4px solid var(--border-color-9);
    margin: 20px;
}
.ltn__video-bg-img {
    min-height: 350px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    z-index: 99;
}
.ltn__video-img {
    position: relative;
    display: inline-block;
    &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background-color: var(--ltn__primary-color);
    }
    img {
        margin: 0;
    }
    .ltn__video-icon-1,
    .ltn__video-icon-2 {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}
.ltn__video-info {
    position: relative;
}
.ltn__video-popup-height-300 {
    height: 300px;
}
.ltn__video-popup-height-400 {
    height: 400px;
}
.ltn__video-popup-height-500 {
    height: 500px;
}
.ltn__video-popup-height-600 {
    height: 600px;
}
.text-center {
    .slide-video {
        display: flex;
        justify-content: center;
    }
}
.ltn__secondary-bg {
    &.ltn__video-icon-2 {
        background-color: var(--ltn__secondary-color);
        color: var(--white);
    }
    &.ltn__video-icon-2 i {
        color: var(--white);
    }
}
.ltn__video-icon-2-small {
    height: 60px;
    width: 60px;
    font-size: 16px;
}

@media (min-width: 992px) and (max-width: 1199px) {
    .ltn__video-popup-height-500,
    .ltn__video-popup-height-600 {
        height: 450px;
    }
}
@media (max-width: 991px) {
    .ltn__video-icon-2 {
        height: 50px;
        width: 50px;
        font-size: 18px;
    }
    .ltn__video-popup-height-500,
    .ltn__video-popup-height-600 {
        height: initial;
    }
}
@media (max-width: 767px) {
    .ltn__video-bg-img {
        min-height: 320px;
        &.ml-30 {
            margin-left: 0;
        }
    }
    .ltn__video-img,
    .ltn__video-bg-img {
        &.ml-30 {
            margin-left: 0;
        }
    }
}

/* ----------------------------------------------------
    Author Widget
---------------------------------------------------- */
.ltn__author-widget-inner {
    img {
        margin-bottom: 25px;
        max-width: 140px;
        border-radius: 100%;
    }
    p {
        font-size: 14px;
    }
    .ltn__social-media ul li {
        color: var(--ltn__color-1);
    }
}

.ltn__author-widget-2 {
    .ltn__author-widget-inner {
        img {
            margin-bottom: 25px;
            max-width: 100%;
            border-radius: inherit;
        }
    }
}
/* ----------------------------------------------------
    Footer About Widget
---------------------------------------------------- */
@media (min-width: 992px) {
    .footer-about-widget {
        padding-right: 35px;
    }
}
/* ----------------------------------------------------
    Widget Responsive
---------------------------------------------------- */
@media (max-width: 1199px) {
    .widget {
        padding: 35px 20px 40px 20px;
    }
    .top-rated-product-img {
        max-width: 70px;
        margin-right: 15px;
    }
}

@media (max-width: 991px) {
    .ltn__right-sidebar {
        margin-top: 100px;
    }
}

@media (max-width: 767px) {
    .car-price-filter-range .price_filter .ui-state-default:last-child::after {
        display: none;
    }
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */