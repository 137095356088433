.contact-form {
  margin-left: 700px;
  &-wrap.micro-2 {
    width: 420px;
    padding-top: 14px;
  }
}

.details-map {
  height: 580px;
}

i.contact-icon {
  color: #0f3136;
}

.contact-icon {
  color: white;
}

.contact-link:hover .contact-icon {
  color: #5ba600 !important;
}