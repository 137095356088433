i.contact-icon {
  color: white;
}

.contact-link:hover .contact-icon-3 {
  color: #e53e29 !important;
}

// Temp fix for Microsite 3
.row {
  overflow:hidden;
}