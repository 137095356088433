
/* -------------------------
    ## Preloader Css
--------------------------- */
.pre-wrap{
    position: fixed;
    content: '';
    transform: translate(-100%, -240%);
    font-size:62px;
}
.preloader-inner {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999999;
    background-color: rgba(7, 28, 31, 1);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .cancel-preloader{
        position: absolute;
        bottom: 30px;
        right: 30px;
       a{
            background-color: var(--white);
            font-weight: 600;
            text-transform: capitalize;
            color: var(--ltn__primary-color);
            width: 200px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            border-radius: 30px;
            display: block;
            transition: all 0.3s ease 0s;
            &:hover{
                background-color: var(--ltn__secondary-color);
                color: var(--white);
            }
       }
    }
}
.spinner {
  margin: 120px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  
  -webkit-animation: sk-rotate 2.0s infinite linear;
  animation: sk-rotate 2.0s infinite linear;
}

.dot1, .dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--ltn__secondary-color);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate { 100% { -webkit-transform: rotate(360deg) }}
@keyframes sk-rotate { 100% { transform: rotate(360deg); -webkit-transform: rotate(360deg) }}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
