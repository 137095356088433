/*
    Template Name: Quarter
    Description: Real Estate HTML Template
    Version: 1.0.0
*/
/* Single side border-radius  */
/* BORDER RADIUS */
/* user select */
/* box sizing  */
/* placeholder */
/* transition  */
/* transform */
/* rotate */
/* scale */
/* translate */
/* translate rotate */
/* skew */
/* ============================================================
>>> TABLE OF CONTENTS:
===============================================================
# Google fonts
# Gutter Code 
# Normalize
# Typography
# Custom Class
# input and button type focus outline disable
# Form input box
# Text meant only for screen readers.
# Transition

# Accessibility
# Globals

# Alignments
# Clearings
# Posts and pages
# Captions
# Galleries
# Unit test
# ScrollUp
# Owl Carousel 
# Slick Slider
# Background Overlay
# Scrollbar
# Padding Top
# Padding Bottom
# Margin Top
# Margin Bottom
# Custom margin Padding

============================================================= */
/* -------------------------------------
    Google fonts
------------------------------------- */
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Rajdhani:wght@400;500;600;700&display=swap'); */
/*
font-family: 'Open Sans', sans-serif;
font-family: 'Rajdhani', sans-serif;
*/
/* @import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&family=Titillium+Web:wght@400;600;700;900&display=swap'); */
/*
font-family: 'Nunito Sans', sans-serif;
font-family: 'Titillium Web', sans-serif;
*/
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&family=Poppins:wght@400;500;600;700;800;900&display=swap");
/*
font-family: 'Nunito Sans', sans-serif;
font-family: 'Poppins', sans-serif;
*/
/* ====================================================
    Gutter Code 
==================================================== */
.container,
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 15px;
  padding-left: 15px;
  position: relative;
}

.ltn__no-gutter > [class*="col-"] {
  /* No padding only for child columns */
  padding-right: 0;
  padding-left: 0;
}

.ltn__no-gutter-all [class*="col-"] {
  /* No padding for every columns */
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 992px) {
  /* Modify this based on column def */
  .ltn__custom-gutter {
    margin-left: -0px;
    margin-right: -0px;
  }
  .ltn__custom-gutter > [class*="col-"] {
    padding-right: 0px;
    padding-left: 0px;
  }
  .ltn__custom-gutter-all .row {
    margin-left: -15px;
    margin-right: -15px;
  }
  .ltn__custom-gutter-all .row [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 991px) {
  /* Modify this based on column def */
  .ltn__custom-gutter {
    margin-left: -15px;
    margin-right: -15px;
  }
  .ltn__custom-gutter > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
  .ltn__custom-gutter-all {
    margin-left: -15px;
    margin-right: -15px;
  }
  .ltn__custom-gutter-all [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
}

@media (max-width: 767px) {
  /* Modify this based on column def */
  .ltn__product-gutter .row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .ltn__product-gutter [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px;
  }
}

/* ----------------------------------------------------
    Normalize    0B2C3D
---------------------------------------------------- */
:root {
  --ltn__primary-color: #000000;
  --ltn__primary-color-2: #041113;
  /* Darken */
  --ltn__primary-color-3: #133236;
  /* Lighten */
  --ltn__secondary-color: #ff5a3c;
  --ltn__secondary-color-2: #cb3421;
  /* Darken */
  --ltn__secondary-color-3: #fb412a;
  /* Lighten */
  --ltn__heading-color: #071c1f;
  --ltn__paragraph-color: #5c727d;
  --ltn__heading-font: "Poppins", sans-serif;
  --ltn__body-font: "Nunito Sans", sans-serif;
  /* Footer ACD2D8 8CB2B2  */
  --ltn__color-1: #8cb2b2;
  --ltn__color-2: #acd2d8;
  --ltn__color-3: #a3bcc0;
  --ltn__color-4: #84a2a6;
  --ltn__color-5: #5c727d;
  --ltn__color-6: #f5dfdc;
  /* Gradient Colors */
  --gradient-color-1: linear-gradient(
    90deg,
    rgba(242, 139, 194, 1) 0%,
    rgba(216, 177, 242, 1) 50%
  );
  --gradient-color-2: linear-gradient(
    to top,
    rgba(7, 28, 31, 0) 0%,
    rgba(7, 28, 31, 1) 90%
  );
  --gradient-color-3: linear-gradient(
    to bottom,
    rgba(7, 28, 31, 0) 0%,
    rgba(7, 28, 31, 1) 90%
  );
  --gradient-color-4: linear-gradient(
    to top,
    rgba(242, 246, 247, 0) 0%,
    rgba(242, 246, 247, 1) 90%
  );
  --gradient-color-5: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 70%
  );
  /* Background Colors ( 1, 2, 3, 4, 5 )  */
  --section-bg-1: #f2f6f7;
  /* White */
  --section-bg-2: #171b2a;
  /* Black */
  --section-bg-5: #fff2f0;
  /* White */
  --section-bg-6: #0b2c3d;
  /* Black */
  --section-bg-7: #282b38;
  /* Black */
  /* Border Colors */
  --border-color-1: #e5eaee;
  /* White */
  --border-color-2: #1e2021;
  /* Black */
  --border-color-3: #5c617f;
  /* Black */
  --border-color-4: #eb6954;
  /* Red */
  --border-color-5: #bc3928;
  /* Red */
  --border-color-6: #103034;
  /* Black */
  --border-color-7: #d1dae0;
  /* White */
  --border-color-8: #f6f6f6;
  /* White */
  --border-color-9: #e4ecf2;
  /* White */
  --border-color-10: #ebeeee;
  /* White */
  --border-color-11: #ededed;
  /* White */
  --border-color-12: #e1e6ff;
  /* White */
  --border-color-13: #f1f8ff;
  /* White */
  /* Box Shadow Colors */
  --ltn__box-shadow-1: 0 16px 32px 0 rgba(7, 28, 31, 0.1);
  --ltn__box-shadow-2: 0 0 4px rgba(0, 0, 0, 0.1);
  /* like border */
  --ltn__box-shadow-3: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  /* like border GGL */
  --ltn__box-shadow-4: 0 5px 20px 0 rgba(23, 44, 82, 0.1);
  --ltn__box-shadow-5: 0 8px 16px 0 rgba(93, 93, 93, 0.1);
  --ltn__box-shadow-6: 0 0 25px 2px rgba(93, 93, 93, 0.2);
  --ltn__box-shadow-7: 0 0 10px 0px rgba(93, 93, 93, 0.2);
  /* Common Colors */
  --black: #000000;
  --black-2: #22355b;
  --white: #fff;
  --white-2: #f2f6f7;
  --white-3: #e8edee;
  --white-4: #e6ecf0;
  --white-5: #f0f4f7;
  --white-6: #f1f1f1;
  --white-7: #f7f7f7;
  --white-8: #fafafa;
  --white-9: #f2f7fa;
  --white-10: #ededed;
  --red: #ff0000;
  --red-2: #f34f3f;
  --red-3: #db483b;
  --silver: #c0c0c0;
  --gray: #808080;
  --maroon: #800000;
  --yellow: #ffff00;
  --olive: #808000;
  --lime: #00ff00;
  --green: #77c720;
  --green-2: #008000;
  --aqua: #00ffff;
  --teal: #008080;
  --blue: #0000ff;
  --navy: #000080;
  --fuchsia: #b13bff;
  --purple: #800080;
  --pink: #ffc0cb;
  --nude: #ebc8b2;
  --orange: #ffa500;
  --ratings: #ffb800;
  /* social media colors */
  --facebook: #365493;
  --twitter: #3cf;
  --linkedin: #0077b5;
  --pinterest: #c8232c;
  --dribbble: #ea4c89;
  --behance: #131418;
  --google-plus: #dd4b39;
  --instagram: #e4405f;
  --vk: #3b5998;
  --wechat: #7bb32e;
  --youtube: #cb2027;
  --email: #f89a1e;
}

/* -------------------------------------
    Typography
------------------------------------- */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: var(--ltn__body-font);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

body {
  color: var(--ltn__paragraph-color);
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  font-family: var(--ltn__body-font);
  line-height: 1.8;
  margin: 0 auto;
}

h1,
.h1 {
  font-size: 36px;
  line-height: 1.2;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 24px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--ltn__heading-color);
  clear: both;
  font-family: var(--ltn__heading-font);
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 15px;
}

p {
  color: var(--ltn__paragraph-color);
  margin-bottom: 1.5em;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

a {
  color: inherit;
  text-decoration: none;
  color: var(--ltn__paragraph-color);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: inherit;
}

a:hover {
  color: var(--ltn__secondary-color);
}

button,
input,
optgroup,
select,
textarea {
  font-family: var(--ltn__body-font);
}

pre,
code,
kbd,
tt,
var,
samp {
  font-family: var(--ltn__body-font);
}

pre {
  word-break: break-word;
}

a i {
  padding: 0 2px;
}

img {
  max-width: 100%;
}

ul li,
ol li {
  margin-top: 1rem;
}

/* -------------------------------------
    Custom Class
------------------------------------- */
.section-bg-1 {
  background-color: var(--section-bg-1);
}
.section-bg-1 .ltn__separate-line .separate-icon {
  background-color: var(--section-bg-1);
}

.section-bg-2,
.section-bg-6,
.section-bg-7 {
  background-color: var(--section-bg-2);
}
.section-bg-2 h1,
.section-bg-2 h2,
.section-bg-2 h3,
.section-bg-2 h4,
.section-bg-2 h5,
.section-bg-2 h6,
.section-bg-2 p,
.section-bg-2 li,
.section-bg-2 i,
.section-bg-2 span,
.section-bg-2 tr,
.section-bg-2 td,
.section-bg-6 h1,
.section-bg-6 h2,
.section-bg-6 h3,
.section-bg-6 h4,
.section-bg-6 h5,
.section-bg-6 h6,
.section-bg-6 p,
.section-bg-6 li,
.section-bg-6 i,
.section-bg-6 span,
.section-bg-6 tr,
.section-bg-6 td,
.section-bg-7 h1,
.section-bg-7 h2,
.section-bg-7 h3,
.section-bg-7 h4,
.section-bg-7 h5,
.section-bg-7 h6,
.section-bg-7 p,
.section-bg-7 li,
.section-bg-7 i,
.section-bg-7 span,
.section-bg-7 tr,
.section-bg-7 td {
  color: var(--white);
}
.section-bg-2 .ltn__separate-line .separate-icon,
.section-bg-6 .ltn__separate-line .separate-icon,
.section-bg-7 .ltn__separate-line .separate-icon {
  background-color: var(--black-2);
}

.section-bg-3 {
  position: relative;
}
.section-bg-3:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-color-1);
  opacity: 0.9;
}
.section-bg-3 h1,
.section-bg-3 h2,
.section-bg-3 h3,
.section-bg-3 h4,
.section-bg-3 h5,
.section-bg-3 h6,
.section-bg-3 p,
.section-bg-3 li,
.section-bg-3 i,
.section-bg-3 span,
.section-bg-3 tr,
.section-bg-3 td {
  color: var(--white);
}
.section-bg-3 .slick-arrow-1 .slick-arrow {
  background-color: transparent;
}
.section-bg-3 input[type="text"],
.section-bg-3 input[type="email"],
.section-bg-3 input[type="password"],
.section-bg-3 input[type="submit"] {
  border-color: var(--white);
  color: var(--white);
}
.section-bg-3 .btn-wrapper button {
  color: var(--white);
}
.section-bg-3 input::-webkit-input-placeholder {
  color: var(--white);
}
.section-bg-3 input::-moz-placeholder {
  color: var(--white);
}
.section-bg-3 input:-ms-input-placeholder {
  color: var(--white);
}
.section-bg-3 input:-moz-placeholder {
  color: var(--white);
}
.section-bg-3 .ltn__separate-line .separate-icon {
  background: var(--gradient-color-1);
}
.section-bg-3 .ltn__separate-line .separate-icon i {
  color: var(--white);
}

.section-bg-4 {
  background-color: var(--ltn__primary-color-2);
}
.section-bg-4 h1,
.section-bg-4 h2,
.section-bg-4 h3,
.section-bg-4 h4,
.section-bg-4 h5,
.section-bg-4 h6,
.section-bg-4 p,
.section-bg-4 li,
.section-bg-4 i,
.section-bg-4 span,
.section-bg-4 tr,
.section-bg-4 td {
  color: var(--white);
}
.section-bg-4 .ltn__separate-line .separate-icon {
  background-color: var(--black-2);
}
.section-bg-4 .ltn__copyright-design h6,
.section-bg-4 .ltn__copyright-design h4 {
  color: var(--ltn__color-1);
}

.section-bg-5 {
  background-color: var(--section-bg-5);
}

.section-bg-6 {
  background-color: var(--section-bg-6);
}

.section-bg-7 {
  background-color: var(--section-bg-7);
}

.text-color-white {
  color: var(--white);
}
.text-color-white h1,
.text-color-white h2,
.text-color-white h3,
.text-color-white h4,
.text-color-white h5,
.text-color-white h6,
.text-color-white p,
.text-color-white li,
.text-color-white i,
.text-color-white span,
.text-color-white tr,
.text-color-white td {
  color: var(--white);
}

.ltn__primary-color {
  color: var(--ltn__primary-color) !important;
}

.ltn__primary-color-2 {
  color: var(--ltn__primary-color-2) !important;
}

.ltn__primary-color-3 {
  color: var(--ltn__primary-color-3) !important;
}

.ltn__secondary-color {
  color: var(--ltn__secondary-color) !important;
}

.ltn__secondary-color-2 {
  color: var(--ltn__secondary-color-2) !important;
}

.ltn__secondary-color-3 {
  color: var(--ltn__secondary-color-3) !important;
}

.ltn__color-1 {
  color: var(--ltn__color-1);
}

.white-color {
  color: var(--white);
}

.white-color-im {
  color: var(--white) !important;
}

.ltn__primary-bg {
  background-color: var(--ltn__primary-color);
}

.ltn__primary-bg-2 {
  background-color: var(--ltn__primary-color-2);
}

.ltn__secondary-bg {
  background-color: var(--ltn__secondary-color);
}

.ltn__secondary-bg-2 {
  background-color: var(--ltn__secondary-color-2);
}

.white-bg {
  background-color: var(--white);
}

.gradient-color-1 {
  background: var(--gradient-color-1);
}

.gradient-color-2 {
  background: var(--gradient-color-2);
}

.gradient-color-3 {
  background: var(--gradient-color-3);
}

.gradient-color-4 {
  background: var(--gradient-color-4);
}

.gradient-color-5 {
  background: var(--gradient-color-5);
}

.before-bg-1 {
  position: relative;
}
.before-bg-1:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 34.2%;
  left: 0;
  bottom: 0;
  background-color: var(--section-bg-1);
}

.before-bg-top {
  position: relative;
}
.before-bg-top:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 72%;
  left: 0;
  top: 0;
  background-color: var(--section-bg-1);
}

.before-bg-bottom {
  position: relative;
}
.before-bg-bottom:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  left: 0;
  bottom: 0;
  background-color: var(--section-bg-2);
}

.before-bg-bottom-2 {
  position: relative;
}
.before-bg-bottom-2:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  left: 0;
  bottom: 0;
  background-color: var(--section-bg-1);
}

.before-bg-right {
  position: relative;
}
.before-bg-right:before {
  position: absolute;
  content: "";
  width: 25%;
  height: 100%;
  left: auto;
  right: 0;
  top: 0;
  background-color: var(--ltn__primary-color);
}

.before-bg-left {
  position: relative;
}
.before-bg-left:before {
  position: absolute;
  content: "";
  width: 25%;
  height: 100%;
  left: 0;
  right: auto;
  top: 0;
  background-color: var(--ltn__primary-color);
}

.box-shadow {
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
}

.box-shadow-1 {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

.ltn__custom-icon {
  position: relative;
}
.ltn__custom-icon::before {
  content: "\f063";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
}

.ltn__border {
  border: 1px solid var(--border-color-1);
}

.ltn__border-top {
  border-top: 1px solid var(--border-color-1);
}

.border-top {
  border-top: 1px solid var(--border-color-1);
}

.border-bottom {
  border-bottom: 1px solid var(--border-color-1);
}

.border-color-3 {
  border-color: var(--border-color-3) !important;
}

mark {
  background-color: var(--ltn__primary-color);
  padding: 3px 15px;
  color: var(--white);
  border-radius: 25px;
}

hr {
  margin-top: 50px;
  margin-bottom: 50px;
  border-top: 1px solid var(--border-color-12);
}

a.text-decoration,
.text-decoration a {
  text-decoration: underline;
}

fieldset {
  padding: 30px 40px;
  border: 1px solid #eee;
}

fieldset legend {
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
  width: auto;
  margin-top: -9px;
  margin-bottom: 0;
  padding: 0 15px;
  text-transform: uppercase;
  color: #333;
  background-color: #fff;
}

@media (max-width: 991px) {
  .before-bg-right:before {
    width: 0;
  }
  .before-bg-left:before {
    width: 0;
  }
}

.position-relative {
  position: relative;
}

.ltn__z-index-9 {
  z-index: 9;
}

.ltn__z-index-99 {
  z-index: 99;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-black {
  background-color: var(--black) !important;
  color: var(--white) !important;
}

.bg-white {
  background-color: var(--white) !important;
  color: var(--black) !important;
}

.bg-red {
  background-color: var(--red) !important;
  color: var(--white) !important;
}

.bg-yellow {
  background-color: var(--yellow) !important;
  color: var(--white) !important;
}

.bg-green {
  background-color: var(--green) !important;
  color: var(--white) !important;
}

.bg-blue {
  background-color: var(--blue) !important;
  color: var(--white) !important;
}

.bg-orange {
  background-color: var(--orange) !important;
  color: var(--white) !important;
}

.bg-fuchsia {
  background-color: var(--fuchsia) !important;
  color: var(--white) !important;
}

/* ----------------------------------------------------
    input and button type focus outline disable
---------------------------------------------------- */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
textarea:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus,
select:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #ddd;
}

code {
  color: #faa603;
}

/* ----------------------------------------------------
    Form input box
---------------------------------------------------- */
input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
textarea {
  background-color: var(--white);
  border: 2px solid;
  border-color: var(--border-color-9);
  height: 65px;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 20px;
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  width: 100%;
  margin-bottom: 30px;
  border-radius: 0;
  padding-right: 40px;
}

input[type="text"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="submit"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--ltn__paragraph-color);
  font-size: 14px;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
textarea:focus {
  border-color: var(--ltn__secondary-color);
}

input[type="password"] {
  letter-spacing: 3px;
  font-size: 16px;
}

textarea {
  resize: vertical;
  padding: 15px 20px;
  min-height: 150px;
}

button {
  outline: none;
  border: none;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.form-input-box {
  position: relative;
}

.form-input-box input[type="text"] {
  width: 100%;
  height: 50px;
  padding: 0 70px 0 15px;
  border-radius: 0 15px 0px 0px;
  border: 1px solid var(--ltn__primary-color);
  margin-bottom: 0;
}

.form-input-box button[type="submit"] {
  background-color: var(--ltn__primary-color);
  color: var(--white);
  padding: 0 18px;
  height: 100%;
  border: 1px solid var(--ltn__primary-color);
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.form-input-box button[type="submit"]:hover {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white);
}

/* input-item */
.input-item {
  position: relative;
}
.input-item.ltn__custom-icon::before {
  position: absolute;
  top: 35%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
  color: var(--ltn__secondary-color);
}

.input-item-name.ltn__custom-icon::before {
  content: "\f007";
}

.input-item-email.ltn__custom-icon::before {
  content: "\f0e0";
}

.input-item-phone.ltn__custom-icon::before {
  content: "\f095";
}

.input-item-subject.ltn__custom-icon::before {
  content: "\f06e";
}

.input-item-website.ltn__custom-icon::before {
  content: "\f0ac";
}

.input-item-date.ltn__custom-icon::before {
  content: "\f073";
}

.input-item-textarea.ltn__custom-icon::before {
  content: "\f303";
  top: 30px;
}

.input-info-save {
  font-size: 14px;
}

label.checkbox-inline {
  font-size: 14px;
}

/* ----------------------------------------------------
    Text meant only for screen readers.
---------------------------------------------------- */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/* -------------------------------------
    Transition
------------------------------------- */
/* ----------------------------------------------------
    Alignments
---------------------------------------------------- */
.alignleft {
  float: left;
  clear: both;
  margin-right: 20px;
}

.alignright {
  float: right;
  clear: both;
  margin-left: 20px;
}

.aligncenter {
  clear: both;
  display: block;
  margin: 0 auto 1.75em;
}

.alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.alignwide {
  max-width: 1100px;
}

.fix {
  overflow: hidden;
}

/* ----------------------------------------------------
    Clearings
---------------------------------------------------- */
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
  clear: both;
}

/* ----------------------------------------------------
    Posts and pages
---------------------------------------------------- */
.sticky {
  display: block;
}

.updated:not(.published) {
  display: none;
}

.blog-pagination ul li {
  display: inline-block;
}

.blog-pagination ul li + li {
  margin: 0 5px;
}

.blog-pagination {
  display: block;
  width: 100%;
}

.blog-pagination ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.blog-pagination ul li a,
.blog-pagination ul li span {
  display: block;
  width: 40px;
  height: 40px;
  border: 1px solid #e2e2e2;
  line-height: 40px;
  text-align: center;
  font-weight: 600;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.blog-pagination ul li span.current,
.blog-pagination ul li a:hover {
  background-color: var(--ltn__primary-color);
  color: #fff;
}

/* ----------------------------------------------------
    Media
---------------------------------------------------- */
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/* ----------------------------------------------------
    Captions
---------------------------------------------------- */
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
  clear: both;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/* ----------------------------------------------------
    Galleries
---------------------------------------------------- */
.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-caption {
  display: block;
}

/* ----------------------------------------------------
    Unit test
---------------------------------------------------- */
.wp-link-pages a {
  margin: 0 5px;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.wp-link-pages {
  margin-bottom: 30px;
  margin-top: 25px;
}

.wp-link-pages span,
.wp-link-pages a {
  border: 1px solid #e2e2e2;
  padding: 5px 15px;
  display: inline-block;
}

.wp-link-pages .current,
.wp-link-pages a:hover {
  background-color: var(--ltn__primary-color);
  color: #fff;
  border-color: var(--ltn__primary-color);
}

.wp-link-pages span:first-child {
  margin-right: 5px;
}

dl,
ol,
ul {
  padding-left: 20px;
}

.post-password-form input {
  display: block;
  border: 1px solid #e2e2e2;
  height: 50px;
  border-radius: 3px;
  padding: 0 20px;
}

.post-password-form label {
  font-weight: 600;
  color: #333;
}

.post-password-form input[type="submit"] {
  width: 100px;
  height: 50px;
  background-color: var(--ltn__primary-color);
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
}

.post-password-form input[type="submit"]:hover {
  background-color: #121a2f;
}

.footer-widget .table td,
.footer-widget .table th {
  padding: 0.5rem !important;
}

/* ----------------------------------------------------
    ScrollUp
---------------------------------------------------- */
#scrollUp {
  background-color: var(--section-bg-1);
  color: var(--ltn__heading-color);
  bottom: 70px;
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  width: 40px;
  right: 3%;
  text-align: center;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
}
#scrollUp i {
  line-height: 40px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
#scrollUp:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

/* ----------------------------------------------------
    Owl Carousel 
---------------------------------------------------- */
.owl-dots {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.owl-dots .owl-dot {
  display: inline-block;
  background-color: var(--ltn__secondary-color);
  height: 10px;
  width: 10px;
  margin-right: 30px;
  border-radius: 100%;
}

.owl-dots .owl-dot:last-child {
  margin-right: 0px;
}

.owl-dots .owl-dot:hover,
.owl-dots .owl-dot.active {
  background-color: var(--ltn__primary-color);
}

/* owl-arrow-1 */
.owl-arrow-1 .owl-nav > div {
  position: absolute;
  left: -60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: auto;
}

.owl-arrow-1 .owl-nav > div.owl-next {
  left: auto;
  right: -60px;
}

@media only screen and (max-width: 1200px) {
  .owl-arrow-1 .owl-nav > div {
    right: auto;
    left: 0px;
  }
  .owl-arrow-1 .owl-nav > div.owl-next {
    right: 0px;
    left: auto;
  }
}

/* ----------------------------------------------------
    Slick Slider Dots, Arrow
---------------------------------------------------- */
/* Slick dots */
.slick-dots {
  margin: 0 0 30px;
  padding: 0;
  display: block;
  text-align: center;
  line-height: 1;
}
.slick-dots li {
  display: inline-block;
  list-style: none;
  display: inline-block;
  font-size: 0;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin-right: 10px;
  background-color: var(--silver);
  cursor: pointer;
  margin-top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.slick-dots li button {
  display: none;
}
.slick-dots li:hover,
.slick-dots li.slick-active {
  background-color: var(--ltn__secondary-color);
  height: 15px;
  width: 15px;
  margin-bottom: -2px;
}

/* slick-arrow */
.slick-arrow {
  cursor: pointer;
  z-index: 9;
}

/* slick-arrow-1 */
.slick-arrow-1 .slick-arrow {
  background-color: var(--white);
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 15px;
  right: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  line-height: 48px;
  display: block;
  border: 1px solid var(--white-4);
  border-radius: 100%;
  text-align: center;
  font-size: 20px;
  color: var(--ltn__primary-color) !important;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.slick-arrow-1 .slick-arrow:hover {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white) !important;
}

.slick-arrow-1 .slick-next {
  right: 15px;
  left: auto;
}

.slick-arrow-1:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
}

.slick-arrow-1-inner.slick-arrow-1:hover .slick-arrow {
  left: 50px;
  right: auto;
}

.slick-arrow-1-inner.slick-arrow-1:hover .slick-next {
  right: 50px;
  left: auto;
}

@media (min-width: 1350px) {
  .slick-arrow-1:hover .slick-arrow {
    left: -50px;
    right: auto;
  }
  .slick-arrow-1:hover .slick-next {
    right: -50px;
    left: auto;
  }
}

/* slick-arrow-2 */
.slick-arrow-2 .slick-arrow {
  color: var(--ltn__primary-color) !important;
  cursor: pointer;
  position: absolute;
  bottom: -30px;
  height: 30px;
  width: 30px;
  line-height: 28px;
  display: block;
  left: 15px;
  border: 1px solid var(--border-color-1);
  text-align: center;
}
.slick-arrow-2 .slick-arrow:hover {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white) !important;
}

.slick-arrow-2 .slick-next {
  left: 60px;
}

/* slick-arrow-3 */
.slick-arrow-3 .slick-arrow {
  cursor: pointer;
  position: absolute;
  top: -120px;
  height: 60px;
  width: 60px;
  line-height: 58px;
  display: block;
  left: auto;
  right: 100px;
  border: 2px solid;
  text-align: center;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
}
.slick-arrow-3 .slick-arrow:hover {
  background-color: var(--ltn__secondary-color);
  border-color: var(--border-color-4);
  color: var(--white) !important;
}

.slick-arrow-3 .slick-next {
  right: 15px;
  background-color: var(--white);
  border-color: var(--white);
  color: var(--ltn__primary-color) !important;
}

.slick-arrow-3 .slick-prev {
  background-color: var(--ltn__secondary-color);
  border-color: var(--border-color-4);
  color: var(--white) !important;
}

/* slick-arrow-4 */
.slick-arrow-4 {
  margin: 0;
}
.slick-arrow-4 .slick-arrow {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  text-align: center;
  height: 50px;
  width: 50px;
  line-height: 50px;
  margin: 0 auto;
  border: 1px solid;
  border-radius: 100%;
}
.slick-arrow-4 .slick-arrow:hover {
  background-color: var(--ltn__secondary-color);
  border-color: var(--border-color-4);
  color: var(--white) !important;
}
.slick-arrow-4 .slick-next {
  right: -60px;
}
.slick-arrow-4 .slick-prev {
  left: -60px;
}

@media (max-width: 767px) {
  .slick-dots {
    margin: 0 0 15px;
  }
}

/* ----------------------------------------------------
    Background Overlay
---------------------------------------------------- */
.bg-overlay-black-10,
.bg-overlay-black-20,
.bg-overlay-black-30,
.bg-overlay-black-40,
.bg-overlay-black-50,
.bg-overlay-black-60,
.bg-overlay-black-70,
.bg-overlay-black-80,
.bg-overlay-black-90,
.bg-overlay-white-10,
.bg-overlay-white-20,
.bg-overlay-white-30,
.bg-overlay-white-40,
.bg-overlay-white-50,
.bg-overlay-white-60,
.bg-overlay-white-70,
.bg-overlay-white-80,
.bg-overlay-white-90 {
  z-index: 8;
  position: relative;
}

.bg-overlay-black-10:before,
.bg-overlay-black-20:before,
.bg-overlay-black-30:before,
.bg-overlay-black-40:before,
.bg-overlay-black-50:before,
.bg-overlay-black-60:before,
.bg-overlay-black-70:before,
.bg-overlay-black-80:before,
.bg-overlay-black-90:before,
.bg-overlay-white-10:before,
.bg-overlay-white-20:before,
.bg-overlay-white-30:before,
.bg-overlay-white-40:before,
.bg-overlay-white-50:before,
.bg-overlay-white-60:before,
.bg-overlay-white-70:before,
.bg-overlay-white-80:before,
.bg-overlay-white-90:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg-overlay-black-10:before {
  background: rgba(0, 0, 0, 0.1);
}

.bg-overlay-black-20:before {
  background: rgba(0, 0, 0, 0.2);
}

.bg-overlay-black-30:before {
  background: rgba(0, 0, 0, 0.3);
}

.bg-overlay-black-40:before {
  background: rgba(0, 0, 0, 0.4);
}

.bg-overlay-black-50:before {
  background: rgba(0, 0, 0, 0.5);
}

.bg-overlay-black-60:before {
  background: rgba(0, 0, 0, 0.6);
}

.bg-overlay-black-70:before {
  background: rgba(0, 0, 0, 0.7);
}

.bg-overlay-black-80:before {
  background: rgba(0, 0, 0, 0.8);
}

.bg-overlay-black-90:before {
  background: rgba(0, 0, 0, 0.9);
}

.bg-overlay-white-10:before {
  background: rgba(255, 255, 255, 0.1);
}

.bg-overlay-white-20:before {
  background: rgba(255, 255, 255, 0.2);
}

.bg-overlay-white-30:before {
  background: rgba(255, 255, 255, 0.3);
}

.bg-overlay-white-40:before {
  background: rgba(255, 255, 255, 0.4);
}

.bg-overlay-white-50:before {
  background: rgba(255, 255, 255, 0.5);
}

.bg-overlay-white-60:before {
  background: rgba(255, 255, 255, 0.6);
}

.bg-overlay-white-70:before {
  background: rgba(255, 255, 255, 0.7);
}

.bg-overlay-white-80:before {
  background: rgba(255, 255, 255, 0.8);
}

.bg-overlay-white-90:before {
  background: rgba(255, 255, 255, 0.9);
}

.bg-overlay-theme-05,
.bg-overlay-theme-10,
.bg-overlay-theme-20,
.bg-overlay-theme-30,
.bg-overlay-theme-40,
.bg-overlay-theme-50,
.bg-overlay-theme-60,
.bg-overlay-theme-70,
.bg-overlay-theme-80,
.bg-overlay-theme-90 {
  z-index: 9;
  position: relative;
}

.bg-overlay-theme-05:before,
.bg-overlay-theme-10:before,
.bg-overlay-theme-20:before,
.bg-overlay-theme-30:before,
.bg-overlay-theme-40:before,
.bg-overlay-theme-50:before,
.bg-overlay-theme-60:before,
.bg-overlay-theme-70:before,
.bg-overlay-theme-80:before,
.bg-overlay-theme-90:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg-overlay-theme-05:before {
  background: rgba(229, 62, 41, 0.05);
}

.bg-overlay-theme-10:before {
  background: rgba(229, 62, 41, 0.1);
}

.bg-overlay-theme-20:before {
  background: rgba(229, 62, 41, 0.2);
}

.bg-overlay-theme-30:before {
  background: rgba(229, 62, 41, 0.3);
}

.bg-overlay-theme-40:before {
  background: rgba(229, 62, 41, 0.4);
}

.bg-overlay-theme-50:before {
  background: rgba(229, 62, 41, 0.5);
}

.bg-overlay-theme-60:before {
  background: rgba(229, 62, 41, 0.6);
}

.bg-overlay-theme-70:before {
  background: rgba(229, 62, 41, 0.7);
}

.bg-overlay-theme-80:before {
  background: rgba(229, 62, 41, 0.8);
}

.bg-overlay-theme-90:before {
  background: rgba(229, 62, 41, 0.9);
}

.bg-overlay-theme-black-05,
.bg-overlay-theme-black-10,
.bg-overlay-theme-black-20,
.bg-overlay-theme-black-30,
.bg-overlay-theme-black-40,
.bg-overlay-theme-black-50,
.bg-overlay-theme-black-60,
.bg-overlay-theme-black-70,
.bg-overlay-theme-black-80,
.bg-overlay-theme-black-90 {
  z-index: 9;
  position: relative;
}

.bg-overlay-theme-black-05:before,
.bg-overlay-theme-black-10:before,
.bg-overlay-theme-black-20:before,
.bg-overlay-theme-black-30:before,
.bg-overlay-theme-black-40:before,
.bg-overlay-theme-black-50:before,
.bg-overlay-theme-black-60:before,
.bg-overlay-theme-black-70:before,
.bg-overlay-theme-black-80:before,
.bg-overlay-theme-black-90:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.bg-overlay-theme-black-05:before {
  background: rgba(7, 28, 31, 0.05);
}

.bg-overlay-theme-black-10:before {
  background: rgba(7, 28, 31, 0.1);
}

.bg-overlay-theme-black-20:before {
  background: rgba(7, 28, 31, 0.2);
}

.bg-overlay-theme-black-30:before {
  background: rgba(7, 28, 31, 0.3);
}

.bg-overlay-theme-black-40:before {
  background: rgba(7, 28, 31, 0.4);
}

.bg-overlay-theme-black-50:before {
  background: rgba(7, 28, 31, 0.5);
}

.bg-overlay-theme-black-60:before {
  background: rgba(7, 28, 31, 0.6);
}

.bg-overlay-theme-black-70:before {
  background: rgba(7, 28, 31, 0.7);
}

.bg-overlay-theme-black-80:before {
  background: rgba(7, 28, 31, 0.8);
}

.bg-overlay-theme-black-90:before {
  background: rgba(7, 28, 31, 0.9);
}

/* ----------------------------------------------
  Scrollbar
---------------------------------------------- */
.ltn__scrollbar {
  overflow-y: auto;
}

.ltn__scrollbar::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
  border-radius: 30px;
}

.ltn__scrollbar::-webkit-scrollbar-thumb {
  background-color: #ddd;
}

.mean-nav {
  overflow-y: auto;
}

.mean-nav::-webkit-scrollbar {
  width: 3px;
  background-color: var(--ltn__primary-color);
  border-radius: 30px;
}

.mean-nav::-webkit-scrollbar-thumb {
  background-color: var(--ltn__secondary-color);
}

/* ----------------------------------------------------
    Padding Top
---------------------------------------------------- */
.pt-0 {
  padding-top: 0px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-97 {
  padding-top: 97px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-112 {
  padding-top: 112px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-118 {
  padding-top: 118px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-122 {
  padding-top: 122px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-230 {
  padding-top: 230px;
}

.pt-250 {
  padding-top: 250px;
}

.pt-280 {
  padding-top: 280px;
}

.pt-290 {
  padding-top: 290px;
}

.pt-300 {
  padding-top: 300px;
}

.pt-310 {
  padding-top: 310px;
}

.pt-320 {
  padding-top: 320px;
}

.pt-350 {
  padding-top: 350px;
}

/* ----------------------------------------------------
    Padding Bottom
---------------------------------------------------- */
.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-97 {
  padding-bottom: 97px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-107 {
  padding-bottom: 107px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-112 {
  padding-bottom: 112px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-118 {
  padding-bottom: 118px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-122 {
  padding-bottom: 122px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-250 {
  padding-bottom: 250px;
}

.pb-280 {
  padding-bottom: 280px;
}

.pb-290 {
  padding-bottom: 290px;
}

.pb-300 {
  padding-bottom: 300px;
}

.pb-310 {
  padding-bottom: 310px;
}

.pb-320 {
  padding-bottom: 320px;
}

.pb-350 {
  padding-bottom: 350px;
}

/* ----------------------------------------------------
    Margin Top
---------------------------------------------------- */
.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px;
}

.mt-37 {
  margin-top: 37px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-118 {
  margin-top: 118px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-122 {
  margin-top: 122px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-170 {
  margin-top: 170px;
}

/* ----------------------------------------------------
    Margin Bottom
---------------------------------------------------- */
.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-118 {
  margin-bottom: 118px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-122 {
  margin-bottom: 122px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-150 {
  margin-bottom: 150px;
}

/* ----------------------------------------------------
    Custom margin Padding
---------------------------------------------------- */
.mr-30 {
  margin-right: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.pl-70 {
  padding-left: 70px;
}

.pr-70 {
  padding-right: 70px;
}

/* Padding left right */
.plr--2 {
  padding-left: 2%;
  padding-right: 2%;
}

.plr--3 {
  padding-left: 3%;
  padding-right: 3%;
}

.plr--4 {
  padding-left: 4%;
  padding-right: 4%;
}

.plr--5 {
  padding-left: 5%;
  padding-right: 5%;
}

.plr--6 {
  padding-left: 6%;
  padding-right: 6%;
}

.plr--7 {
  padding-left: 7%;
  padding-right: 7%;
}

.plr--8 {
  padding-left: 8%;
  padding-right: 8%;
}

.plr--9 {
  /* used */
  padding-left: 9%;
  padding-right: 9%;
}

.plr--10 {
  padding-left: 10%;
  padding-right: 10%;
}

.plr--11 {
  padding-left: 11%;
  padding-right: 11%;
}

.plr--12 {
  padding-left: 12%;
  padding-right: 12%;
}

@media (max-width: 1599px) {
  .plr--2,
  .plr--3,
  .plr--4,
  .plr--5,
  .plr--6,
  .plr--7,
  .plr--8,
  .plr--9,
  .plr--10,
  .plr--11,
  .plr--12 {
    padding-left: 2%;
    padding-right: 2%;
  }
}

@media (max-width: 1399px) {
  .plr--2,
  .plr--3,
  .plr--4,
  .plr--5,
  .plr--6,
  .plr--7,
  .plr--8,
  .plr--9,
  .plr--10,
  .plr--11,
  .plr--12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 1200px) {
  /* Margin Top Minus */
  .mt--30 {
    margin-top: -30px !important;
  }
  .mt--65 {
    margin-top: -65px;
  }
  .mt--80 {
    margin-top: -100px;
  }
  .mt--90 {
    margin-top: -100px;
  }
  .mt--100 {
    margin-top: -100px;
  }
  .mt--110 {
    margin-top: -100px;
  }
  .mt--120 {
    margin-top: -100px;
  }
  .mt--150 {
    margin-top: -100px;
  }
}

@media (max-width: 767px) {
  .mb-sm-20 {
    margin-bottom: 20px;
  }
}

/* Margin Bottom Minus */
.mb--30 {
  margin-bottom: -30px;
}

.mb--80 {
  margin-bottom: -100px;
}

.mb--90 {
  margin-bottom: -100px;
}

.mb--100 {
  margin-bottom: -100px;
}

.mb--110 {
  margin-bottom: -100px;
}

.mb--120 {
  margin-bottom: -100px;
}

.mb--150 {
  margin-bottom: -100px;
}

/* ----------------------------------------------------
    Custom Height
---------------------------------------------------- */
.ltn__height-100 {
  height: 100px;
}

.ltn__height-200 {
  height: 200px;
}

.ltn__height-250 {
  height: 250px;
}

.ltn__height-300 {
  height: 300px;
}

.ltn__height-350 {
  height: 350px;
}

.ltn__height-400 {
  height: 400px;
}

.ltn__height-450 {
  height: 450px;
}

.ltn__height-500 {
  height: 500px;
}

.ltn__height-550 {
  height: 550px;
}

.ltn__height-600 {
  height: 600px;
}

.ltn__height-650 {
  height: 650px;
}

.ltn__height-700 {
  height: 700px;
}

/* --------------------------------------------------------------
# Gutenberg	Default Style
-------------------------------------------------------------- */
.single-post-details-item .entry-content > .alignwide {
  max-width: 1100px;
}

.single-post-details-item .entry-content > .alignfull {
  margin: 1.5em 0;
  max-width: 100%;
}

.wp-block-video video {
  max-width: 636px;
}

.wp-block-image img {
  display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
  width: 100%;
}

.wp-block-image.alignfull img {
  width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
  margin: 1.5em auto;
}

.wp-block-cover-text p {
  padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
  padding: 0 14px;
}

.wp-block-table {
  display: block;
  overflow-x: auto;
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}

.wp-block-table td,
.wp-block-table th {
  padding: 0.5em;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-quote.is-large {
  margin: 0 auto 16px;
}

.wp-block-pullquote > p:first-child {
  margin-top: 0;
}

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .wp-block-cover-text p {
    padding: 1.5em 0;
  }
}

.wp-block-video video {
  max-width: 636px;
}

.wp-block-image img {
  display: block;
}

.wp-block-image.alignleft,
.wp-block-image.alignright {
  width: 100%;
}

.wp-block-image.alignfull img {
  width: 100vw;
}

.wp-block-gallery:not(.components-placeholder) {
  margin: 1.5em auto;
}

.wp-block-cover-text p {
  padding: 1.5em 14px;
}

ul.wp-block-latest-posts.alignwide,
ul.wp-block-latest-posts.alignfull,
ul.wp-block-latest-posts.is-grid.alignwide,
ul.wp-block-latest-posts.is-grid.alignwide {
  padding: 0 14px;
}

.wp-block-table {
  display: block;
  overflow-x: auto;
}

.wp-block-table table {
  border-collapse: collapse;
  width: 100%;
}

.wp-block-table td,
.wp-block-table th {
  padding: 0.5em;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
}

.wp-block-embed.type-video > .wp-block-embed__wrapper > iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wp-block-quote.is-large {
  margin: 0 auto 16px;
}

.wp-block-pullquote > p:first-child {
  margin-top: 0;
}

.wp-block-quote:not(.is-large):not(.is-style-large) {
  border-left: 4px solid #000;
  padding-left: 1em;
}

.wp-block-separator {
  margin: 3em auto;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .wp-block-cover-text p {
    padding: 1.5em 0;
  }
}

.wp-block-pullquote {
  border-top: 4px solid #555d66;
  border-bottom: 4px solid #555d66;
  color: #40464d;
}

/* --------------------------------------------------------------
## Block Color Palette Colors
-------------------------------------------------------------- */
.has-strong-blue-color {
  color: #0073aa;
}

.has-strong-blue-background-color {
  background-color: #0073aa;
}

.has-lighter-blue-color {
  color: #229fd8;
}

.has-lighter-blue-background-color {
  background-color: #229fd8;
}

.has-very-light-gray-color {
  color: #eee;
}

.has-very-light-gray-background-color {
  background-color: #eee;
}

.has-very-dark-gray-color {
  color: #444;
}

.has-very-dark-gray-background-color {
  background-color: #444;
}

/* ----------------------------------------------------
    Animation Custom Class
---------------------------------------------------- */
.ltn__effect-img {
  position: absolute;
}

.ltn__effect-img-top-left {
  top: 6%;
  left: 5%;
}

.ltn__effect-img-top-right {
  top: 5%;
  right: 5%;
}

.ltn__effect-img-center-left {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 3%;
}

.ltn__effect-img-center-center {
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

.ltn__effect-img-center-right {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 3%;
}

.ltn__effect-img-bottom-left {
  bottom: 5%;
  left: 0;
}

.ltn__effect-img-bottom-right {
  bottom: 5%;
  right: 5%;
}

.ltn__animation-pulse1 a {
  -webkit-animation: pulse1 1s infinite;
  animation: pulse1 1s infinite;
}

.ltn__animation-pulse2 a {
  -webkit-animation: pulse2 1s infinite;
  animation: pulse2 1s infinite;
}

@-webkit-keyframes pulse1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes pulse1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@-webkit-keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 90, 60, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 90, 60, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 90, 60, 0);
    box-shadow: 0 0 0 15px rgba(255, 90, 60, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 90, 60, 0);
    box-shadow: 0 0 0 0 rgba(255, 90, 60, 0);
  }
}

@keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 90, 60, 0.6);
    box-shadow: 0 0 0 0 rgba(255, 90, 60, 0.6);
  }
  70% {
    -webkit-box-shadow: 0 0 0 15px rgba(255, 90, 60, 0);
    box-shadow: 0 0 0 15px rgba(255, 90, 60, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 90, 60, 0);
    box-shadow: 0 0 0 0 rgba(255, 90, 60, 0);
  }
}

/* ----------------------------------------------------
    Animation CSS
---------------------------------------------------- */
.ltn__parallax-effect-wrap,
.layer {
  display: block;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.ltn__parallax-effect-wrap {
  min-height: 600px;
  position: relative;
  overflow: hidden;
}

.layer {
  position: absolute;
}

.layer div {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}

.background {
  background: url(../img/effect/1.png) no-repeat 50% 100%;
  bottom: 96px;
  background-size: cover;
  position: absolute;
  width: 110%;
  left: -5%;
  top: -5%;
}

.lighthouse {
  -webkit-transform-origin: 50% 90%;
  -moz-transform-origin: 50% 90%;
  -ms-transform-origin: 50% 90%;
  transform-origin: 50% 90%;
  -webkit-animation: lighthouse 4s 0.1s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-animation: lighthouse 4s 0.1s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation: lighthouse 4s 0.1s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  margin: 0px -64px;
  background-size: 128px 224px;
  height: 224px;
  width: 128px;
  background-image: url(../img/effect/2.png);
  background-repeat: no-repeat;
  bottom: 64px;
  position: absolute;
  right: 20%;
}

.wave.plain {
  background-image: url(../img/effect/3.png);
}

.wave.paint {
  background-image: url(../img/effect/4.png);
}

.light {
  margin: -120px -120px;
  background-size: 240px 240px;
  height: 240px;
  width: 240px;
  background-repeat: no-repeat;
  bottom: 112px;
  position: absolute;
  left: 50%;
}

.light.orange {
  background-image: url(../img/effect/5.png);
}

.light.purple {
  background-image: url(../img/effect/6.png);
}

.light.a {
  bottom: 80px;
  left: 20%;
}

.light.b {
  bottom: 80px;
  left: 30%;
}

.light.c {
  bottom: 112px;
  left: 45%;
}

.light.d {
  bottom: 96px;
  left: 60%;
}

.light.e {
  bottom: 112px;
  left: 75%;
}

.light.f {
  bottom: 64px;
  left: 80%;
}

.light.phase-1 {
  -webkit-animation: phase 20s 0.1s infinite linear;
  -moz-animation: phase 20s 0.1s infinite linear;
  animation: phase 20s 0.1s infinite linear;
}

.light.phase-2 {
  -webkit-animation: phase 18s 0.1s infinite linear;
  -moz-animation: phase 18s 0.1s infinite linear;
  animation: phase 18s 0.1s infinite linear;
}

.light.phase-3 {
  -webkit-animation: phase 16s 0.1s infinite linear;
  -moz-animation: phase 16s 0.1s infinite linear;
  animation: phase 16s 0.1s infinite linear;
}

.light.phase-4 {
  -webkit-animation: phase 14s 0.1s infinite linear;
  -moz-animation: phase 14s 0.1s infinite linear;
  animation: phase 14s 0.1s infinite linear;
}

.light.phase-5 {
  -webkit-animation: phase 12s 0.1s infinite linear;
  -moz-animation: phase 12s 0.1s infinite linear;
  animation: phase 12s 0.1s infinite linear;
}

.light.phase-6 {
  -webkit-animation: phase 10s 0.1s infinite linear;
  -moz-animation: phase 10s 0.1s infinite linear;
  animation: phase 10s 0.1s infinite linear;
}

.hanger {
  -webkit-transform-origin: 0% 0%;
  -moz-transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  position: absolute;
}

.hanger.position-1 {
  top: 28%;
}

.hanger.position-2 {
  top: 46%;
}

.hanger.position-3 {
  top: 59%;
}

.hanger.position-4 {
  top: 66.5%;
}

.hanger.position-5 {
  top: 69.5%;
}

.hanger.position-6 {
  top: 66.5%;
}

.hanger.position-7 {
  top: 59%;
}

.hanger.position-8 {
  top: 46%;
}

.hanger.position-9 {
  top: 28%;
}

.hanger.position-1 {
  left: 10%;
}

.hanger.position-2 {
  left: 20%;
}

.hanger.position-3 {
  left: 30%;
}

.hanger.position-4 {
  left: 40%;
}

.hanger.position-5 {
  left: 50%;
}

.hanger.position-6 {
  left: 60%;
}

.hanger.position-7 {
  left: 70%;
}

.hanger.position-8 {
  left: 80%;
}

.hanger.position-9 {
  left: 90%;
}

.board {
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  margin: 0px -140px;
  background-size: 280px 280px;
  height: 280px;
  width: 280px;
  background-repeat: no-repeat;
  position: absolute;
  top: -4px;
  left: 0;
}

.board.birds {
  background-image: url(../img/effect/5.png);
}

.board.cloud-1 {
  background-image: url(../img/effect/1.png);
}

.board.cloud-2 {
  background-image: url(../img/effect/2.png);
}

.board.cloud-3 {
  background-image: url(../img/effect/3.png);
}

.board.cloud-4 {
  background-image: url(../img/effect/4.png);
}

.swing-1 {
  -webkit-animation: swing 4s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-animation: swing 4s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation: swing 4s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.swing-2 {
  -webkit-animation: swing 3.5s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-animation: swing 3.5s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation: swing 3.5s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.swing-3 {
  -webkit-animation: swing 3s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-animation: swing 3s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation: swing 3s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.swing-4 {
  -webkit-animation: swing 2.5s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-animation: swing 2.5s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation: swing 2.5s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.swing-5 {
  -webkit-animation: swing 2s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  -moz-animation: swing 2s infinite alternate
    cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation: swing 2s infinite alternate cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/* --------------- */
.wave {
  background: transparent repeat-x;
  position: absolute;
  width: 100%;
  left: 0;
  margin: 0px 0px;
  background-position: center bottom;
  background-size: auto 101%;
  height: 30%;
  -webkit-animation: wave 5.33333s 0.1s infinite linear;
  -moz-animation: wave 5.33333s 0.1s infinite linear;
  animation: wave 5.33333s 0.1s infinite linear;
}

.ltn__animation-wave-3s {
  -webkit-animation: wave 3s 0.1s infinite linear;
  -moz-animation: wave 3s 0.1s infinite linear;
  animation: wave 3s 0.1s infinite linear;
}

.ltn__animation-wave-4s {
  -webkit-animation: wave 4s 0.1s infinite linear;
  -moz-animation: wave 4s 0.1s infinite linear;
  animation: wave 4s 0.1s infinite linear;
}

.ltn__animation-wave-5s {
  -webkit-animation: wave 5.33333s 0.1s infinite linear;
  -moz-animation: wave 5.33333s 0.1s infinite linear;
  animation: wave 5.33333s 0.1s infinite linear;
}

.ltn__animation-wave-6s {
  -webkit-animation: wave 6.22222s 0.1s infinite linear;
  -moz-animation: wave 6.22222s 0.1s infinite linear;
  animation: wave 6.22222s 0.1s infinite linear;
}

.ltn__animation-wave-7s {
  -webkit-animation: wave 7.11111s 0.1s infinite linear;
  -moz-animation: wave 7.11111s 0.1s infinite linear;
  animation: wave 7.11111s 0.1s infinite linear;
}

.ltn__animation-wave-8s {
  -webkit-animation: wave 8s 0.1s infinite linear;
  -moz-animation: wave 8s 0.1s infinite linear;
  animation: wave 8s 0.1s infinite linear;
}

.cross,
.x,
.y {
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@-webkit-keyframes phase {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes phase {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes phase {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@keyframes phase {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes tilt {
  0% {
    -webkit-transform: rotateX(-30deg);
    -moz-transform: rotateX(-30deg);
    transform: rotateX(-30deg);
  }
  25% {
    -webkit-transform: rotateX(30deg);
    -moz-transform: rotateX(30deg);
    transform: rotateX(30deg);
  }
  50% {
    -webkit-transform: rotateY(-30deg);
    -moz-transform: rotateY(-30deg);
    transform: rotateY(-30deg);
  }
  75% {
    -webkit-transform: rotateY(30deg);
    -moz-transform: rotateY(30deg);
    transform: rotateY(30deg);
  }
  100% {
    -webkit-transform: rotateZ(20deg);
    -moz-transform: rotateZ(20deg);
    transform: rotateZ(20deg);
  }
}

@-moz-keyframes tilt {
  0% {
    -webkit-transform: rotateX(-30deg);
    -moz-transform: rotateX(-30deg);
    transform: rotateX(-30deg);
  }
  25% {
    -webkit-transform: rotateX(30deg);
    -moz-transform: rotateX(30deg);
    transform: rotateX(30deg);
  }
  50% {
    -webkit-transform: rotateY(-30deg);
    -moz-transform: rotateY(-30deg);
    transform: rotateY(-30deg);
  }
  75% {
    -webkit-transform: rotateY(30deg);
    -moz-transform: rotateY(30deg);
    transform: rotateY(30deg);
  }
  100% {
    -webkit-transform: rotateZ(20deg);
    -moz-transform: rotateZ(20deg);
    transform: rotateZ(20deg);
  }
}

@-ms-keyframes tilt {
  0% {
    -webkit-transform: rotateX(-30deg);
    -moz-transform: rotateX(-30deg);
    transform: rotateX(-30deg);
  }
  25% {
    -webkit-transform: rotateX(30deg);
    -moz-transform: rotateX(30deg);
    transform: rotateX(30deg);
  }
  50% {
    -webkit-transform: rotateY(-30deg);
    -moz-transform: rotateY(-30deg);
    transform: rotateY(-30deg);
  }
  75% {
    -webkit-transform: rotateY(30deg);
    -moz-transform: rotateY(30deg);
    transform: rotateY(30deg);
  }
  100% {
    -webkit-transform: rotateZ(20deg);
    -moz-transform: rotateZ(20deg);
    -ms-transform: rotate(20deg);
    transform: rotateZ(20deg);
  }
}

@keyframes tilt {
  0% {
    -webkit-transform: rotateX(-30deg);
    -moz-transform: rotateX(-30deg);
    transform: rotateX(-30deg);
  }
  25% {
    -webkit-transform: rotateX(30deg);
    -moz-transform: rotateX(30deg);
    transform: rotateX(30deg);
  }
  50% {
    -webkit-transform: rotateY(-30deg);
    -moz-transform: rotateY(-30deg);
    transform: rotateY(-30deg);
  }
  75% {
    -webkit-transform: rotateY(30deg);
    -moz-transform: rotateY(30deg);
    transform: rotateY(30deg);
  }
  100% {
    -webkit-transform: rotateZ(20deg);
    -moz-transform: rotateZ(20deg);
    transform: rotateZ(20deg);
  }
}

@-webkit-keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
  }
}

@-moz-keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
  }
}

@-ms-keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
  }
}

@keyframes wave {
  0% {
    -webkit-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    -moz-transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
    transform: rotateZ(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
  }
  100% {
    -webkit-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    -moz-transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
    transform: rotateZ(360deg) translate3d(0, 10%, 0) rotateZ(-360deg);
  }
}

@-webkit-keyframes lighthouse {
  0% {
    -webkit-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    -moz-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    transform: translate3d(15%, 0, 0) rotateZ(10deg);
  }
  100% {
    -webkit-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    -moz-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
  }
}

@-moz-keyframes lighthouse {
  0% {
    -webkit-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    -moz-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    transform: translate3d(15%, 0, 0) rotateZ(10deg);
  }
  100% {
    -webkit-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    -moz-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
  }
}

@-ms-keyframes lighthouse {
  0% {
    -webkit-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    -moz-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    transform: translate3d(15%, 0, 0) rotateZ(10deg);
  }
  100% {
    -webkit-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    -moz-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
  }
}

@keyframes lighthouse {
  0% {
    -webkit-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    -moz-transform: translate3d(15%, 0, 0) rotateZ(10deg);
    transform: translate3d(15%, 0, 0) rotateZ(10deg);
  }
  100% {
    -webkit-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    -moz-transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
    transform: translate3d(-15%, 0, 0) rotateZ(-10deg);
  }
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotateZ(10deg);
    -moz-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
  }
  100% {
    -webkit-transform: rotateZ(-10deg);
    -moz-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg);
  }
}

@-moz-keyframes swing {
  0% {
    -webkit-transform: rotateZ(10deg);
    -moz-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
  }
  100% {
    -webkit-transform: rotateZ(-10deg);
    -moz-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg);
  }
}

@-ms-keyframes swing {
  0% {
    -webkit-transform: rotateZ(10deg);
    -moz-transform: rotateZ(10deg);
    -ms-transform: rotate(10deg);
    transform: rotateZ(10deg);
  }
  100% {
    -webkit-transform: rotateZ(-10deg);
    -moz-transform: rotateZ(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotateZ(-10deg);
  }
}

@keyframes swing {
  0% {
    -webkit-transform: rotateZ(10deg);
    -moz-transform: rotateZ(10deg);
    transform: rotateZ(10deg);
  }
  100% {
    -webkit-transform: rotateZ(-10deg);
    -moz-transform: rotateZ(-10deg);
    transform: rotateZ(-10deg);
  }
}

/* ----------------------------------------------------
    End Animation
---------------------------------------------------- */
/* ----------------------------------------------------
    Breadcurmb Area
---------------------------------------------------- */
.ltn__breadcrumb-area {
  background-color: var(--section-bg-1);
  margin-bottom: 120px;
  padding-top: 110px;
  padding-bottom: 110px;
}

.ltn__breadcrumb-list ul {
  margin: 0;
  padding: 0;
}

.ltn__breadcrumb-list ul li {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  font-weight: 700;
}

.ltn__breadcrumb-list ul li:last-child {
  margin-right: 0;
  color: var(--ltn__primary-color);
}

.ltn__breadcrumb-list ul li:after {
  position: absolute;
  content: "\f054";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  font-size: 10px;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ltn__breadcrumb-list ul li:last-child:after {
  display: none;
}

/* breadcrumb-area-2 */
.ltn__breadcrumb-area-2 {
  padding-top: 250px;
  padding-bottom: 130px;
}

.ltn__breadcrumb-inner-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__breadcrumb-inner-2 .section-title-area {
  margin-bottom: 0;
}
.ltn__breadcrumb-inner-2 .ltn__section-title-2 .section-title {
  margin-bottom: 0;
}
.ltn__breadcrumb-inner-2 .ltn__breadcrumb-list {
  margin-left: 100px;
}
.ltn__breadcrumb-inner-2 .ltn__breadcrumb-list ul li:after {
  position: absolute;
  content: "";
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 10px;
  width: 2px;
  background-color: var(--ltn__color-1);
  opacity: 0.5;
}

.ltn__breadcrumb-color-white .ltn__breadcrumb-list li {
  color: var(--ltn__color-1);
}
.ltn__breadcrumb-color-white .ltn__breadcrumb-list li:last-child {
  color: var(--ltn__secondary-color);
}

/* breadcrumb-area-3 */
.ltn__breadcrumb-area-3 {
  padding-top: 350px;
  padding-bottom: 130px;
}

/* breadcrumb-area-4 */
.ltn__breadcrumb-inner-4 .section-title-area {
  margin-bottom: 0;
}

.ltn__breadcrumb-inner-4 .ltn__breadcrumb-list ul li:last-child {
  opacity: 0.5;
}

/* ----------------------------------------------------
    Responsive
---------------------------------------------------- */
@media (max-width: 767px) {
  .ltn__breadcrumb-area-2 {
    padding-top: 240px;
    padding-bottom: 80px;
  }
  .ltn__breadcrumb-inner-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    text-align: center;
  }
  .ltn__breadcrumb-inner-2 .ltn__breadcrumb-list {
    margin-left: 0;
  }
  .ltn__breadcrumb-list ul li {
    font-size: 14px;
  }
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* ============================================================
>>> TABLE OF CONTENTS:
===============================================================
# Widgets
# Search Widget
# Newsletter Widget
# Tag Cloud Widget
# Color Widget
# Menu Widget
# Popular Post Widget
# Banner Widget
# Top Rated Product Widget
# Price Filter Widget
# Instagram Widget
# Video Widget
# Author Widget
# Widget Responsive

============================================================= */
/* --------------------------------------------------------------
    Widgets
-------------------------------------------------------------- */
.widget {
  margin-bottom: 40px;
  padding: 35px 30px 40px 35px;
  border: 2px solid var(--border-color-11);
}
.widget:last-child {
  margin-bottom: 0;
}
.widget hr {
  margin-top: 35px;
  margin-bottom: 35px;
}

.ltn__widget-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
  text-transform: capitalize;
}

.ltn__widget-title-border {
  position: relative;
  padding-left: 45px;
}
.ltn__widget-title-border::after,
.ltn__widget-title-border::before {
  position: absolute;
  content: "";
  background-color: var(--ltn__secondary-color);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}
.ltn__widget-title-border::before {
  width: 18px;
  height: 4px;
  border-radius: 25px;
}
.ltn__widget-title-border::after {
  width: 4px;
  height: 4px;
  left: 22px;
  border-radius: 100%;
}

.ltn__widget-title-border-2 {
  border-left: 2px solid;
  border-color: var(--ltn__secondary-color);
  padding-left: 10px;
  position: relative;
}
.ltn__widget-title-border-2::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 2px;
  height: 14px;
  background-color: var(--ltn__secondary-color);
  display: none;
}

/* widget-2 */
.widget-2 {
  margin-bottom: 40px;
}

/* ----------------------------------------------------
    Search Widget
---------------------------------------------------- */
.ltn__search-widget form {
  position: relative;
}

.ltn__search-widget input[type="text"] {
  margin-bottom: 0;
  padding-right: 65px;
  padding-left: 20px;
  height: 60px;
  background-color: var(--section-bg-1);
  font-weight: 600;
  font-size: 14px;
}

.ltn__search-widget input[type="text"]::-webkit-input-placeholder {
  color: var(--ltn__primary-color);
  font-size: 12px;
}

.ltn__search-widget button {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 20px;
  color: var(--white);
  border: 1px solid;
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ltn__search-widget button:hover {
  color: var(--white);
  background-color: var(--ltn__primary-color);
  border-color: var(--ltn__primary-color);
}

/* ----------------------------------------------------
    Newsletter Widget
---------------------------------------------------- */
.ltn__newsletter-widget {
  background-color: var(--ltn__primary-color);
  padding: 50px 35px;
  position: relative;
  z-index: 2;
}
.ltn__newsletter-widget h6 {
  color: var(--ltn__secondary-color);
  margin-bottom: 5px;
}
.ltn__newsletter-widget h4 {
  color: var(--white);
  font-size: 36px;
}
.ltn__newsletter-widget input[type="text"] {
  background-color: #133236;
  color: var(--white);
  border-color: #133236;
  height: 70px;
  padding-left: 25px;
  padding-right: 70px;
}
.ltn__newsletter-widget input[type="text"]::-webkit-input-placeholder {
  color: var(--white);
}
.ltn__newsletter-widget input[type="text"]:-ms-input-placeholder {
  color: var(--white);
}
.ltn__newsletter-widget input[type="text"]::-ms-input-placeholder {
  color: var(--white);
}
.ltn__newsletter-widget input[type="text"]::placeholder {
  color: var(--white);
}
.ltn__newsletter-widget button {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white);
  padding: 0 25px;
}
.ltn__newsletter-widget button:hover {
  background-color: var(--ltn__secondary-color-2);
  border-color: var(--ltn__secondary-color-2);
  color: var(--white);
}

.ltn__newsletter-bg-icon {
  position: absolute;
  right: 20px;
  top: 15%;
  font-size: 120px;
  color: var(--ltn__color-1);
  opacity: 0.1;
  z-index: -1;
  line-height: 1;
}

/* ----------------------------------------------------
    Tag Cloud Widget
---------------------------------------------------- */
.ltn__tagcloud-widget ul {
  margin: 0;
  padding: 0;
}
.ltn__tagcloud-widget ul li {
  list-style: none;
  display: inline-block;
  margin: 5px 3px;
}
.ltn__tagcloud-widget ul li a {
  display: block;
  padding: 8px 24px 6px;
  font-size: 13px;
  text-transform: uppercase;
  background-color: var(--section-bg-1);
  font-weight: 700;
  font-family: var(--ltn__heading-font);
}
.ltn__tagcloud-widget ul li a:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

/* ----------------------------------------------------
    Color Widget
---------------------------------------------------- */
.ltn__color-widget ul {
  padding: 0;
  margin: 0;
}
.ltn__color-widget ul li {
  list-style: none;
  display: inline-block;
  margin-top: 3px;
  margin-right: 5px;
  background-color: var(--section-bg-1);
  border: 1px solid var(--border-color-1);
}
.ltn__color-widget ul li a {
  width: 40px;
  height: 22px;
  display: block;
}
.ltn__color-widget ul li:hover {
  outline: 2px solid var(--ltn__secondary-color);
  outline-offset: 1px;
}
.ltn__color-widget ul .black {
  background-color: var(--black);
}
.ltn__color-widget ul .white {
  background-color: var(--white);
}
.ltn__color-widget ul .red {
  background-color: var(--red);
}
.ltn__color-widget ul .silver {
  background-color: var(--silver);
}
.ltn__color-widget ul .gray {
  background-color: var(--gray);
}
.ltn__color-widget ul .maroon {
  background-color: var(--maroon);
}
.ltn__color-widget ul .yellow {
  background-color: var(--yellow);
}
.ltn__color-widget ul .olive {
  background-color: var(--olive);
}
.ltn__color-widget ul .lime {
  background-color: var(--lime);
}
.ltn__color-widget ul .green {
  background-color: var(--green);
}
.ltn__color-widget ul .aqua {
  background-color: var(--aqua);
}
.ltn__color-widget ul .teal {
  background-color: var(--teal);
}
.ltn__color-widget ul .blue {
  background-color: var(--blue);
}
.ltn__color-widget ul .navy {
  background-color: var(--navy);
}
.ltn__color-widget ul .fuchsia {
  background-color: var(--fuchsia);
}
.ltn__color-widget ul .purple {
  background-color: var(--purple);
}
.ltn__color-widget ul .pink {
  background-color: var(--pink);
}
.ltn__color-widget ul .nude {
  background-color: var(--nude);
}
.ltn__color-widget ul .orange {
  background-color: var(--orange);
}

/* ----------------------------------------------------
    Menu Widget
---------------------------------------------------- */
.ltn__menu-widget > ul {
  padding: 0;
  margin: 0;
}
.ltn__menu-widget > ul li {
  list-style: none;
  margin-top: 15px;
  display: block;
  font-size: 14px;
  font-weight: 700;
}
.ltn__menu-widget > ul li a {
  display: block;
  font-size: 14px;
  font-weight: 700;
}
.ltn__menu-widget > ul li a span {
  float: right;
  color: var(--ltn__color-5);
}
.ltn__menu-widget > ul li span {
  float: right;
  color: var(--ltn__color-5);
}

/* menu-widget-2 */
.ltn__menu-widget-2 {
  background-color: transparent;
}
.ltn__menu-widget-2 ul li a {
  background-color: var(--section-bg-1);
  padding: 20px 10px 20px 30px;
}
.ltn__menu-widget-2 ul li a span {
  background-color: var(--white);
  color: var(--ltn__primary-color);
  position: relative;
  top: -11px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__menu-widget-2 ul li.active a,
.ltn__menu-widget-2 ul li:hover > a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}
.ltn__menu-widget-2 ul li.active a span,
.ltn__menu-widget-2 ul li:hover > a span {
  background-color: var(--ltn__secondary-color-2);
  color: var(--white);
}
.ltn__menu-widget-2 ul li.active a span:hover,
.ltn__menu-widget-2 ul li:hover > a span:hover {
  background-color: var(--ltn__primary-color);
  color: var(--white);
}
.ltn__menu-widget-2 > ul > li:first-child {
  margin-top: 0;
}
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li a {
  background-color: var(--ltn__primary-color);
  color: var(--white);
}
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li a span {
  background-color: var(--ltn__primary-color-2);
  color: var(--white);
}
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li.active a,
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li:hover > a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li.active a span,
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li:hover > a span {
  background-color: var(--ltn__secondary-color-2);
  color: var(--white);
}
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li.active a span:hover,
.ltn__menu-widget-2.ltn__menu-widget-2-color-2 ul li:hover > a span:hover {
  background-color: var(--ltn__primary-color);
  color: var(--white);
}

/* ----------------------------------------------------
    Popular Post Widget
---------------------------------------------------- */
.ltn__popular-post-widget ul {
  padding: 0;
  margin: 0;
}
.ltn__popular-post-widget ul li {
  list-style: none;
}

.ltn__popular-post-widget > ul > li {
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color-1);
  padding-bottom: 30px;
}

.ltn__popular-post-widget > ul > li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0px;
}

.popular-post-widget-img {
  float: left;
  margin-right: 20px;
}
.popular-post-widget-img img {
  max-width: 80px;
}

.popular-post-widget-brief {
  overflow: hidden;
}
.popular-post-widget-brief h6 {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
}
.popular-post-widget-brief .ltn__blog-meta {
  margin-bottom: 0;
}
.popular-post-widget-brief .ltn__blog-meta li {
  color: var(--ltn__secondary-color);
}

.ltn__twitter-post-widget .popular-post-widget-img {
  float: left;
  margin-right: 20px;
}
.ltn__twitter-post-widget .popular-post-widget-img img {
  max-width: 60px;
  border-radius: 100%;
}
.ltn__twitter-post-widget .popular-post-widget-img a {
  color: var(--ltn__secondary-color);
}

.ltn__twitter-post-widget .popular-post-widget-brief p {
  margin-bottom: 5px;
}
.ltn__twitter-post-widget .popular-post-widget-brief p a {
  color: var(--ltn__secondary-color);
}

/* ----------------------------------------------------
    Banner Widget
---------------------------------------------------- */
.ltn__banner-widget {
  padding: 0 !important;
  border: 0;
}

/* ----------------------------------------------------
    Top Rated Product Widget
---------------------------------------------------- */
.ltn__top-rated-product-widget ul {
  padding: 0;
  margin: 0;
}
.ltn__top-rated-product-widget ul li {
  list-style: none;
}

.ltn__top-rated-product-widget > ul > li {
  margin-bottom: 25px;
  padding-bottom: 25px;
  border-bottom: 1px solid;
  border-color: var(--white-4);
}

.ltn__top-rated-product-widget > ul > li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}

.top-rated-product-img {
  max-width: 90px;
  float: left;
  margin-right: 20px;
}

.top-rated-product-info {
  overflow: hidden;
}
.top-rated-product-info h6 {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 500;
}
.top-rated-product-info .product-ratting {
  margin-bottom: 0;
}
.top-rated-product-info .product-ratting li {
  font-size: 10px;
}
.top-rated-product-info .product-price {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--ltn__secondary-color);
  font-weight: 700;
}
.top-rated-product-info .product-ratting ul {
  line-height: 1;
  margin-bottom: 5px;
}

/* ----------------------------------------------------
    Popular Product Widget
---------------------------------------------------- */
.ltn__popular-product-widget .ltn__product-item {
  margin-bottom: 30px;
}
.ltn__popular-product-widget .ltn__product-item .product-info {
  padding: 25px 25px 1px;
}
.ltn__popular-product-widget .ltn__product-item .product-title {
  font-size: 16px;
}
.ltn__popular-product-widget .ltn__product-item .product-img-location ul li,
.ltn__popular-product-widget .ltn__product-item .ltn__plot-brief li {
  font-size: 12px;
}

.ltn__popular-product-widget .slick-dots {
  margin-bottom: 0;
}

/* ----------------------------------------------------
    Price Filter Widget
---------------------------------------------------- */
.price_filter {
  padding-bottom: 13px;
}

.price_slider_amount {
  overflow: hidden;
}

.price_slider_amount > input[type="text"],
.price_slider_amount > input[type="submit"] {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: left;
  height: 25px;
  padding: 0;
  text-align: left;
  width: 140px;
  margin-bottom: 20px;
}

.price_slider_amount > input[type="text"] {
  font-weight: 700;
}

.price_filter .ui-widget-content {
  background-color: var(--ltn__primary-color);
  border: medium none;
  color: #222;
  height: 3px;
  width: 98%;
  background-image: none;
}

.price_filter .ui-slider .ui-slider-range {
  background-color: var(--ltn__secondary-color);
  background-image: none;
}

.price_filter .ui-state-default,
.price_filter .ui-widget-content .ui-state-default,
.price_filter .ui-widget-header .ui-state-default {
  background-color: var(--ltn__secondary-color);
  border: medium none;
  border-radius: 100%;
  height: 12px;
  margin-left: 0;
  margin-top: -6px;
  top: 50%;
  width: 12px;
  background-image: none;
}

/* ----------------------------------------------------
    Instagram Widget
---------------------------------------------------- */
.ltn__instafeed-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.ltn__instafeed-grid .instagram_gallery {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.ltn__instafeed-grid .instagram_gallery a,
.ltn__instafeed-grid .instagram_gallery .instagram-image {
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1 0 16.6666%;
  -ms-flex: 1 0 16.6666%;
  flex: 1 0 16.6666%;
  max-width: 16.6666%;
}

.ltn__instagram-widget .ltn__instafeed-grid .instagram_gallery a,
.ltn__instagram-widget
  .ltn__instafeed-grid
  .instagram_gallery
  .instagram-image {
  -webkit-flex: 0 0 33.333333%;
  -ms-flex: 0 0 33.333333%;
  -webkit-box-flex: 0;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.ltn__instafeed-slider .slick-list .slick-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.insta-slide-gutter .slick-list {
  margin-right: -7.5px !important;
  margin-left: -7.5px !important;
}
.insta-slide-gutter .slick-list .slick-slide {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.insta-grid-gutter .instagram_gallery {
  margin-right: -7.5px !important;
  margin-left: -7.5px !important;
}
.insta-grid-gutter .instagram_gallery a,
.insta-grid-gutter .instagram_gallery .instagram-image {
  padding-right: 7.5px;
  padding-left: 7.5px;
  margin: 7px 0;
}

/* ----------------------------------------------------
    Video Widget
---------------------------------------------------- */
.ltn__video-icon-1 {
  background-color: transparent;
  color: var(--white);
  height: 80px;
  width: 80px;
  border: 6px solid;
  border-color: var(--white);
  border-radius: 100%;
  font-size: 22px;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ltn__video-icon-1:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__video-icon-2 {
  height: 80px;
  width: 80px;
  background-color: var(--white);
  color: var(--ltn__secondary-color);
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  z-index: 9;
  border-radius: 100%;
}
.ltn__video-icon-2 i {
  color: var(--ltn__secondary-color);
}
.ltn__video-icon-2:hover.ltn__video-icon-2-border {
  outline: outset;
}
.ltn__video-icon-2.border-radius-no {
  border-radius: 0;
}

.ltn__video-icon-2-border {
  outline-offset: 15px;
  outline: 4px solid var(--border-color-9);
  margin: 20px;
}

.ltn__video-bg-img {
  min-height: 350px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  z-index: 99;
}

.ltn__video-img {
  position: relative;
  display: inline-block;
}
.ltn__video-img::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-color: var(--ltn__primary-color);
}
.ltn__video-img img {
  margin: 0;
}
.ltn__video-img .ltn__video-icon-1,
.ltn__video-img .ltn__video-icon-2 {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ltn__video-info {
  position: relative;
}

.ltn__video-popup-height-300 {
  height: 300px;
}

.ltn__video-popup-height-400 {
  height: 400px;
}

.ltn__video-popup-height-500 {
  height: 500px;
}

.ltn__video-popup-height-600 {
  height: 600px;
}

.text-center .slide-video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ltn__secondary-bg.ltn__video-icon-2 {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__secondary-bg.ltn__video-icon-2 i {
  color: var(--white);
}

.ltn__video-icon-2-small {
  height: 60px;
  width: 60px;
  font-size: 16px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ltn__video-popup-height-500,
  .ltn__video-popup-height-600 {
    height: 450px;
  }
}

@media (max-width: 991px) {
  .ltn__video-icon-2 {
    height: 50px;
    width: 50px;
    font-size: 18px;
  }
  .ltn__video-popup-height-500,
  .ltn__video-popup-height-600 {
    height: initial;
  }
}

@media (max-width: 767px) {
  .ltn__video-bg-img {
    min-height: 320px;
  }
  .ltn__video-bg-img.ml-30 {
    margin-left: 0;
  }
  .ltn__video-img.ml-30,
  .ltn__video-bg-img.ml-30 {
    margin-left: 0;
  }
}

/* ----------------------------------------------------
    Author Widget
---------------------------------------------------- */
.ltn__author-widget-inner img {
  margin-bottom: 25px;
  max-width: 140px;
  border-radius: 100%;
}

.ltn__author-widget-inner p {
  font-size: 14px;
}

.ltn__author-widget-inner .ltn__social-media ul li {
  color: var(--ltn__color-1);
}

.ltn__author-widget-2 .ltn__author-widget-inner img {
  margin-bottom: 25px;
  max-width: 100%;
  border-radius: inherit;
}

/* ----------------------------------------------------
    Footer About Widget
---------------------------------------------------- */
@media (min-width: 992px) {
  .footer-about-widget {
    padding-right: 35px;
  }
}

/* ----------------------------------------------------
    Widget Responsive
---------------------------------------------------- */
@media (max-width: 1199px) {
  .widget {
    padding: 35px 20px 40px 20px;
  }
  .top-rated-product-img {
    max-width: 70px;
    margin-right: 15px;
  }
}

@media (max-width: 991px) {
  .ltn__right-sidebar {
    margin-top: 100px;
  }
}

@media (max-width: 767px) {
  .car-price-filter-range .price_filter .ui-state-default:last-child::after {
    display: none;
  }
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* ============================================================
>>> TABLE OF CONTENTS:
===============================================================
# Header
# Header Top Area
# Header Middle Area
# Header Bottom Area
# Header Feature Area
# Main Menu
# Header Options
# Mini Cart
# Header Searchbox
# Currency Menu
# Header Sticky
# Utilize Mobile Menu

============================================================= */
/* --------------------------------------------------------------
    Header
-------------------------------------------------------------- */
.ltn__header-area {
  z-index: 9999;
  position: relative;
}

/* ----------------------------------------------
    Header Top Area
---------------------------------------------- */
.ltn__header-top-area {
  border-bottom: 1px solid;
  border-color: var(--border-color-3);
}
.ltn__header-top-area .ltn__social-media ul li {
  font-size: 14px;
  margin: 0 10px 0 0;
}

.top-area-color-white {
  background-color: var(--ltn__primary-color);
}
.top-area-color-white p,
.top-area-color-white a,
.top-area-color-white li,
.top-area-color-white .welcome p,
.top-area-color-white .welcome a,
.top-area-color-white .ltn__drop-menu > ul > li > a,
.top-area-color-white .ltn__drop-menu > ul > li:hover > a {
  color: var(--white);
}

.ltn__header-top-area .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* welcome */
.welcome p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 40px;
}

.ltn__top-bar-menu > ul {
  padding: 0;
  margin: 0;
}
.ltn__top-bar-menu > ul > li {
  list-style: none;
  display: inline-block;
  margin: 0 30px 0 0;
  font-size: 14px;
  font-weight: 700;
}
.ltn__top-bar-menu > ul > li:last-child {
  margin-right: 0;
}
.ltn__top-bar-menu > ul > li > i,
.ltn__top-bar-menu > ul > li > a > i {
  margin-right: 3px;
  color: var(--ltn__secondary-color);
}

.ltn__top-bar-menu .ltn__currency-menu .active-currency {
  font-size: 14px;
  font-weight: 700;
}

.header-top-btn {
  display: inline-block;
}
.header-top-btn a {
  display: inline-block;
  padding: 0 20px;
  line-height: 40px;
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}
.header-top-btn a:hover {
  background-color: var(--ltn__secondary-color-2);
}

/* ----------------------------------------------
    Header Middle Area
---------------------------------------------- */
.ltn__header-middle-area {
  z-index: 999;
}

.ltn__header-middle-area > .container-fluid > .row,
.ltn__header-middle-area > .container > .row {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.ltn__header-middle-area > .container-fluid > .row > .col,
.ltn__header-middle-area > .container > .row > .col {
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 1;
  position: static;
}

.site-logo {
  min-width: 185px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
}
.site-logo a {
  color: var(--ltn__primary-color);
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
  display: contents;
}
.site-logo i {
  color: var(--ltn__secondary-color);
  margin-right: 5px;
  font-size: 24px;
  position: relative;
  top: -2px;
}

.ltn__header-1 .ltn__header-middle-area {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* ----------------------------------------------
    Header Bottom Area (header-2)
---------------------------------------------- */
.header-bottom-area .row {
  position: relative;
}
.header-bottom-area .row .col {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sticky-logo {
  max-width: 200px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  height: 100%;
  float: left;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  display: none;
}

.header-menu-2 {
  text-align: center;
}
.header-menu-2 .ltn__main-menu > ul {
  display: inline-block;
}

/* ----------------------------------------------
    Header Feature Area
---------------------------------------------- */
.header-feature-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-feature-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 50px;
  max-width: 50%;
}
.header-feature-item:last-child {
  margin-right: 0;
}
.header-feature-item h6 {
  margin-bottom: 0;
  color: var(--ltn__body-color);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 1;
}
.header-feature-item p {
  margin-bottom: 0;
  font-size: 16px;
  font-family: var(--ltn__heading-font);
  line-height: 1.2;
}

.header-feature-icon {
  margin-right: 10px;
  font-size: 25px;
  line-height: 1;
  color: var(--ltn__heading-color);
}

/* ----------------------------------------------
    Main Menu
---------------------------------------------- */
.header-menu-wrap,
.header-menu-column {
  position: inherit;
}

.ltn__main-menu ul {
  margin-bottom: 0;
}

.ltn__main-menu > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
}

.ltn__main-menu li {
  list-style: none;
  display: block;
  margin-top: 0;
}

.ltn__main-menu li > a {
  position: relative;
}

.ltn__main-menu > ul > li {
  display: inline-block;
  position: relative;
  margin-right: 20px;
}

.ltn__main-menu > ul > li:last-child {
  margin-right: 0px;
}

.ltn__main-menu > ul > li > a {
  font-size: 18px;
  padding: 20px 10px;
  display: inline-block;
  white-space: nowrap;
  color: var(--ltn__heading-color);
  font-weight: 600;
  font-family: var(--ltn__body-font);
}

.ltn__main-menu li:hover > a {
  color: var(--ltn__secondary-color);
}

/* Submenu */
.ltn__main-menu li > ul,
.ltn__main-menu .sub-menu {
  position: absolute;
  margin: 0;
  top: 130%;
  -webkit-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  -o-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  min-width: 230px;
  padding: 15px 0;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  background-color: #fff;
  left: inherit;
  right: inherit;
  opacity: 0;
  visibility: hidden;
  text-align: left;
  z-index: 999;
  border-top: 5px solid var(--ltn__secondary-color);
}

.ltn__main-menu li ul li,
.ltn__main-menu .sub-menu li {
  line-height: 1.3;
  padding: 12px 25px 12px 30px;
  font-size: 16px;
}

.mega-menu li ul {
  border-top: 0;
}

.mega-menu li ul li {
  padding-left: 10px;
  padding-right: 0;
}

.ltn__main-menu li:hover > ul,
.ltn__main-menu li:hover > .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.ltn__main-menu li ul li > ul,
.ltn__main-menu .sub-menu li > .sub-menu {
  left: 100%;
}

.ltn__main-menu li ul li:hover > ul,
.ltn__main-menu .sub-menu li:hover > .sub-menu {
  top: 0;
}

.ltn__main-menu li ul li,
.ltn__main-menu .sub-menu li {
  position: relative;
}

.ltn__main-menu > ul > li:last-child ul li > ul,
.ltn__main-menu > ul > li:last-child .sub-menu li > .sub-menu,
.ltn__main-menu > ul > li:nth-last-child(2) ul li > ul,
.ltn__main-menu > ul > li:nth-last-child(2) .sub-menu li > .sub-menu,
.ltn__main-menu > ul > li:nth-last-child(3) ul li > ul,
.ltn__main-menu > ul > li:nth-last-child(3) .sub-menu li > .sub-menu {
  left: auto;
  right: 100%;
}

.ltn__main-menu li > ul.ltn__sub-menu-col-2,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-2,
.ltn__main-menu li > ul.ltn__sub-menu-col-3,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 {
  width: 510px;
}
.ltn__main-menu li > ul.ltn__sub-menu-col-2 > li,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-2 > li,
.ltn__main-menu li > ul.ltn__sub-menu-col-3 > li,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 > li {
  width: 50%;
  float: left;
  padding: 12px 20px 12px 25px;
}
.ltn__main-menu li > ul.ltn__sub-menu-col-2 > li > a,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-2 > li > a,
.ltn__main-menu li > ul.ltn__sub-menu-col-3 > li > a,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 > li > a {
  padding: 12px 20px 12px 20px;
  margin-left: 0;
  border-bottom: 1px dashed #ddd;
  display: block;
  font-weight: 600;
  background-color: var(--section-bg-1);
}
.ltn__main-menu li > ul.ltn__sub-menu-col-2 .sub-menu-child,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-2 .sub-menu-child,
.ltn__main-menu li > ul.ltn__sub-menu-col-3 .sub-menu-child,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 .sub-menu-child {
  position: inherit;
  float: left;
  width: 100%;
  left: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  z-index: inherit;
}
.ltn__main-menu li > ul.ltn__sub-menu-col-2 .sub-menu-child li,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-2 .sub-menu-child li,
.ltn__main-menu li > ul.ltn__sub-menu-col-3 .sub-menu-child li,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 .sub-menu-child li {
  padding: 12px 20px 12px 20px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
}
.ltn__main-menu li > ul.ltn__sub-menu-col-2 .sub-menu-child li img,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-2 .sub-menu-child li img,
.ltn__main-menu li > ul.ltn__sub-menu-col-3 .sub-menu-child li img,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 .sub-menu-child li img {
  position: absolute;
  bottom: -20px;
  left: 50%;
  width: 150px;
  height: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  z-index: 999;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__main-menu li > ul.ltn__sub-menu-col-2 .sub-menu-child li:hover img,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-2 .sub-menu-child li:hover img,
.ltn__main-menu li > ul.ltn__sub-menu-col-3 .sub-menu-child li:hover img,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 .sub-menu-child li:hover img {
  bottom: -10px;
  visibility: visible;
  opacity: 1;
}

.ltn__main-menu li > ul.ltn__sub-menu-col-3,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 {
  width: 780px;
}
.ltn__main-menu li > ul.ltn__sub-menu-col-3 > li,
.ltn__main-menu .sub-menu.ltn__sub-menu-col-3 > li {
  width: 33.33%;
}

.ltn__main-menu li:hover > ul.ltn__sub-menu-col-2 .sub-menu-child,
.ltn__main-menu li:hover > .sub-menu.ltn__sub-menu-col-2 .sub-menu-child {
  opacity: 1;
  visibility: visible;
}

.menu-pages-img-show li {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
}
.menu-pages-img-show li img {
  position: absolute;
  bottom: -20px;
  left: 50%;
  width: 130px;
  height: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  z-index: 999;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.menu-pages-img-show li:hover img {
  bottom: -10px;
  visibility: visible;
  opacity: 1;
}

/* Menu Reverse */
.ltn__main-menu > ul > li:last-child,
.ltn__main-menu > ul > li:nth-last-child(2) {
  position: relative;
}

.ltn__main-menu > ul > li:last-child > ul,
.ltn__main-menu > ul > li:last-child > .sub-menu,
.ltn__main-menu > ul > li:nth-last-child(2) > ul,
.ltn__main-menu > ul > li:nth-last-child(2) > .sub-menu {
  left: auto;
  right: 0;
}

/* Mega Menu */
.ltn__main-menu .mega-menu {
  left: 0;
  right: auto;
  overflow: hidden;
  width: 100%;
}

.mega-menu > li {
  float: left;
  padding-bottom: 0 !important;
  min-width: 250px !important;
}

.mega-menu > li {
  min-width: 220px;
}

.ltn__main-menu li:hover ul.mega-menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaley(1);
  -ms-transform: scaley(1);
  transform: scaley(1);
}

.mega-menu li ul,
.mega-menu li .sub-menu {
  left: 0% !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: inherit;
}

/* .ltn__main-menu li:hover .mega-menu li ul */
.ltn__main-menu li .mega-menu > li > ul,
.ltn__main-menu li .mega-menu .sub-menu {
  top: 0;
}

.ltn__main-menu li:hover .mega-menu > li > ul,
.ltn__main-menu li:hover .mega-menu .sub-menu {
  opacity: 1;
  visibility: visible;
}

.mega-menu > li > a {
  margin-left: 0;
  border-bottom: 1px dashed #ddd;
  display: block;
  font-weight: 600;
  padding: 10px;
  background-color: var(--section-bg-1);
}

.mega-menu.column-1,
.mega-menu.column-2 {
  left: auto;
}

.mega-menu.column-2 > li {
  min-width: 50%;
  width: 50%;
}

.mega-menu.column-3 > li {
  min-width: 33.33%;
  width: 33.33%;
}

@media (min-width: 991px) {
  .mega-menu.column-4 > li,
  .mega-menu.column-5 > li,
  .mega-menu.column-6 > li,
  .mega-menu.column-7 > li,
  .mega-menu.column-8 > li,
  .mega-menu.column-9 > li,
  .mega-menu.column-10 > li,
  .mega-menu.column-11 > li,
  .mega-menu.column-12 > li {
    min-width: 25%;
    width: 25%;
  }
}

@media (max-width: 991px) {
  .mega-menu.column-2 > li,
  .mega-menu.column-3 > li,
  .mega-menu.column-4 > li,
  .mega-menu.column-5 > li,
  .mega-menu.column-6 > li,
  .mega-menu.column-7 > li,
  .mega-menu.column-8 > li,
  .mega-menu.column-9 > li,
  .mega-menu.column-10 > li,
  .mega-menu.column-11 > li,
  .mega-menu.column-12 > li {
    min-width: 50%;
    width: 50%;
  }
}

@media (max-width: 767px) {
  .mega-menu.column-2 > li,
  .mega-menu.column-3 > li,
  .mega-menu.column-4 > li,
  .mega-menu.column-5 > li,
  .mega-menu.column-6 > li,
  .mega-menu.column-7 > li,
  .mega-menu.column-8 > li,
  .mega-menu.column-9 > li,
  .mega-menu.column-10 > li,
  .mega-menu.column-11 > li,
  .mega-menu.column-12 > li {
    min-width: 100%;
    width: 100%;
  }
}

/* Mega menu submenu submenu */
.mega-menu li ul li ul {
  left: 100% !important;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
}

.mega-menu li ul li:hover > ul {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 991px) {
  .mega-menu-parent {
    position: inherit !important;
  }
}

/* Menu Icon */
.menu-icon > a {
  position: relative;
}
.menu-icon > a::before {
  content: "\f067";
  font-size: 8px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
}

/* Menu Item Badge */
.menu-item-badge {
  position: absolute;
  left: -8px;
  top: -10px;
  font-size: 10px;
  padding: 1px 3px 0px;
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  border-radius: 2px;
  text-transform: uppercase;
}

/* Menu Porduct Item */
.menu-product-item {
  margin-bottom: 30px;
  margin-top: 15px;
  border: 2px solid;
  border-color: var(--border-color-8);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: center;
}
.menu-product-item .product-info {
  padding: 25px 15px 15px;
  padding-top: 0;
}
.menu-product-item .product-title {
  font-size: 16px;
  margin-bottom: 5px;
}
.menu-product-item .product-price {
  font-size: 16px;
  color: var(--ltn__secondary-color);
  font-weight: 600;
}
.menu-product-item .product-price del {
  font-size: 15px;
  opacity: 0.5;
  color: var(--gray);
}

.menu-product-item:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

/* ----------------------------------------------
    Header Options
---------------------------------------------- */
.ltn__header-options > ul {
  padding: 0;
  margin: 0;
}
.ltn__header-options > ul > li {
  display: inline-block;
  margin-right: 15px;
  margin-top: 0;
  list-style: none;
}
.ltn__header-options > ul > li:last-child {
  margin-right: 0;
}

.ltn__header-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.ltn__header-options ul li {
  list-style: none;
}
.ltn__header-options > div {
  margin-right: 20px;
}
.ltn__header-options > div:last-child {
  margin-right: 0;
}
.ltn__header-options .ltn__drop-menu > ul > li > a {
  padding: 0;
}
.ltn__header-options .ltn__drop-menu ul {
  padding: 0;
}
.ltn__header-options .ltn__drop-menu ul li ul {
  padding: 10px 0;
}

.ltn__header-options-2 > div {
  margin-right: 10px;
}

.ltn__header-options-2 .header-search-1,
.ltn__header-options-2 .ltn__drop-menu > ul > li > a,
.ltn__header-options-2 .mini-cart-icon a,
.ltn__header-options-2 .header-wishlist {
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: var(--white);
  color: var(--ltn__heading-color);
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__header-options-2 .header-search-1:hover,
.ltn__header-options-2 .ltn__drop-menu > ul > li > a:hover,
.ltn__header-options-2 .mini-cart-icon a:hover,
.ltn__header-options-2 .header-wishlist:hover {
  background: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__header-options-2 .header-search-1:hover,
.ltn__header-options-2 .ltn__drop-menu > ul > li:hover > a,
.ltn__header-options-2 .mini-cart-icon a:hover {
  background: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__header-options-2 .ltn__drop-menu.ltn__currency-menu > ul > li > a {
  height: inherit;
  width: inherit;
  line-height: inherit;
  background: inherit;
  color: inherit;
}

.ltn__header-options-2 .mobile-menu-toggle > a {
  width: 50px;
  height: 50px;
  background: var(--white);
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

/* ltn__drop-menu */
.ltn__drop-menu {
  display: inline-block;
  text-align: left;
}

.ltn__drop-menu li {
  margin-top: 0;
}

.ltn__drop-menu + .ltn__drop-menu {
  margin-left: 10px;
}

.ltn__drop-menu ul {
  margin-bottom: 0;
}

.ltn__drop-menu > ul > li {
  display: inline-block;
  position: relative;
}

.ltn__drop-menu > ul > li > a {
  display: block;
  text-decoration: none;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
}

.ltn__drop-menu ul li ul {
  position: absolute;
  min-width: 150px;
  right: 0;
  background-color: #fff;
  z-index: 999;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border-top: 1px solid #ececec;
  top: 130%;
  padding: 10px 0;
  margin: 0;
  -webkit-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  -o-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  opacity: 0;
  visibility: hidden;
}

.ltn__drop-menu ul li ul li {
  display: block;
  padding: 5px 15px;
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  font-weight: 500;
}

.ltn__drop-menu ul li ul li a {
  color: inherit;
}

.ltn__drop-menu ul li:hover > ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.ltn__drop-menu ul li:hover > a {
  color: var(--ltn__secondary-color);
}

.ltn__drop-menu > ul > li,
.header-wishlist a {
  font-size: 20px;
}

/* header-wishlist */
.header-wishlist {
  color: var(--ltn__heading-color);
}

/* ----------------------------------------------
    Mini Cart
---------------------------------------------- */
.ltn__mini-cart ul {
  margin: 0;
  padding: 0;
}

.mini-cart-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 30px;
  cursor: pointer;
  color: var(--ltn__heading-color);
}
.mini-cart-icon i {
  font-size: 18px;
}
.mini-cart-icon sup {
  font-size: 14px;
  font-weight: 600;
}

.mini-cart-header h5 {
  margin-bottom: 0;
}

.mini-cart-product-area {
  max-height: calc(100% - (60px + 245px));
}

.ltn__utilize-cart-menu .ltn__mini-cart {
  max-height: calc(100% - (60px + 245px));
}

.mini-cart-item {
  padding-top: 20px;
  padding-left: 10px;
  margin-bottom: 20px;
  border-top: 1px solid var(--border-color-1);
}
.mini-cart-item:first-child {
  border-top: 0;
}

.mini-cart-img {
  float: left;
  width: 80px;
  margin-right: 15px;
  position: relative;
}

.mini-cart-img img {
  background-color: var(--section-bg-1);
}

.mini-cart-info {
  overflow: hidden;
}

.mini-cart-info h6 {
  margin-bottom: 5px;
  font-weight: 500;
}

.mini-cart-item-delete {
  position: absolute;
  left: -8px;
  top: -8px;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  display: block;
  line-height: 20px;
  background-color: #fff;
  text-align: center;
  font-size: 10px;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.mini-cart-item-delete:hover {
  background-color: var(--red);
  color: #fff;
}

.mini-cart-footer {
  margin-top: 25px;
}
.mini-cart-footer p {
  font-size: 14px;
  margin-bottom: 0;
}

.mini-cart-sub-total {
  border-top: 1px solid var(--border-color-1);
  border-bottom: 1px solid var(--border-color-1);
  padding: 20px 0;
}
.mini-cart-sub-total h5 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}
.mini-cart-sub-total span {
  color: var(--ltn__secondary-color);
}

.mini-cart-footer .btn-wrapper {
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 25px 0 15px;
}

.mini-cart-footer .btn-wrapper .btn {
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 14px;
}

.mini-cart-icon-2 > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-width: 125px;
}
.mini-cart-icon-2 > a .mini-cart-icon {
  margin-right: 5px;
}
.mini-cart-icon-2 > a .mini-cart-icon i {
  color: var(--ltn__heading-color);
  font-size: 20px;
}
.mini-cart-icon-2 > a .mini-cart-icon sup {
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  width: 20px;
  line-height: 20px;
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  text-align: center;
  border-radius: 100%;
  right: 8px;
  top: -8px;
}
.mini-cart-icon-2 > a h6 {
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  line-height: 20px;
  font-weight: 700;
  color: var(--ltn__body-color);
  font-size: 14px;
  text-transform: uppercase;
}

.mini-cart-icon-2 ul li {
  list-style: none;
}

/* ----------------------------------------
  Header Searchbox
---------------------------------------- */
.header-search-1 {
  cursor: pointer;
  display: inline-block;
}
.header-search-1 .search-icon {
  min-width: 25px;
  text-align: center;
  font-size: 16px;
}

.header-search-wrap {
  position: relative;
}

.header-search-1-form {
  background-color: var(--white);
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  height: 0;
  margin-right: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 130%;
  -webkit-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-transition-property: height;
  -o-transition-property: height;
  transition-property: height;
  width: 320px;
  z-index: 9999;
}
.header-search-1-form form {
  position: relative;
  margin: 15px 15px 0;
}
.header-search-1-form input {
  height: 60px;
  padding-right: 50px;
}
.header-search-1-form button[type="submit"] {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: var(--ltn__heading-color);
  display: block;
  font-size: 18px;
  height: 60px;
  line-height: 48px;
  position: absolute;
  right: 8px;
  text-align: center;
  top: 0;
  padding: 1px 6px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.header-search-1-form button[type="submit"]:hover {
  color: var(--ltn__primary-color);
}

.search-icon {
  position: relative;
}
.search-icon .for-search-close {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
}

.search-open.header-search-1-form {
  height: 90px;
}

.search-open .for-search-show {
  opacity: 0;
  visibility: hidden;
}

.search-open .for-search-close {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 767px) {
  .header-search-wrap {
    position: inherit;
  }
  .header-search-1-form {
    top: 100%;
  }
  .header-search-1-form {
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
    right: 50%;
  }
}

@media (max-width: 575px) {
  .header-search-1-form {
    width: 270px;
  }
}

/* search-2 */
.header-search-2 {
  min-width: 500px;
}
.header-search-2 form {
  position: relative;
}
.header-search-2 input {
  margin-bottom: 0;
  border: 0;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  border-radius: 50px;
  padding-left: 20px;
  padding-right: 50px;
  height: 45px;
}
.header-search-2 button {
  position: absolute;
  right: 0;
  background-color: transparent;
  height: 45px;
  top: 0;
  padding: 0 15px;
}

/* ----------------------------------------
    Currency Menu
---------------------------------------- */
.ltn__currency-menu .active-currency {
  font-size: 16px;
  font-weight: 700;
}

.ltn__currency-menu ul li ul {
  min-width: 200px;
}

.ltn__currency-menu .dropdown-toggle::after {
  margin-left: 3px;
  border-top: 0.25em solid;
  border-right: 0.2em solid transparent;
  border-left: 0.2em solid transparent;
}

.ltn__language-menu .dropdown-toggle {
  padding-right: 10px !important;
  position: relative;
}
.ltn__language-menu .dropdown-toggle::after {
  display: none;
}
.ltn__language-menu .dropdown-toggle::before {
  content: "\e911";
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: -5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "icomoon";
  color: var(--ltn__secondary-color);
}

/* ----------------------------------------
  Header 2
---------------------------------------- */
.ltn__header-2 .sticky-active .row .col {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.ltn__header-2 .sticky-active .sticky-logo {
  display: block;
}

.ltn__header-2 .sticky-active .header-menu-2 {
  text-align: right;
}

/* ----------------------------------------
  Header 3
---------------------------------------- */
.ltn__header-3 .ltn__header-middle-area {
  padding: 30px 0;
}

.ltn__header-3 .header-bottom-area.sticky-active {
  padding-top: 0px;
  padding-bottom: 0px;
}

.ltn__header-3 .sticky-active .row .col {
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.ltn__header-3 .sticky-active .sticky-logo {
  display: block;
}

.ltn__header-3 .sticky-active .header-menu-2 {
  text-align: right;
}

/* ----------------------------------------
    Header 4
---------------------------------------- */
.ltn__header-4 .ltn__header-middle-area,
.ltn__header-5 .ltn__header-middle-area {
  padding-top: 22px;
  padding-bottom: 22px;
}

.ltn__header-4 .ltn__header-top-area {
  border-color: var(--border-color-3);
}

.site-logo-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.site-logo-wrap .site-logo {
  min-width: auto;
}

.get-support {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding-left: 20px;
  margin-left: 20px;
}
.get-support::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 30px;
  width: 1px;
  background-color: #999;
}
.get-support .get-support-icon {
  margin-right: 10px;
  font-size: 35px;
  line-height: 1;
  color: var(--ltn__secondary-color);
}
.get-support .get-support-icon i {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.get-support .get-support-info {
  margin: 0;
  text-align: left;
}
.get-support .get-support-info h6 {
  margin: 0;
  font-weight: 600;
  font-size: 14px;
}
.get-support .get-support-info h4 {
  margin: 5px 0 0;
  line-height: 1;
}

.get-support-color-white .get-support-info h6,
.get-support-color-white .get-support-info h4 {
  color: var(--white);
}

.special-link {
  margin-left: 15px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}
.special-link a {
  background-color: var(--ltn__secondary-color);
  color: var(--white) !important;
  padding: 12px 30px !important;
}
.special-link a:hover {
  background-color: var(--section-bg-1);
  color: var(--ltn__primary-color) !important;
}

.menu-color-white .ltn__main-menu > ul > li > a {
  color: var(--white);
}

/* ----------------------------------------
  Header 5
---------------------------------------- */
.ltn__header-5 .get-support::before {
  background-color: #e4e8ea;
}

.ltn__header-5 .ltn__header-options {
  margin-left: 30px;
}

/* ----------------------------------------
  ltn__header-logo-mobile-menu-in-mobile
---------------------------------------- */
.ltn__header-logo-mobile-menu-in-mobile {
  margin-top: 0;
}

/* ----------------------------------------------
    Header Sticky
---------------------------------------------- */
.sticky-active {
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  background-color: var(--white);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  -webkit-box-shadow: 0 10px 50px 0 rgba(46, 56, 220, 0.2);
  box-shadow: 0 10px 50px 0 rgba(46, 56, 220, 0.2);
}

.ltn__header-4 .ltn__header-middle-area.sticky-active,
.ltn__header-5 .ltn__header-middle-area.sticky-active {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Sticky Background Secondary */
.sticky-active.ltn__sticky-bg-secondary {
  background-color: var(--ltn__secondary-color);
}
.sticky-active.ltn__sticky-bg-secondary .ltn__main-menu > ul > li > a,
.sticky-active.ltn__sticky-bg-secondary .header-wishlist a,
.sticky-active.ltn__sticky-bg-secondary .mini-cart-icon,
.sticky-active.ltn__sticky-bg-secondary .header-feature-item h6,
.sticky-active.ltn__sticky-bg-secondary .header-feature-item p,
.sticky-active.ltn__sticky-bg-secondary .header-feature-icon,
.sticky-active.ltn__sticky-bg-secondary .header-search-1 .search-icon,
.sticky-active.ltn__sticky-bg-secondary
  .ltn__header-options
  .ltn__drop-menu
  > ul
  > li
  > a {
  color: var(--white);
}
.sticky-active.ltn__sticky-bg-secondary .ltn__header-options-2 .header-search-1,
.sticky-active.ltn__sticky-bg-secondary
  .ltn__header-options-2
  .header-search-1
  i,
.sticky-active.ltn__sticky-bg-secondary
  .ltn__header-options-2
  .ltn__drop-menu
  > ul
  > li
  > a,
.sticky-active.ltn__sticky-bg-secondary
  .ltn__header-options-2
  .mini-cart-icon
  a,
.sticky-active.ltn__sticky-bg-secondary
  .ltn__header-options-2
  .header-wishlist {
  background: var(--white);
  color: var(--ltn__heading-color);
}

/* Sticky Background Black */
.sticky-active.ltn__sticky-bg-black {
  background-color: var(--ltn__heading-color);
}
.sticky-active.ltn__sticky-bg-black .ltn__main-menu > ul > li > a,
.sticky-active.ltn__sticky-bg-black .header-wishlist a,
.sticky-active.ltn__sticky-bg-black .mini-cart-icon,
.sticky-active.ltn__sticky-bg-black .header-feature-item h6,
.sticky-active.ltn__sticky-bg-black .header-feature-item p,
.sticky-active.ltn__sticky-bg-black .header-feature-icon,
.sticky-active.ltn__sticky-bg-black .header-search-1 .search-icon,
.sticky-active.ltn__sticky-bg-black
  .ltn__header-options
  .ltn__drop-menu
  > ul
  > li
  > a {
  color: var(--white);
}
.sticky-active.ltn__sticky-bg-black .ltn__header-options-2 .header-search-1,
.sticky-active.ltn__sticky-bg-black .ltn__header-options-2 .header-search-1 i,
.sticky-active.ltn__sticky-bg-black
  .ltn__header-options-2
  .ltn__drop-menu
  > ul
  > li
  > a,
.sticky-active.ltn__sticky-bg-black .ltn__header-options-2 .mini-cart-icon a,
.sticky-active.ltn__sticky-bg-black .ltn__header-options-2 .header-wishlist {
  background: var(--white);
  color: var(--ltn__heading-color);
}

/* Sticky Background White */
.sticky-active.ltn__sticky-bg-white {
  background-color: var(--white);
}
.sticky-active.ltn__sticky-bg-white .ltn__main-menu > ul > li > a,
.sticky-active.ltn__sticky-bg-white .header-wishlist a,
.sticky-active.ltn__sticky-bg-white .mini-cart-icon,
.sticky-active.ltn__sticky-bg-white .header-feature-item h6,
.sticky-active.ltn__sticky-bg-white .header-feature-item p,
.sticky-active.ltn__sticky-bg-white .header-feature-icon,
.sticky-active.ltn__sticky-bg-white .header-search-1 .search-icon,
.sticky-active.ltn__sticky-bg-white
  .ltn__header-options
  .ltn__drop-menu
  > ul
  > li
  > a {
  color: var(--ltn__heading-color);
}
.sticky-active.ltn__sticky-bg-white .ltn__header-options-2 .header-search-1,
.sticky-active.ltn__sticky-bg-white .ltn__header-options-2 .header-search-1 i,
.sticky-active.ltn__sticky-bg-white
  .ltn__header-options-2
  .ltn__drop-menu
  > ul
  > li
  > a,
.sticky-active.ltn__sticky-bg-white .ltn__header-options-2 .mini-cart-icon a,
.sticky-active.ltn__sticky-bg-white .ltn__header-options-2 .header-wishlist {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
  background: var(--white);
  color: var(--ltn__heading-color);
}

.header-contact-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* ltn__header-transparent */
.ltn__header-transparent {
  position: absolute;
  width: 100%;
  z-index: 999;
  background-color: transparent;
}
.ltn__header-transparent .top-area-color-white {
  background-color: transparent;
}

/* ----------------------------------------
  Utilize Mobile Menu
---------------------------------------- */
.ltn__utilize {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: auto;
  display: block;
  overflow: hidden;
  width: 400px;
  height: 100vh;
  padding: 20px 10px 20px 30px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  background-color: var(--white);
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 99999;
}

.ltn__utilize.ltn__utilize-mobile-menu {
  right: auto;
  left: 0;
  padding: 50px 40px;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.ltn__utilize-menu-inner {
  position: relative;
  z-index: 9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -ms-touch-action: auto;
  touch-action: auto;
  overflow-x: hidden !important;
  padding-right: 20px;
}

.ltn__utilize-menu-search-form {
  margin-bottom: 30px;
  position: relative;
}
.ltn__utilize-menu-search-form input[type="text"] {
  margin-bottom: 0;
}
.ltn__utilize-menu-search-form button {
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 100%;
  padding: 0 15px;
}

@media (max-width: 479px) {
  .ltn__utilize {
    width: 300px;
  }
  .ltn__utilize.ltn__utilize-mobile-menu {
    padding: 50px 20px;
  }
}

.ltn__utilize-menu > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ltn__utilize-menu > ul > li {
  position: relative;
}

.ltn__utilize-menu > ul > li .menu-expand {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 24px;
  height: 44px;
  cursor: pointer;
  background-color: transparent;
}

.ltn__utilize-menu > ul > li .menu-expand::before,
.ltn__utilize-menu > ul > li .menu-expand::after {
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 7px);
  width: 14px;
  height: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  background-color: #7e7e7e;
}

.ltn__utilize-menu > ul > li .menu-expand::after {
  -webkit-transform: rotate(90deg) scale(0.75);
  -ms-transform: rotate(90deg) scale(0.75);
  transform: rotate(90deg) scale(0.75);
}

.ltn__utilize-menu > ul > li.active .menu-expand::after {
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
}

.ltn__utilize-menu > ul > li > a {
  display: block;
  padding: 8px 24px 8px 0;
  text-transform: uppercase;
}

.ltn__utilize-menu > ul > li .sub-menu {
  position: static;
  top: auto;
  display: none;
  visibility: visible;
  width: 100%;
  min-width: auto;
  padding: 0;
  padding-left: 15px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  opacity: 1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ltn__utilize-menu > ul > li .sub-menu li {
  line-height: inherit;
  position: relative;
  list-style: none;
}

.ltn__utilize-overlay {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.ltn__utilize.ltn__utilize-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.ltn__utilize.ltn__utilize-mobile-menu.ltn__utilize-open {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.mobile-menu-toggle {
  margin-right: 15px;
}

.mobile-menu-toggle > a {
  width: 24px;
  height: 32px;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: #333;
  margin-left: auto;
}

.mobile-menu-toggle svg {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 60px;
  margin-top: -2px;
  margin-left: -2px;
  cursor: pointer;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.mobile-menu-toggle svg path {
  -webkit-transition: stroke-dashoffset 0.5s
      cubic-bezier(0.25, -0.25, 0.75, 1.25),
    stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  -o-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
    stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
    stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
  fill: none;
  stroke: #333;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  stroke-width: 30px;
}

.mobile-menu-toggle svg path#top,
.mobile-menu-toggle svg path#bottom {
  stroke-dasharray: 240px 950px;
}

.mobile-menu-toggle svg path#middle {
  stroke-dasharray: 240px 240px;
}

.mobile-menu-toggle .close svg path#top,
.mobile-menu-toggle .close svg path#bottom {
  stroke-dasharray: -650px;
  stroke-dashoffset: -650px;
}

.mobile-menu-toggle .close svg path#middle {
  stroke-dasharray: 1px 220px;
  stroke-dashoffset: -115px;
}

.ltn__utilize-menu-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}
.ltn__utilize-menu-head .ltn__utilize-menu-title {
  font-weight: 700;
}
.ltn__utilize-menu-head .ltn__utilize-close {
  background-color: transparent;
  font-size: 30px;
  padding: 0 15px;
}

.ltn__utilize-buttons {
  border-top: 1px solid var(--border-color-1);
  padding: 5px 0px 20px;
  margin: 30px 0;
  border-bottom: 1px solid var(--border-color-1);
}
.ltn__utilize-buttons ul {
  padding: 0;
  margin: 0;
}
.ltn__utilize-buttons ul li {
  display: inline-block;
}
.ltn__utilize-buttons .utilize-btn-icon {
  width: 50px;
  display: inline-block;
  height: 50px;
  border: 2px solid var(--border-color-1);
  line-height: 46px;
  text-align: center;
  margin-right: 10px;
}

.ltn__utilize-buttons-2 ul li {
  display: block;
}

.menu-btn-white.mobile-menu-toggle > a {
  color: #fff;
}

.menu-btn-white.mobile-menu-toggle svg path {
  stroke: #fff;
}

.menu-btn-border a {
  width: 40px;
  height: 38px;
  border: 1px solid;
}

/* ----------------------------------------
  Responsive
---------------------------------------- */
@media (min-width: 1200px) and (max-width: 1300px) {
  .ltn__main-menu > ul > li {
    margin-right: 15px;
  }
  .ltn__main-menu > ul > li:last-child {
    margin-right: 0;
  }
  .ltn__main-menu > ul > li > a {
    font-size: 15px;
  }
  .ltn__header-6 .ltn__main-menu > ul > li {
    margin-right: 10px;
  }
  .ltn__header-6 .ltn__main-menu > ul > li:last-child {
    margin-right: 0px;
  }
  .ltn__header-6 .special-link a {
    padding: 15px 20px !important;
  }
  .ltn__header-6 .ltn__main-menu > ul > li > a {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .site-logo {
    min-width: 180px;
  }
  .ltn__main-menu > ul > li > a {
    padding: 20px 15px;
  }
  .ltn__main-menu > ul > li {
    margin-right: 10px;
  }
  .ltn__header-3 .header-feature-item {
    display: none;
  }
  .ltn__header-logo-and-mobile-menu .site-logo-wrap {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 10px 0 10px;
  }
  .ltn__header-logo-and-mobile-menu
    .ltn__header-middle-area
    > .container-fluid
    > .row
    > .col,
  .ltn__header-logo-and-mobile-menu
    .ltn__header-middle-area
    > .container
    > .row
    > .col {
    max-width: 65%;
  }
  .ltn__header-logo-and-mobile-menu .ltn__header-options {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
  }
}

@media (max-width: 991px) {
  .header-search-column,
  .header-menu-column {
    display: none;
  }
  /* sticky */
  .sticky-active {
    position: inherit;
  }
  .ltn__header-2
    .ltn__header-middle-area
    > .container
    > .row
    .header-feature-column {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    border-top: 1px solid #ddd;
  }
  .ltn__header-2 .header-feature-area {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .ltn__header-2 .header-feature-item {
    margin-right: 15px;
  }
  .ltn__header-3
    .ltn__header-middle-area
    > .container
    > .row
    .header-search-column {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    border-top: 1px solid #ddd;
  }
  .ltn__header-3 .header-search-2 {
    min-width: 280px;
  }
  .ltn__top-bar-menu ul li {
    margin: 0 20px 0 0;
  }
}

@media (max-width: 767px) {
  .ltn__header-top-area {
    padding: 5px 0;
    text-align: center;
  }
  .ltn__header-top-area .ltn__social-media ul li {
    font-size: 12px;
    margin: 0 5px 0 0;
  }
  .ltn__top-bar-menu .ltn__currency-menu .active-currency,
  .ltn__top-bar-menu > ul > li {
    font-size: 13px;
  }
  .ltn__drop-menu > ul > li {
    position: inherit;
  }
  .ltn__drop-menu > ul > li > a {
    height: 20px;
    line-height: 20px;
  }
  .ltn__drop-menu ul li ul {
    left: 50%;
    right: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .top-bar-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .welcome p {
    line-height: 20px;
    margin: 5px 0;
  }
  .site-logo {
    min-width: 100px;
  }
  .ltn__header-5 .top-bar-right,
  .ltn__header-4 .top-bar-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;
  }
  .ltn__header-4 .site-logo-wrap,
  .ltn__header-5 .site-logo-wrap {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 10px 0 30px;
  }
  .ltn__header-4 .ltn__header-options,
  .ltn__header-5 .ltn__header-options {
    margin-left: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ltn__header-3 .top-bar-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 5px;
  }
  .ltn__header-logo-and-mobile-menu-in-mobile .site-logo-wrap {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 10px 0 10px;
  }
  .ltn__header-logo-and-mobile-menu-in-mobile
    .ltn__header-middle-area
    > .container-fluid
    > .row
    > .col,
  .ltn__header-logo-and-mobile-menu-in-mobile
    .ltn__header-middle-area
    > .container
    > .row
    > .col {
    max-width: 65%;
  }
  .ltn__header-logo-and-mobile-menu-in-mobile .ltn__header-options {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
  }
  .header-top-btn a {
    padding: 0 15px;
    line-height: 25px;
  }
}

@media (max-width: 575px) {
  .site-logo a {
    font-size: 24px;
  }
  .site-logo a i {
    font-size: 20px;
  }
  .ltn__header-1 .ltn__header-middle-area > .container > .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .ltn__header-1 .site-logo {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ltn__header-1 .ltn__header-options {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 30px;
  }
  .ltn__header-7 .site-logo {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ltn__header-7 .ltn__header-options {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
  }
  .ltn__header-7 .ltn__header-middle-area > .container > .row .col,
  .ltn__header-7 .ltn__header-middle-area > .container-fluid > .row .col {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .ltn__header-3 .mini-cart-icon-2 > a {
    min-width: auto;
  }
  .ltn__header-3 .mini-cart-icon-2 > a h6 {
    display: none;
  }
}

@media (max-width: 449px) {
  .ltn__header-options > div {
    margin-right: 10px;
  }
  .header-feature-area {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .header-feature-item:last-child {
    padding-top: 0;
  }
  .get-support {
    padding-left: 10px;
    margin-left: 10px;
  }
  .get-support .get-support-icon {
    font-size: 20px;
    display: none;
  }
  .get-support .get-support-info h6 {
    display: 13px;
  }
  .get-support .get-support-info h4 {
    font-size: 16px;
  }
}

@media (max-width: 370px) {
  .ltn__header-3 .ltn__header-options > ul > li {
    margin-right: 5px;
  }
}

/* ----------------------------------------
  Icon Font
---------------------------------------- */
.ltn__header-options i,
.ltn__header-top-area i {
  font-weight: bold;
}

/* ----------------------------------------------------
    Category Menu
---------------------------------------------------- */
.ltn__category-menu-wrap {
  position: relative;
}

.ltn__category-menu-title h2 {
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 45px;
  margin: 0;
  overflow: hidden;
  padding: 5px 20px;
  position: relative;
  text-transform: uppercase;
}
.ltn__category-menu-title h2::before,
.ltn__category-menu-title h2::after {
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  display: block;
  font-size: 14px;
}
.ltn__category-menu-title h2::before {
  content: "\f0c9";
  float: left;
  margin-right: 10px;
}
.ltn__category-menu-title h2::after {
  content: "\f0dd";
  float: right;
}

.ltn__category-menu-toggle {
  background-color: var(--white);
}
.ltn__category-menu-toggle > ul {
  border-color: var(--border-color-1);
  border-width: 1px 1px 1px;
  border-style: solid;
  padding: 0;
  margin: 0;
}
.ltn__category-menu-toggle > ul > li > a {
  padding: 10px 15px;
  display: block;
  font-size: 15px;
  border-bottom: 1px solid var(--border-color-1);
}
.ltn__category-menu-toggle > ul > li:hover > a {
  background-color: var(--section-bg-1);
  font-weight: 700;
}
.ltn__category-menu-toggle > ul > li:hover .ltn__category-submenu {
  opacity: 1;
  visibility: visible;
  top: 0;
}
.ltn__category-menu-toggle > ul > li i {
  font-size: 18px;
  margin-right: 5px;
  width: 25px;
  display: inline-block;
}
.ltn__category-menu-toggle ul {
  margin: 0;
}
.ltn__category-menu-toggle ul li {
  margin-top: 0;
  list-style: none;
  position: relative;
  font-size: 14px;
}
.ltn__category-menu-toggle ul li a {
  display: block;
}
.ltn__category-menu-toggle ul li:hover > a {
  color: var(--ltn__secondary-color);
}
.ltn__category-menu-toggle.ltn__one-line-active .ltn__category-submenu li a {
  display: block;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ltn__category-submenu {
  position: absolute;
  left: 100%;
  top: 90%;
  width: 230px;
  z-index: 99;
  background-color: #fff;
  padding: 25px 20px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__category-submenu ul {
  padding: 0;
}
.ltn__category-submenu ul li {
  padding: 8px 0px;
}
.ltn__category-submenu > li {
  width: 100%;
  float: left;
  padding: 0 10px;
}
.ltn__category-submenu.ltn__category-column-5,
.ltn__category-submenu.ltn__category-column-6,
.ltn__category-submenu.ltn__category-column-7,
.ltn__category-submenu.ltn__category-column-8,
.ltn__category-submenu.ltn__category-column-9,
.ltn__category-submenu.ltn__category-column-10,
.ltn__category-submenu.ltn__category-column-11,
.ltn__category-submenu.ltn__category-column-12,
.ltn__category-submenu.ltn__category-column-no-5,
.ltn__category-submenu.ltn__category-column-no-6,
.ltn__category-submenu.ltn__category-column-no-7,
.ltn__category-submenu.ltn__category-column-no-8,
.ltn__category-submenu.ltn__category-column-no-9,
.ltn__category-submenu.ltn__category-column-no-10,
.ltn__category-submenu.ltn__category-column-no-11,
.ltn__category-submenu.ltn__category-column-no-12,
.ltn__category-submenu.ltn__category-column-no-13,
.ltn__category-submenu.ltn__category-column-no-14 {
  width: 730px;
}
.ltn__category-submenu.ltn__category-column-5 > li,
.ltn__category-submenu.ltn__category-column-6 > li,
.ltn__category-submenu.ltn__category-column-7 > li,
.ltn__category-submenu.ltn__category-column-8 > li,
.ltn__category-submenu.ltn__category-column-9 > li,
.ltn__category-submenu.ltn__category-column-10 > li,
.ltn__category-submenu.ltn__category-column-11 > li,
.ltn__category-submenu.ltn__category-column-12 > li,
.ltn__category-submenu.ltn__category-column-no-5 > li,
.ltn__category-submenu.ltn__category-column-no-6 > li,
.ltn__category-submenu.ltn__category-column-no-7 > li,
.ltn__category-submenu.ltn__category-column-no-8 > li,
.ltn__category-submenu.ltn__category-column-no-9 > li,
.ltn__category-submenu.ltn__category-column-no-10 > li,
.ltn__category-submenu.ltn__category-column-no-11 > li,
.ltn__category-submenu.ltn__category-column-no-12 > li,
.ltn__category-submenu.ltn__category-column-no-13 > li,
.ltn__category-submenu.ltn__category-column-no-14 > li {
  width: 25%;
}
.ltn__category-submenu.ltn__category-column-4,
.ltn__category-submenu.ltn__category-column-no-4 {
  width: 730px;
}
.ltn__category-submenu.ltn__category-column-4 > li,
.ltn__category-submenu.ltn__category-column-no-4 > li {
  width: 25%;
}
.ltn__category-submenu.ltn__category-column-3,
.ltn__category-submenu.ltn__category-column-no-3 {
  width: 690px;
}
.ltn__category-submenu.ltn__category-column-3 > li,
.ltn__category-submenu.ltn__category-column-no-3 > li {
  width: 33%;
}
.ltn__category-submenu.ltn__category-column-2,
.ltn__category-submenu.ltn__category-column-no-2 {
  width: 460px;
}
.ltn__category-submenu.ltn__category-column-2 > li,
.ltn__category-submenu.ltn__category-column-no-2 > li {
  width: 50%;
}

.ltn__category-submenu-title > a {
  border-bottom: 1px solid #ddd;
  font-weight: 700;
  padding: 5px 0;
  margin-bottom: 10px;
}

.ltn__category-menu-toggle .ltn__category-menu-more-item-parent {
  cursor: pointer;
}

.ltn__category-menu-toggle .ltn__category-menu-more-item-child,
.ltn__category-menu-toggle .rx-show {
  display: none;
}

.ltn__category-menu-toggle .rx-change .rx-show {
  display: block;
}

.ltn__category-menu-toggle .rx-change .rx-default {
  display: none;
}

@media (max-width: 1200px) {
  .ltn__category-menu-toggle > ul > li > a {
    padding: 10px 5px;
    font-size: 13px;
  }
  .ltn__category-menu-toggle > ul > li i {
    font-size: 16px;
    width: 20px;
  }
  .ltn__category-menu-toggle ul li {
    font-size: 13px;
  }
  .ltn__category-submenu ul {
    padding-left: 10px;
  }
  .ltn__category-submenu ul li {
    padding: 5px 0px;
  }
}

@media (max-width: 991px) {
  .ltn__category-menu-wrap {
    margin-bottom: 30px;
  }
  .ltn__category-menu-toggle {
    max-height: 350px;
    overflow: auto;
    position: absolute;
    width: 100%;
    z-index: 99;
    display: none;
  }
  .ltn__category-submenu {
    display: none;
    opacity: inherit !important;
    visibility: inherit !important;
    left: inherit !important;
    width: 100% !important;
    top: 100%;
    max-height: 350px;
    overflow: auto;
    -webkit-transition: unset;
    -o-transition: unset;
    transition: unset;
    -webkit-box-shadow: inherit;
    box-shadow: inherit;
    position: inherit;
    padding: 5px 0px 0px 15px;
  }
  .ltn__category-submenu > li {
    width: 100% !important;
    margin-bottom: 0;
    float: none;
  }
  .ltn__category-submenu-children {
    display: none;
  }
  .ltn__category-menu-drop > a {
    position: relative;
  }
  .ltn__category-menu-drop > a::before {
    position: absolute;
    content: "+";
    right: 15px;
    top: 50%;
    -webkit-transform: translatey(-50%);
    -ms-transform: translatey(-50%);
    transform: translatey(-50%);
    font-size: 20px;
    font-weight: 400;
  }
  .ltn__category-menu-drop.open > a::before {
    display: none;
  }
  .ltn__category-menu-drop.open > a::after {
    position: absolute;
    content: "-";
    right: 15px;
    top: 50%;
    -webkit-transform: translatey(-50%);
    -ms-transform: translatey(-50%);
    transform: translatey(-50%);
    font-size: 30px;
    font-weight: 400;
  }
}

.mobile-header-menu-fullwidth .mobile-menu-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid var(--border-color-1);
  padding: 7px 20px 6px;
  margin-right: 0;
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* ----------------------------------------------------
    Slider Area 1, 2, 3, 4, 5, 6, 7, 9
---------------------------------------------------- */
.ltn__slide-item {
  padding-top: 100px;
  padding-bottom: 100px;
  height: 750px;
}

.ltn__slide-item-inner {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slide-item-info-inner {
  width: 100%;
}

.slide-title {
  font-size: 46px;
  font-weight: 700;
}

/* Slider Animation Start */
.ltn__slide-animation {
  position: relative;
  z-index: 9;
}
.ltn__slide-animation > * {
  opacity: 0;
  visibility: hidden;
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.ltn__slide-animation > *:nth-child(1) {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.ltn__slide-animation > *:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}
.ltn__slide-animation > *:nth-child(3) {
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
}
.ltn__slide-animation > *:nth-child(4) {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.ltn__slide-animation > *:nth-child(5) {
  -webkit-animation-delay: 2.5s;
  animation-delay: 2.5s;
  -webkit-animation-duration: 2.5s;
  animation-duration: 2.5s;
}
.ltn__slide-animation > *:nth-child(6) {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}
.ltn__slide-animation > *:nth-child(7) {
  -webkit-animation-delay: 3.5s;
  animation-delay: 3.5s;
  -webkit-animation-duration: 3.5s;
  animation-duration: 3.5s;
}
.ltn__slide-animation > *:nth-child(8) {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-duration: 4s;
  animation-duration: 4s;
}
.ltn__slide-animation > *:nth-child(9) {
  -webkit-animation-delay: 4.5s;
  animation-delay: 4.5s;
  -webkit-animation-duration: 4.5s;
  animation-duration: 4.5s;
}
.ltn__slide-animation > *:nth-child(11) {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}

.slick-current .ltn__slide-animation > *,
.ltn__slide-animation-active .ltn__slide-animation > * {
  opacity: 1;
  visibility: visible;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* Title Background Image */
.title-bg-img {
  background-image: url("../img/slider/1.jpg");
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  word-break: break-word;
}

.title-bg-img-2 {
  background-image: url("../img/slider/2.jpg");
}

/* Slide Arrow */
.slick-slide-arrow-1 .slick-arrow {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 40px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  line-height: 58px;
  border: 1px solid var(--border-color-1);
  text-align: center;
  font-size: 20px;
  color: var(--ltn__primary-color) !important;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}
.slick-slide-arrow-1 .slick-arrow:hover {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white) !important;
}

.slick-slide-arrow-1 .slick-next {
  right: 40px;
  left: auto;
}

.slick-slide-arrow-1:hover .slick-arrow {
  opacity: 1;
  visibility: visible;
  left: 20px;
}

.slick-slide-arrow-1:hover .slick-next {
  right: 20px;
  left: auto;
}

.slick-slide-dots-1 .slick-dots {
  position: absolute;
  bottom: 50px;
  width: 100%;
  margin: 0;
}

.arrow-white .slick-arrow {
  color: var(--white) !important;
  border: 1px solid var(--border-color-3);
}

@media (min-width: 1400px) {
  .slick-slide-arrow-1:hover .slick-arrow {
    opacity: 1;
    visibility: visible;
    left: 60px;
  }
  .slick-slide-arrow-1:hover .slick-next {
    right: 60px;
    left: auto;
  }
}

/* slide-item-2 */
.ltn__slide-item-2 {
  height: calc(100vh - 0px);
  padding-top: 200px;
}
.ltn__slide-item-2 .slide-item-info {
  max-width: 650px;
}
.ltn__slide-item-2 .slide-sub-title {
  margin-bottom: 20px;
  font-family: var(--ltn__body-font);
}
.ltn__slide-item-2 .slide-sub-title span {
  color: var(--ltn__secondary-color);
}
.ltn__slide-item-2 .slide-title {
  font-size: 65px;
  line-height: 1;
  margin-bottom: 20px;
}
.ltn__slide-item-2 .slide-title span {
  color: var(--ltn__secondary-color);
}
.ltn__slide-item-2 .btn-wrapper {
  margin-top: 40px;
}
.ltn__slide-item-2 .slide-brief {
  padding-left: 30px;
  border-left: 1px solid #576466;
}

.ltn__slide-item-6 .text-right .slide-item-info,
.ltn__slide-item-2 .text-right .slide-item-info {
  margin-left: auto;
}

.ltn__slide-item-6 .text-right .slide-brief,
.ltn__slide-item-2 .text-right .slide-brief {
  padding-left: 0px;
  border-left: 0;
  padding-right: 30px;
  border-right: 1px solid #576466;
  margin-left: auto;
}

.ltn__slide-item-6 .text-center .slide-item-info,
.ltn__slide-item-2 .text-center .slide-item-info {
  margin-left: auto;
  margin-right: auto;
}

.ltn__slide-item-6 .text-center .slide-brief,
.ltn__slide-item-2 .text-center .slide-brief {
  padding-left: 0px;
  border-left: 0;
  padding-right: 0px;
  border-right: 0;
}

.slide-brief {
  max-width: 450px;
}

.text-right .slide-brief {
  margin-left: auto;
}

.text-center .slide-brief {
  margin-left: auto;
  margin-right: auto;
}

.ltn__product-pointer {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
}
.ltn__product-pointer > ul {
  padding: 0;
  margin: 0;
}
.ltn__product-pointer > ul > li {
  display: inline-block;
  list-style: none;
  position: relative;
}
.ltn__product-pointer > ul > li > ul {
  position: absolute;
  top: 80%;
  min-width: 300px;
  padding: 0;
  background-color: white;
  right: 80px;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  text-align: left;
  opacity: 0;
  visibility: hidden;
}
.ltn__product-pointer > ul > li > ul > li {
  list-style: none;
}
.ltn__product-pointer > ul > li:hover ul {
  top: 50%;
  opacity: 1;
  visibility: visible;
}
.ltn__product-pointer > ul > li:hover .ltn__pointer-icon {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.ltn__product-pointer ul li,
.ltn__product-pointer ol li {
  margin-top: 5px;
}
.ltn__product-pointer img {
  height: inherit !important;
}
.ltn__product-pointer p:last-child {
  margin-bottom: 0;
}
.ltn__product-pointer .ltn__pointer-icon {
  cursor: pointer;
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 20px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__product-pointer .ltn__product-pointer-inner {
  padding: 15px 30px 25px;
  position: relative;
}
.ltn__product-pointer .ltn__product-pointer-inner::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 15px solid var(--white);
  border-bottom: 15px solid transparent;
  right: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ltn__product-pointer .ltn__product-pointer-inner h5 {
  margin-bottom: 10px;
}
.ltn__product-pointer .ltn__product-pointer-inner p {
  font-size: 14px;
}
.ltn__product-pointer.ltn__product-pointer-1 {
  top: 38%;
  left: auto;
  right: 24%;
}
.ltn__product-pointer.ltn__product-pointer-2 {
  top: 60%;
  left: 20%;
}
.ltn__product-pointer.ltn__product-pointer-3 {
  top: 10%;
  left: 40%;
}

.slide-img-left .ltn__product-pointer > ul > li > ul {
  left: 80px;
  right: auto;
}

.slide-img-left .ltn__product-pointer .ltn__product-pointer-inner::before {
  border-right: 15px solid var(--white);
  border-left: 0;
  right: auto;
  left: -15px;
}

/* slide-item-3 */
.ltn__slide-item-3 .row [class*="col-"] {
  position: inherit;
}

.ltn__slide-item-3 .slide-item-info {
  position: relative;
  z-index: 2;
}

.ltn__slide-item-3 .slide-item-img {
  width: 45%;
  position: absolute;
  right: 150px;
  top: auto;
  bottom: 100px;
  height: 70%;
}
.ltn__slide-item-3 .slide-item-img img {
  text-align: right;
  margin-left: auto;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center center;
  object-position: center center;
}

.ltn__slide-item-3 .text-right .slide-item-img {
  right: auto;
  left: 150px;
}

.ltn__slide-item-3-normal {
  height: calc(100vh - 160px);
  padding-top: 100px;
}

/* slide-item-4 */
.ltn__slide-item-4 {
  padding-bottom: 0;
}
.ltn__slide-item-4 .slide-item-img {
  margin-top: 100px;
}

/* slider-5 */
.ltn__slider-5 .ltn__slide-item-2 {
  padding-top: 100px;
}

/* slide-item-5 */
.ltn__slide-item-5 .slide-item-img {
  right: 200px;
  bottom: 0;
  height: 80%;
}

.ltn__slide-item-5 .call-to-circle-1 {
  right: 14%;
  left: auto;
  top: 30%;
  z-index: -1;
  -webkit-animation: wave 8s 0.1s infinite linear;
  animation: wave 8s 0.1s infinite linear;
}

.ltn__slide-item-5 .text-right .slide-item-img {
  right: auto;
  left: 150px;
}

.ltn__slide-item-5 .text-right .call-to-circle-1 {
  left: 20%;
  right: auto;
}

/* slide-item-6 */
.ltn__slide-item-6 .slide-item-info {
  max-width: 100%;
}

.ltn__slide-item-6 .slide-title {
  font-size: 80px;
  line-height: 1;
}

/* slide-item-7 */
.ltn__slide-item-7 {
  min-height: 800px;
  height: calc(100vh - 0px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ltn__slide-item-7 .slide-item-info {
  max-width: 850px;
}
.ltn__slide-item-7 .slide-title {
  font-size: 90px;
  line-height: 1;
}

/* slider-9 */
.ltn__slide-item-9 {
  height: 800px;
}
.ltn__slide-item-9 .slide-item-info {
  height: 800px;
  max-width: 500px;
}

.ltn__slide-item-9 .ltn__slide-item-inner {
  display: block;
}

.ltn__slide-item-9 .slide-item-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 30px 10px;
}

.ltn__slide-item-9 .right-align .slide-item-info {
  margin-left: auto;
}

.ltn__slide-item-9 .slide-item-info-inner {
  position: relative;
}
.ltn__slide-item-9 .slide-item-info-inner::before,
.ltn__slide-item-9 .slide-item-info-inner::after {
  position: absolute;
  content: "";
  width: 2px;
  height: 84px;
  background-color: var(--ltn__heading-color);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.ltn__slide-item-9 .slide-item-info-inner::before {
  top: -46.5%;
}
.ltn__slide-item-9 .slide-item-info-inner::after {
  bottom: -51%;
}

.ltn__slide-item-9 .text-color-white .slide-item-info-inner::before,
.ltn__slide-item-9 .text-color-white .slide-item-info-inner::after {
  background-color: var(--white);
}

.ltn__slide-item-9 .ltn__tab-menu a {
  padding: 12px 20px;
}

.ltn__slide-item-9 .slide-item-info-line-no::before,
.ltn__slide-item-9 .slide-item-info-line-no::after {
  display: none;
}

/* slide-item-10 */
.ltn__slide-item-10 {
  height: 535px;
}
.ltn__slide-item-10 .slide-item-info-inner {
  padding: 30px;
}

/* ----------------------------------------------------
    Slider Area 1, 2, 3, 4, 5, 6, 7, 9
---------------------------------------------------- */
/* ======================================================
    s 3 Start
 ====================================================== */
/* slider-4 */
.ltn__slider-11 .ltn__slider-11-inner {
  position: relative;
}

.ltn__slider-11 .ltn__slide-item-11 {
  position: relative;
}
.ltn__slider-11 .ltn__slide-item-11 .slide-content {
  padding: 80px 0 100px 200px;
  padding-bottom: 250px;
  position: relative;
  width: 90%;
  z-index: 1;
  min-height: 100vh;
  height: 800px;
}
.ltn__slider-11 .ltn__slide-item-11 .slide-content .slide-title-1 {
  color: var(--main-color-one);
}
.ltn__slider-11 .ltn__slide-item-11 .slide-content .slide-title-2 {
  color: var(--secondary-color-three);
}
.ltn__slider-11 .ltn__slide-item-11 .slide-content p {
  color: #000;
}
.ltn__slider-11 .ltn__slide-item-11:before {
  opacity: 0;
}

.ltn__slider-11 .slide-item-img {
  position: absolute;
  right: 0px;
  top: 0;
  height: 100%;
  z-index: 0;
  width: 45%;
}
.ltn__slider-11 .slide-item-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center center;
  object-position: center center;
  margin-left: auto;
}

/* slider-4-active */
.ltn__slider-11-active .slick-arrow {
  position: absolute;
  bottom: 60px;
  left: 30%;
  z-index: 9;
}
.ltn__slider-11-active .slick-arrow i {
  font-size: 30px;
  color: #ccc;
}
.ltn__slider-11-active .slick-arrow.slick-next {
  margin-left: 50px;
}

/* slider-4-pagination */
/* ===== Set Display for show / hide ===== */
.ltn__slider-11-pagination-count {
  display: none;
  position: absolute;
  top: 53%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: auto;
  right: 50px;
}
.ltn__slider-11-pagination-count span {
  color: var(--ltn__color-5);
}
.ltn__slider-11-pagination-count .count {
  position: absolute;
  top: -22px;
  left: -8px;
}
.ltn__slider-11-pagination-count .total {
  bottom: -25px;
  position: absolute;
  left: -10px;
}
.ltn__slider-11-pagination-count .slick-dots {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 520px;
}
.ltn__slider-11-pagination-count .slick-dots li {
  margin: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  border-radius: 0;
  width: 2px;
  opacity: 0.45;
}
.ltn__slider-11-pagination-count .slick-dots li button {
  display: block;
  border-radius: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: var(--section-bg-1);
  opacity: 0.45;
  cursor: pointer;
}
.ltn__slider-11-pagination-count .slick-dots li button:hover {
  background-color: var(--section-bg-1);
  opacity: 1;
}
.ltn__slider-11-pagination-count .slick-dots li.slick-active button {
  background-color: var(--ltn__secondary-color);
  opacity: 1;
}

.ltn__slider-11-pagination-count-show .ltn__slider-11-pagination-count {
  display: block;
}

@media only screen and (min-width: 992px) {
  .ltn__slide-item-11 {
    min-height: calc(100vh - 140px);
  }
}

/* slider-4-img-slide-arrow */
.ltn__slider-11-img-slide-arrow {
  position: absolute;
  bottom: 30px;
  right: 10%;
  width: 500px;
}
.ltn__slider-11-img-slide-arrow .ltn__slider-11-img-slide-arrow-inner {
  position: relative;
}
.ltn__slider-11-img-slide-arrow .ltn__slider-11-img-slide-arrow-active {
  padding-left: 100px;
  background-color: var(--main-color-one);
}
.ltn__slider-11-img-slide-arrow
  .ltn__slider-11-img-slide-arrow-active
  .image-slide-item {
  padding: 0 10px;
}
.ltn__slider-11-img-slide-arrow
  .ltn__slider-11-img-slide-arrow-active
  .image-slide-item
  img {
  border: 5px solid;
  border-color: var(--section-bg-1);
  cursor: pointer;
}
.ltn__slider-11-img-slide-arrow
  .ltn__slider-11-img-slide-arrow-active
  .image-slide-item.slick-current
  img {
  opacity: 0.9;
}
.ltn__slider-11-img-slide-arrow
  .ltn__slider-11-img-slide-arrow-active
  .slick-arrow {
  position: absolute;
  right: auto;
  z-index: 9;
  top: 0;
  left: 0;
  padding-left: 0;
  background-color: var(--section-bg-1);
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 43px;
  font-size: 16px;
}
.ltn__slider-11-img-slide-arrow
  .ltn__slider-11-img-slide-arrow-active
  .slick-arrow.slick-next {
  left: 50px;
}

/* slider-11-slide-item-count */
/* ===== Set Display for show / hide ===== */
.ltn__slider-11-slide-item-count {
  display: none;
  left: 0;
  right: auto;
  top: auto;
  bottom: 0;
  position: absolute;
  background-color: var(--section-bg-1);
  padding: 5px 15px 0px;
}
.ltn__slider-11-slide-item-count .count {
  font-size: 30px;
  line-height: 1;
}

.ltn__slider-11-slide-item-count-show .ltn__slider-11-slide-item-count {
  display: block;
}

/* slider-sticky-icon-2 */
.slider-sticky-icon-2 {
  position: absolute;
  left: 50px;
  bottom: 80px;
  z-index: 1;
}
.slider-sticky-icon-2 ul {
  padding: 0;
}
.slider-sticky-icon-2 ul li {
  list-style: none;
  display: block;
  margin-bottom: 12px;
}

/* ======================================================
    s 3 END
 ====================================================== */
@media (min-width: 1600px) {
  .ltn__slide-item-2 {
    min-height: 800px;
  }
  .ltn__slide-item-3-normal {
    min-height: 780px;
  }
  .ltn__slide-item-6 {
    height: 800px;
  }
}

@media (max-width: 1599px) {
  .liton-slide-item,
  .ltn__slide-item-3,
  .liton-slide-item-inner {
    height: inherit;
  }
  .ltn__slide-item-9 {
    height: 650px;
  }
  .ltn__slide-item-9 .slide-item-info {
    height: 650px;
    max-width: 400px;
  }
  .ltn__slider-11-img-slide-arrow {
    width: 80%;
    max-width: 350px;
  }
  .ltn__slider-11-img-slide-arrow
    .ltn__slider-11-img-slide-arrow-active
    .image-slide-item {
    padding: 0 3px;
  }
  .ltn__slider-11-img-slide-arrow
    .ltn__slider-11-img-slide-arrow-active
    .image-slide-item
    img {
    border: 2px solid;
    border-color: var(--section-bg-1);
  }
  .ltn__slider-11-img-slide-arrow
    .ltn__slider-11-img-slide-arrow-active
    .slick-arrow {
    width: 30px;
    height: 30px;
    line-height: 33px;
    font-size: 14px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .ltn__slider-11-img-slide-arrow
    .ltn__slider-11-img-slide-arrow-active
    .slick-arrow.slick-next {
    left: 40px;
  }
  .ltn__slider-11-slide-item-count-show .ltn__slider-11-slide-item-count {
    display: none;
  }
  .ltn__slider-11-pagination-count-show .ltn__slider-11-pagination-count {
    display: none;
  }
  .slider-sticky-icon-2 {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .ltn__slide-item-2 .slide-title {
    font-size: 50px;
  }
  .ltn__slide-item-6 {
    height: 620px;
  }
  .ltn__slide-item-6 .slide-title {
    font-size: 80px;
  }
}

@media (min-width: 1400px) and (max-width: 1599px) {
  .ltn__slide-item-3 .slide-item-img {
    width: 45%;
    right: 60px;
    top: 60%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .ltn__slide-item-3 .slide-item-img.slide-img-left {
    right: auto;
    left: 60px;
  }
  .ltn__slide-item-4 .slide-item-img {
    margin-top: 50px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .ltn__slider-11 .slide-item-img {
    top: 0;
    right: 0;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .ltn__slide-item-3 .slide-item-img {
    width: 45%;
    right: 60px;
    top: 65%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .ltn__slide-item-3 .slide-item-img.slide-img-left {
    right: auto;
    left: 60px;
  }
  .ltn__slide-item-4 .slide-item-img {
    margin-top: 50px;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .ltn__slider-11 .slide-item-img {
    top: 0;
    right: 0;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ltn__slide-item-3 .slide-item-img {
    width: 45%;
    right: 60px;
  }
  .ltn__slide-item-3 .slide-item-img.slide-img-left {
    right: auto;
    left: 60px;
  }
  .ltn__slide-item-6 .slide-title {
    font-size: 60px;
  }
  .ltn__slide-item.ltn__slide-item-10 {
    height: 495px;
  }
  .ltn__slider-11 .slide-item-img {
    top: 0;
    right: 0;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
  }
}

@media (max-width: 1199px) {
  .slide-title {
    font-size: 36px;
  }
  .ltn__slide-item {
    height: auto;
  }
  .liton-slide-item-inner {
    height: inherit;
  }
  .slide-item-info-inner {
    margin-bottom: 30px;
  }
  .ltn__slide-item-2 .slide-title {
    font-size: 50px;
  }
  .ltn__slide-item-2 .slide-brief {
    padding-left: 15px;
  }
  .ltn__slide-item-6 {
    padding-top: 140px;
  }
  .ltn__slide-item-7 {
    min-height: 550px;
    height: auto;
  }
  .ltn__slide-item-7 .slide-title {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .slide-title {
    font-size: 26px;
  }
  .liton-slide-item-inner {
    height: inherit;
  }
  .slide-item-info-inner {
    margin-bottom: 30px;
  }
  .slick-slide-arrow-1 .slick-arrow {
    width: 40px;
    height: 40px;
    line-height: 38px;
    font-size: 16px;
  }
  .ltn__slide-item-2 .slide-title {
    font-size: 40px;
  }
  .ltn__slide-item-2 .slide-brief {
    padding-left: 15px;
  }
  .ltn__slide-item-2 .btn-wrapper {
    margin-top: 20px;
  }
  .ltn__slide-item-3 .slide-item-img {
    width: 100%;
    position: relative;
    height: auto;
    right: auto;
    bottom: auto;
  }
  .ltn__slide-item-3 .slide-item-img img {
    height: auto;
  }
  .ltn__slide-item-3 .text-right .slide-item-img {
    right: auto;
    left: auto;
  }
  .ltn__product-pointer > ul > li > ul {
    min-width: 280px;
  }
  .ltn__product-pointer > ul > li > ul {
    top: 110%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .ltn__product-pointer > ul > li:hover ul {
    top: 130%;
  }
  .ltn__product-pointer .ltn__product-pointer-inner::before {
    left: 50%;
    top: -25px;
    -webkit-transform: translateX(-50%) rotate(-90deg);
    -ms-transform: translateX(-50%) rotate(-90deg);
    transform: translateX(-50%) rotate(-90deg);
  }
  .slide-img-left .ltn__product-pointer .ltn__product-pointer-inner::before {
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(90deg);
    -ms-transform: translateX(-50%) rotate(90deg);
    transform: translateX(-50%) rotate(90deg);
  }
  .ltn__slide-item-5 .slide-item-img {
    display: none;
  }
  .ltn__slide-item-6 .slide-title {
    font-size: 40px;
  }
  .ltn__slide-item-7 {
    min-height: 400px;
  }
  .ltn__slide-item-7 .slide-title {
    font-size: 40px;
  }
  .ltn__slide-item-9 {
    height: 560px;
  }
  .ltn__slide-item-9 .slide-item-info {
    height: auto;
    margin: 30px;
  }
  .ltn__slide-item-9 .ltn__slide-item-inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .ltn__slide-item-9 .slide-item-info-inner::before,
  .ltn__slide-item-9 .slide-item-info-inner::after {
    display: none;
  }
  .ltn__slider-11-img-slide-arrow {
    right: 50%;
    -webkit-transform: translate(50%);
    -ms-transform: translate(50%);
    transform: translate(50%);
  }
}

@media (max-width: 767px) {
  .liton-slide-item-inner {
    height: inherit;
  }
  .slide-item-info-inner {
    margin-bottom: 30px;
  }
  .slide-title br {
    display: none;
  }
  .slide-title {
    font-size: 24px;
  }
  .ltn__slide-item-2 {
    height: auto;
    padding-top: 300px;
  }
  .ltn__slide-item-2 .slide-title {
    font-size: 30px;
  }
  .ltn__slide-item-2 .slide-brief {
    padding-left: 15px;
  }
  .ltn__slide-item-3-normal {
    padding-top: 100px;
  }
  .ltn__product-pointer {
    display: none;
  }
  .ltn__slide-item-6 .slide-title {
    font-size: 30px;
  }
}

/* Slide Image Show Only Mobile Device */
/*
.ltn__slide-item-9-img {
    display: none;
}
@media (max-width: 767px) { 
    .ltn__slide-item-9 .ltn__slide-item-inner {
        display: none; 
    }
    .ltn__slide-item-9 {
        height: auto;
    }
    .ltn__slide-item-9.bg-image {
        background-size: contain;
        background: none !important;
    }
    .ltn__slide-item-9-img {
        display: block;
    }
    .ltn__slider-area .slick-slide-dots-1 .slick-dots {
        bottom: 20px;
    }

}
*/
/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* ----------------------------------------------------
    Product Area
---------------------------------------------------- */
.ltn__product-item {
  position: relative;
  margin-bottom: 50px;
}

.product-img {
  position: relative;
  overflow: hidden;
}

.product-img img {
  position: relative;
  -webkit-transition: all 3.5s ease 0s;
  -o-transition: all 3.5s ease 0s;
  transition: all 3.5s ease 0s;
}

.product-img:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--ltn__primary-color);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ltn__product-item:hover .product-img:before {
  opacity: 0.4;
  visibility: visible;
}

.ltn__product-item:hover .product-img::before {
  opacity: 0.4;
  visibility: visible;
}

.product-title {
  font-size: 18px;
  margin-bottom: 5px;
}

.product-ratting {
  margin-bottom: 5px;
}

.product-ratting ul {
  margin: 0;
  padding: 0;
}

.product-ratting li {
  display: inline-block;
  margin: 0 -2px;
  color: var(--ratings);
  font-size: 12px;
}

.product-hover-action {
  position: absolute;
  top: 60%;
  left: 0;
  right: 0;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ltn__product-item:hover .product-hover-action {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.product-hover-action ul {
  margin: 0;
  padding: 0;
  display: inline-block;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
}

.product-hover-action li {
  height: 50px;
  width: 50px;
  line-height: 50px;
  font-size: 14px;
  text-align: center;
  float: left;
  margin-right: 0px;
  margin-top: 0;
  list-style: none;
  border-right: 1px solid var(--border-color-7);
}

.product-hover-action li:last-child {
  margin-right: 0;
  border-right: 0;
}

.product-hover-action li a {
  display: block;
  background-color: var(--white);
  font-weight: 700;
}

.product-hover-action li:hover a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.product-badge {
  position: absolute;
  top: 15px;
  right: 18px;
}

.product-badge ul {
  margin: 0;
  padding: 0;
}

.product-badge li {
  list-style: none;
  display: inline-block;
  font-size: 13px;
  font-weight: 600;
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  padding: 3px 15px 0px;
  text-transform: uppercase;
  line-height: 25px;
}
.product-badge li:first-child {
  margin-top: 0;
}
.product-badge li::before {
  position: absolute;
  content: "";
  bottom: -8px;
  left: 0;
  border-left: 8px solid var(--ltn__secondary-color);
  width: 0;
  height: 0;
  border-bottom: 8px solid transparent;
  display: none;
}
.product-badge li .soldout-badge {
  background-color: var(--red-2);
}

.product-info {
  padding: 20px 0 0;
}

.product-price {
  color: var(--ltn__heading-color);
  font-weight: 600;
}

.product-price del {
  opacity: 0.6;
  margin-left: 5px;
  font-size: 80%;
}

.ltn__product-item .product-price {
  margin-bottom: 12px;
}

.product-action ul {
  margin: 10px 0 0;
  padding: 0;
}

.product-action li {
  display: inline-block;
  margin-top: 0;
}

.product-action li a {
  display: inline-block;
  padding: 3px 15px;
  border: 1px solid var(--ltn__heading-color);
  color: var(--ltn__heading-color);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.product-action li:hover a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  border-color: var(--ltn__secondary-color);
}

.button-1 a {
  display: inline-block;
  padding: 5px 15px;
  background-color: var(--ltn__heading-color);
  color: var(--white);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.button-1:hover a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

/* product-item-2 */
.ltn__product-item-2 .product-img img {
  background-color: var(--section-bg-1);
}

.ltn__product-item-2:hover .product-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.ltn__product-item .add-to-cart {
  width: auto;
  text-transform: uppercase;
}
.ltn__product-item .add-to-cart a {
  padding: 0 15px;
}
.ltn__product-item .add-to-cart span {
  font-size: 12px;
  line-height: 1;
}

/* product-item-3 */
.ltn__product-item-3 {
  border: 2px solid;
  border-color: var(--border-color-8);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__product-item-3 .product-img::before {
  display: none;
}
.ltn__product-item-3 .product-info {
  padding: 25px 30px 15px;
}
.ltn__product-item-3 .product-title {
  font-size: 16px;
  margin-bottom: 0;
}
.ltn__product-item-3 .product-price {
  font-size: 20px;
  color: var(--ltn__secondary-color);
  font-weight: 700;
}
.ltn__product-item-3 .product-price del {
  font-size: 20px;
  opacity: 0.6;
}
.ltn__product-item-3 .product-info-brief {
  border-top: 2px solid;
  border-color: var(--border-color-8);
  margin-bottom: 15px;
  max-width: 400px;
}
.ltn__product-item-3 .product-info-brief ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ltn__product-item-3 .product-info-brief ul li {
  list-style: none;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
}
.ltn__product-item-3 .product-info-brief ul li i {
  color: var(--ltn__secondary-color);
  margin-right: 5px;
}
.ltn__product-item-3:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__product-item-3:hover .product-hover-action {
  top: 70%;
}

/* product-item-4 */
.ltn__product-item-4 {
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border: 1px solid;
  border-color: var(--border-color-13);
  background-color: var(--white);
}
.ltn__product-item-4 .product-img {
  position: relative;
}
.ltn__product-item-4 .product-img a {
  display: block;
}
.ltn__product-item-4 .product-img img {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.ltn__product-item-4 .product-img-location-gallery {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 30px 15px;
  margin: 0;
  background: var(--gradient-color-5);
}
.ltn__product-item-4 .product-img-location-gallery ul {
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 2;
}
.ltn__product-item-4 .product-img-location-gallery ul li {
  list-style: none;
  font-size: 14px;
  color: var(--white);
}
.ltn__product-item-4 .product-img-location-gallery .product-img-gallery {
  margin-left: 10px;
}
.ltn__product-item-4 .product-img-location-gallery .product-img-gallery ul li {
  display: inline-block;
  width: 42px;
  height: 30px;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-right: 5px;
  text-align: center;
}
.ltn__product-item-4
  .product-img-location-gallery
  .product-img-gallery
  ul
  li
  a {
  display: block;
}
.ltn__product-item-4
  .product-img-location-gallery
  .product-img-gallery
  ul
  li:last-child {
  margin-right: 0;
}
.ltn__product-item-4 .product-info {
  padding: 30px 30px 1px;
}
.ltn__product-item-4 .product-price {
  font-size: 18px;
  font-family: var(--ltn__heading-font);
  font-weight: 600;
  color: var(--ltn__secondary-color);
  margin-bottom: 5px;
}
.ltn__product-item-4 .product-price label {
  font-weight: 400;
  font-size: 14px;
}
.ltn__product-item-4 .product-title {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 600;
}
.ltn__product-item-4 .product-description p {
  font-size: 14px;
  margin-bottom: 15px;
}
.ltn__product-item-4 .product-info-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid;
  border-color: var(--border-color-1);
  padding: 20px 30px 30px;
}
.ltn__product-item-4 .product-hover-action {
  position: relative;
  opacity: 1;
  visibility: visible;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  line-height: 1;
}
.ltn__product-item-4 .product-hover-action ul {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__product-item-4 .product-hover-action ul li {
  margin-right: 8px;
  height: 36px;
  width: 36px;
  line-height: 42px;
  border-right: 0;
  font-size: 16px;
  background-color: var(--section-bg-1);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__product-item-4 .product-hover-action ul li a {
  background-color: transparent;
}
.ltn__product-item-4 .product-hover-action ul li:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}
.ltn__product-item-4:hover .product-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.ltn__product-item-4:hover .product-img::before {
  display: none;
}

.real-estate-agent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.real-estate-agent .agent-img {
  max-width: 40px;
  margin-right: 12px;
}
.real-estate-agent .agent-img img {
  border-radius: 100%;
}
.real-estate-agent .agent-brief h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 600;
}
.real-estate-agent .agent-brief small {
  font-size: 12px;
}

/* product-item-5 */
.ltn__product-item-5 {
  margin-top: 0;
}
.ltn__product-item-5 .product-img {
  margin-top: 0;
}
.ltn__product-item-5 .product-img a {
  display: block;
}
.ltn__product-item-5 .product-info {
  margin-top: 0;
}
.ltn__product-item-5 .product-info-bottom {
  padding: 20px 30px 15px;
}
.ltn__product-item-5 .product-badge {
  position: inherit;
  margin-bottom: 15px;
}
.ltn__product-item-5 .product-badge li {
  font-size: 16px;
  background-color: transparent;
  color: var(--ltn__secondary-color);
  padding: 0;
}
.ltn__product-item-5 .product-img-location ul {
  padding: 0;
  margin-bottom: 0;
}
.ltn__product-item-5 .product-img-location ul li {
  list-style: none;
  font-size: 14px;
}
.ltn__product-item-5 .product-img-location ul li i {
  color: var(--ltn__secondary-color);
  padding-left: 0;
}
.ltn__product-item-5 .ltn__plot-brief {
  padding: 0;
  margin-bottom: 20px;
}
.ltn__product-item-5 .ltn__plot-brief li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
}
.ltn__product-item-5 .ltn__plot-brief li:last-child {
  margin-right: 0;
}
.ltn__product-item-5 .ltn__plot-brief li span {
  font-weight: 700;
}
.ltn__product-item-5 .real-estate-agent {
  position: absolute;
  top: 20px;
  right: 20px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__product-item-5 .real-estate-agent .agent-img {
  max-width: 50px;
}
.ltn__product-item-5 .real-estate-agent .agent-img img {
  border: 3px solid;
  border-color: var(--border-color-1);
}
.ltn__product-item-5 .product-hover-action {
  text-align: left;
  margin-bottom: 25px;
}

.ltn__product-list-view .ltn__product-item-5 {
  padding: 30px 30px 30px;
}
.ltn__product-list-view .ltn__product-item-5 .real-estate-agent {
  position: inherit;
  margin-top: 15px;
}
.ltn__product-list-view .ltn__product-item-5 .product-info-bottom {
  padding: 0px 0px 0px 30px;
  border: 0;
}
.ltn__product-list-view .ltn__product-item-5 .product-hover-action {
  margin-bottom: 0;
}
.ltn__product-list-view .ltn__product-item-5 .ltn__plot-brief {
  margin-bottom: 5px;
}
.ltn__product-list-view .ltn__product-item-5 .product-badge-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

/* ----------------------------------------------------
    Modal Area
---------------------------------------------------- */
.modal-dialog {
  margin-top: 150px;
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  padding: 0;
  border: 0;
}
.modal-header .close {
  position: absolute;
  height: 40px;
  width: 40px;
  line-height: 40px;
  padding: 0;
  right: 16px;
  left: auto;
  top: 16px;
  opacity: 1;
  z-index: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.modal-header .close span {
  display: block;
  font-size: 25px;
}
.modal-header .close:not(:disabled):not(.disabled):focus,
.modal-header .close:not(:disabled):not(.disabled):hover,
.modal-header .close:hover {
  opacity: 1;
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 980px;
  }
}

.modal-product-info {
  padding-left: 25px;
}

.modal-product-info h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.modal-product-info hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modal-product-info .product-price {
  font-size: 50px;
  margin-bottom: 10px;
  color: var(--ltn__secondary-color);
  line-height: 1.2;
  font-weight: 600;
}

.modal-product-meta > ul {
  margin: 15px 0;
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color-12);
  border-top: 1px solid var(--border-color-12);
}

.modal-product-meta > ul > li {
  list-style: none;
  font-size: 14px;
  margin-top: 10px;
}
.modal-product-meta > ul > li:first-child {
  margin-top: 0;
}

.modal-product-meta li strong {
  color: var(--ltn__heading-color);
  margin-right: 5px;
  font-weight: 600;
  min-width: 100px;
  display: inline-block;
}

.modal-product-quantity input {
  border: 1px solid #e5e5e5;
  float: left;
  height: 45px;
  text-align: center;
  width: 80px;
  margin-right: 20px;
}

.modal-btn {
  margin-top: 15px;
  border-top: 1px solid #f1f1f1;
  text-align: right;
}

.modal-btn a {
  font-size: 14px;
  color: var(--ltn__heading-color);
  padding: 5px 10px;
}

.ltn__modal-area .modal-btn {
  border: 0;
}

.modal-body {
  padding: 30px;
}

.ltn__add-to-cart-modal-area .modal-body {
  padding: 30px;
}

.ltn__add-to-cart-modal-area .modal-product-img {
  float: left;
  max-width: 125px;
  margin-right: 20px;
}

.ltn__add-to-cart-modal-area .modal-product-info {
  overflow: hidden;
}

.ltn__add-to-cart-modal-area .added-cart i {
  color: var(--green);
}

.ltn__add-to-cart-modal-area .btn-wrapper {
  margin-top: 20px;
}
.ltn__add-to-cart-modal-area .btn-wrapper .btn {
  padding: 5px 20px;
  margin-right: 10px;
}

.ltn__add-to-cart-modal-area .additional-info {
  border-top: 1px solid var(--border-color-1);
  text-align: center;
  padding-top: 30px;
  margin-top: 40px;
}

.modal-backdrop.show {
  opacity: 0.7;
}

/* ----------------------------------------------------
    Product Tab
---------------------------------------------------- */
.ltn__tab-menu {
  margin-bottom: 50px;
}

.ltn__tab-menu .nav {
  display: inline-block;
}

.ltn__gallery-filter-menu button,
.ltn__tab-menu a {
  display: inline-block;
  padding: 15px 40px;
  margin-right: 5px;
  margin-bottom: 10px;
  color: var(--ltn__heading-color);
  background-color: var(--section-bg-1);
  font-weight: 700;
}
.ltn__gallery-filter-menu button:last-child,
.ltn__tab-menu a:last-child {
  margin-right: 0;
}
.ltn__gallery-filter-menu button i,
.ltn__tab-menu a i {
  margin-right: 10px;
}

.ltn__gallery-filter-menu .active,
.ltn__tab-menu a.active {
  color: var(--white);
  background-color: var(--ltn__primary-color);
  border-color: var(--ltn__primary-color);
}

.ltn__tab-menu-top-left {
  position: absolute;
  left: 15px;
  top: 0;
}

.ltn__tab-menu-top-right {
  position: absolute;
  right: 15px;
  top: 0;
}

.ltn__gallery-filter-menu.text-uppercase button {
  text-transform: uppercase;
}

.ltn__tab-menu-2 a {
  background-color: transparent;
  border-bottom: 2px solid transparent;
  position: relative;
}
.ltn__tab-menu-2 a.active {
  color: var(--ltn__secondary-color);
  background-color: transparent;
  border-color: var(--ltn__secondary-color);
}
.ltn__tab-menu-2 a::before {
  position: absolute;
  content: "";
  right: -5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 16px;
  width: 2px;
  background-color: var(--ltn__color-1);
  opacity: 0.4;
}
.ltn__tab-menu-2 a:last-child::before {
  display: none;
}

.ltn__tab-menu-3 {
  margin-top: 0;
}
.ltn__tab-menu-3 a {
  background-color: transparent;
  border-bottom: 2px solid transparent;
  padding: 1px;
  margin-right: 50px;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  position: relative;
}
.ltn__tab-menu-3 a.active {
  color: var(--ltn__secondary-color);
  background-color: transparent;
  border-color: var(--ltn__secondary-color);
}
.ltn__tab-menu-3 a.active::before {
  background-color: var(--ltn__secondary-color);
  border: 3px solid var(--white);
}
.ltn__tab-menu-3 a:last-child {
  margin-right: 0;
}
.ltn__tab-menu-3 a::before {
  position: absolute;
  content: "";
  right: 45%;
  top: 100%;
  -webkit-transform: translateY(-40%);
  -ms-transform: translateY(-40%);
  transform: translateY(-40%);
  height: 10px;
  width: 10px;
  background-color: transparent;
  opacity: 1;
  border: 3px solid transparent;
  border-radius: 100%;
}

.ltn__tab-menu-4 a {
  background-color: transparent;
  padding: 0;
  border: 7px solid;
  border-color: var(--white);
}
.ltn__tab-menu-4 a img {
  max-width: 120px;
}
.ltn__tab-menu-4 a.active {
  color: var(--ltn__secondary-color);
  background-color: transparent;
  border-color: var(--white);
}
.ltn__tab-menu-4 a.active::before {
  display: none;
}
.ltn__tab-menu-4 a.active img {
  opacity: 0.6;
}

@media (max-width: 1200px) {
  .ltn__tab-menu-3 a {
    margin-right: 30px;
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .ltn__gallery-filter-menu button,
  .ltn__tab-menu a {
    padding: 15px 25px;
    font-size: 14px;
  }
  .ltn__tab-menu-top-right {
    position: initial;
  }
  .ltn__tab-menu-3 {
    margin-top: 0;
  }
  .ltn__tab-menu-3 a {
    padding: 1px;
    margin-right: 20px;
  }
  .ltn__tab-menu-4 a {
    padding: 0;
  }
  .ltn__tab-menu-4 a.active {
    padding: 0;
  }
}

/* ----------------------------------------------------
    Product Details
---------------------------------------------------- */
.ltn__small-product-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 25px;
  border: 2px solid;
  border-color: var(--border-color-1);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__small-product-item:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
  border-color: transparent;
}
.ltn__small-product-item .product-price {
  color: var(--ltn__secondary-color);
}

.small-product-item-img {
  max-width: 90px;
  margin-right: 20px;
}

/* ----------------------------------------------------
    Product Details
---------------------------------------------------- */
.product-meta-date input[type="date"] {
  border: 1px solid #ddd;
  height: 63px;
  padding: 0 15px;
  line-height: 50px;
}

.product-meta-date input[type="date"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
  line-height: 50px;
}

.product-meta-date input[type="date"]::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
  line-height: 50px;
}

.product-meta-date input[type="date"]:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
  line-height: 50px;
}

.product-meta-date input[type="date"]:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
  line-height: 50px;
}

/* ltn__shop-details-img-gallery */
.ltn__shop-details-img-gallery {
  margin-bottom: 35px;
}
.ltn__shop-details-img-gallery img {
  background-color: var(--white-8);
}

.ltn__shop-details-small-img {
  margin-top: 20px;
  margin-left: -5px;
  margin-right: -5px;
}
.ltn__shop-details-small-img .single-small-img {
  padding: 0 5px;
}
.ltn__shop-details-small-img .single-small-img.slick-current img {
  border: 1px solid var(--ltn__secondary-color);
  margin-bottom: 10px;
}

.ltn__shop-details-small-img.slick-arrow-2 {
  margin-bottom: 35px;
}
.ltn__shop-details-small-img.slick-arrow-2 .slick-arrow {
  left: 5px;
  bottom: -35px;
}
.ltn__shop-details-small-img.slick-arrow-2 .slick-next {
  left: 45px;
}

/* ltn__shop-details-content-wrap */
.ltn__shop-details-content-wrap {
  padding: 50px 50px 40px;
}

/* ltn__shop-details-tab-area */
.ltn__shop-details-tab-menu .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ltn__shop-details-tab-menu .nav a {
  background-color: var(--ltn__primary-color);
  color: var(--white);
  padding: 20px 20px;
  font-size: 16px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  text-align: center;
  font-weight: 700;
}
.ltn__shop-details-tab-menu .nav a.active {
  background-color: var(--ltn__secondary-color);
}

.ltn__shop-details-tab-content-inner {
  border: 1px solid #e5e5e5;
  padding: 50px 50px 30px;
  background-color: var(--section-bg-1);
}
.ltn__shop-details-tab-content-inner .ltn__comment-reply-area form {
  padding: 0;
}

.add-a-review {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-a-review h6 {
  margin-bottom: 0;
  margin-right: 15px;
}

.ltn__shop-details-tab-inner-2 .ltn__shop-details-tab-menu {
  border-bottom: 2px solid var(--border-color-1);
}
.ltn__shop-details-tab-inner-2 .ltn__shop-details-tab-menu .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ltn__shop-details-tab-inner-2 .ltn__shop-details-tab-menu .nav a {
  background-color: transparent;
  color: var(--ltn__paragraph-color);
  padding: 20px 0px;
  margin-right: 50px;
  -webkit-box-flex: inherit;
  -ms-flex: inherit;
  flex: inherit;
  text-align: left;
  position: relative;
}
.ltn__shop-details-tab-inner-2 .ltn__shop-details-tab-menu .nav a::before {
  position: absolute;
  content: "";
  bottom: -2px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: transparent;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__shop-details-tab-inner-2 .ltn__shop-details-tab-menu .nav a.active {
  color: var(--ltn__secondary-color);
  background-color: transparent;
}
.ltn__shop-details-tab-inner-2
  .ltn__shop-details-tab-menu
  .nav
  a.active::before {
  background-color: var(--ltn__secondary-color);
  width: 100%;
}

.ltn__shop-details-tab-inner-2 .ltn__shop-details-tab-content-inner {
  border: 0;
  padding: 30px 0px 0px;
  background-color: transparent;
}
.ltn__shop-details-tab-inner-2
  .ltn__shop-details-tab-content-inner
  .ltn__comment-reply-area
  form {
  padding: 30px;
}

/* product-details-menu-1 */
.ltn__product-details-menu-1 ul li a {
  position: relative;
  margin-right: 10px;
}
.ltn__product-details-menu-1 ul li a::before {
  position: absolute;
  content: ",";
  right: -5px;
}
.ltn__product-details-menu-1 ul li a:last-child::before {
  display: none;
}

.ltn__product-details-menu-1 ul li strong {
  font-weight: 400;
}

.ltn__product-details-menu-1 ul li span {
  font-weight: 600;
}

.ltn__product-details-menu-1 .ltn__color-widget ul li a {
  width: 15px;
  height: 15px;
}

.ltn__product-details-menu-1 .ltn__tagcloud-widget ul li {
  margin: 0;
}
.ltn__product-details-menu-1 .ltn__tagcloud-widget ul li a {
  padding: 3px 10px 1px;
}

/* product-details-menu-2 */
.ltn__product-details-menu-2 ul {
  padding: 0;
  margin: 0;
}
.ltn__product-details-menu-2 ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.ltn__product-details-menu-2 ul li:last-child {
  margin-right: 0;
}

/* product-details-menu-3 */
.ltn__product-details-menu-3 ul {
  padding: 0;
  margin: 0;
}
.ltn__product-details-menu-3 ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
}
.ltn__product-details-menu-3 ul li:last-child {
  margin-right: 0;
}

.property-detail-info-list ul {
  padding: 30px 50px 40px;
  margin: 0;
  float: left;
  border-right: 1px solid;
  border-color: var(--border-color-7);
}
.property-detail-info-list ul:last-child {
  border-right: none;
}
.property-detail-info-list ul li {
  display: block;
}
.property-detail-info-list ul li label {
  margin-right: 5px;
  font-weight: 600;
  min-width: 110px;
  display: inline-block;
}
.property-detail-info-list ul li span {
  font-weight: 600;
  color: var(--ltn__heading-color);
}

.property-detail-feature-list {
  margin-top: 0;
}
.property-detail-feature-list ul {
  margin: 0;
  padding: 0;
}
.property-detail-feature-list ul li {
  display: inline-block;
  min-width: 170px;
  margin-right: 19px;
  margin-bottom: 15px;
}
.property-detail-feature-list .property-detail-feature-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}
.property-detail-feature-list .property-detail-feature-list-item i {
  margin-right: 12px;
  width: 40px;
  min-width: 40px;
  height: 40px;
  line-height: 49px;
  background-color: var(--section-bg-5);
  text-align: center;
  font-size: 22px;
  color: var(--ltn__secondary-color);
}
.property-detail-feature-list .property-detail-feature-list-item h6 {
  margin-top: 0;
  margin-bottom: 6px;
  font-size: 14px;
  font-family: var(--ltn__heading-font);
  font-weight: 500;
}

.property-details-google-map {
  height: 360px;
}

.product-details-apartments-plan .apartments-plan-info {
  padding: 0;
}

.product-details-apartments-info-list {
  padding: 20px 40px;
}

.ltn__page-details-inner .ltn__product-details-review-inner {
  margin-top: 0;
}
.ltn__page-details-inner .ltn__product-details-review-inner h1,
.ltn__page-details-inner .ltn__product-details-review-inner h2,
.ltn__page-details-inner .ltn__product-details-review-inner h3,
.ltn__page-details-inner .ltn__product-details-review-inner h4,
.ltn__page-details-inner .ltn__product-details-review-inner h5,
.ltn__page-details-inner .ltn__product-details-review-inner h6,
.ltn__page-details-inner .ltn__product-details-review-inner p {
  margin-top: 0;
}

@media (max-width: 767px) {
  .property-detail-info-list ul {
    padding: 20px 30px 20px;
    float: none;
    border: none;
  }
}

/* ----------------------------------------------------
    Product Options
---------------------------------------------------- */
.nice-select .option {
  margin-top: 0;
  white-space: normal;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 1.8;
}

.nice-select .list {
  min-width: 100%;
}

.ltn__shop-options {
  margin-bottom: 50px;
}
.ltn__shop-options > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  margin: 0;
  padding: 0;
}
.ltn__shop-options > ul > li {
  list-style: none;
  margin-top: 0;
  line-height: 50px;
  margin-right: 30px;
}
.ltn__shop-options > ul > li:last-child {
  margin-right: 0;
}
.ltn__shop-options .short-by .nice-select {
  border-radius: 0;
  border: 2px solid #ededed;
  height: 50px;
  line-height: 46px;
  min-width: 190px;
}
.ltn__shop-options .short-by .nice-select .option {
  width: 100%;
  padding-left: 10px;
  padding-right: 5px;
}
.ltn__shop-options .short-by .nice-select .current {
  font-weight: 700;
}

.ltn__grid-list-tab-menu a {
  font-size: 30px;
  margin-right: 20px;
}

.ltn__grid-list-tab-menu a:last-child {
  margin-right: 0px;
}

.ltn__grid-list-tab-menu .active {
  color: var(--ltn__secondary-color);
}

.showing-product-number span {
  font-weight: 500;
  font-size: 16px;
  font-family: var(--ltn__heading-font);
}

.ltn__product-list-view .product-title {
  font-size: 22px;
  margin-bottom: 10px;
}

.ltn__product-list-view .ltn__product-item:after {
  display: block;
  clear: both;
  content: "";
}

.ltn__product-list-view .ltn__product-item .product-img {
  max-width: 40%;
  float: left;
}

.ltn__product-list-view .ltn__product-item .product-info {
  overflow: hidden;
  padding: 0 0 0 30px;
}

.ltn__product-list-view .ltn__product-item-3 .product-info {
  overflow: hidden;
  padding: 25px 25px 20px 30px;
}

.ltn__product-list-view .product-hover-action {
  position: inherit;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  text-align: left;
  opacity: 1;
  visibility: visible;
  margin-top: 15px;
}

/* Bootstrap Tab with Slider Issue Fix Start */
.tab-content {
  width: 100%;
}

.tab-content .tab-pane {
  display: block;
  height: 0;
  max-width: 100%;
  visibility: hidden;
  overflow: hidden;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/* Bootstrap Tab with Slider Issue Fix End */
@media (max-width: 1400px) {
  .shop-details-info .product-price {
    font-size: 36px;
  }
  .shop-details-info .cart-plus-minus {
    width: 130px;
  }
  .shop-details-info .ltn__product-details-menu-2 .btn {
    padding: 17px 15px;
  }
}

@media (max-width: 991px) {
  .ltn__product-item-3 .product-info {
    padding: 25px 15px 15px;
  }
  .ltn__product-item-3 .product-price {
    font-size: 16px;
  }
  .ltn__product-item-3 .product-price del {
    font-size: 14px;
  }
  .ltn__shop-details-img-gallery {
    margin-bottom: 75px;
  }
  .ltn__product-item-4 .product-title {
    font-size: 20px;
  }
  .ltn__product-item-4 .product-info {
    padding: 30px 20px 1px;
  }
  .ltn__product-item-4 .product-img-location-gallery {
    padding: 0 15px 15px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-flow: wrap-reverse;
    flex-flow: wrap-reverse;
  }
  .ltn__product-item-4 .product-img-location-gallery ul li {
    font-size: 13px;
    margin-top: 5px;
  }
  .ltn__product-item-4 .product-img-location-gallery .product-img-gallery {
    margin-left: 0;
  }
  .ltn__product-item-4
    .product-img-location-gallery
    .product-img-gallery
    ul
    li {
    width: inherit;
    background-color: transparent;
    margin-top: 0;
  }
  .ltn__product-item-4 .product-description p br {
    display: none;
  }
  .ltn__product-item-4 .product-info-bottom {
    padding: 20px 20px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-flow: wrap-reverse;
    flex-flow: wrap-reverse;
  }
  .ltn__product-item-4 .real-estate-agent {
    margin-top: 20px;
  }
  .slick-slide .ltn__product-item-4 {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

@media (max-width: 767px) {
  .ltn__shop-options > ul {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .ltn__shop-options ul li {
    margin: 5px 0;
  }
  .showing-product-number span {
    font-size: 16px;
  }
  .ltn__product-list-view .ltn__product-item .product-img {
    max-width: 100%;
  }
  .ltn__product-list-view .ltn__product-item .product-info {
    padding: 25px 0 0 1px;
  }
  .ltn__product-list-view .ltn__product-item-3 .product-info {
    padding: 25px 25px 20px 30px;
  }
  .modal-product-info h3 {
    font-size: 22px;
  }
  .modal-product-info .product-price {
    font-size: 34px;
  }
  .ltn__shop-details-tab-menu .nav a {
    padding: 15px 15px;
    font-size: 14px;
  }
  .ltn__shop-details-tab-content-inner {
    padding: 50px 20px 30px;
  }
  .ltn__product-item-4 .product-title {
    font-size: 18px;
  }
  .ltn__product-list-view .ltn__product-item-5 .product-info-bottom {
    padding: 0px 0px 0px 0px;
    border: 0;
  }
}

@media (max-width: 576px) {
  .product-hover-action li {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 13px;
  }
  .product-badge li {
    font-size: 12px;
    padding: 1px 10px;
    line-height: 20px;
  }
  .product-badge li::before {
    bottom: -6px;
  }
  .ltn__product-item-3 .product-title {
    font-size: 14px;
  }
  .ltn__product-item-3 .product-info {
    padding-left: 5px;
    padding-right: 5px;
  }
  .ltn__product-item-3 .product-price {
    font-size: 14px;
  }
  .ltn__product-item-3 .product-price del {
    font-size: 13px;
  }
  .ltn__product-item-3 .product-info-brief ul li {
    font-size: 12px;
  }
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* ----------------------------------------------
  Gallery Area
---------------------------------------------- */
.ltn__gallery-item {
  margin-bottom: 30px;
}
.ltn__gallery-item:hover .ltn__gallery-item-img::before {
  opacity: 0.9;
  visibility: visible;
}
.ltn__gallery-item:hover .ltn__gallery-item-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.ltn__gallery-item:hover .ltn__gallery-action-icon {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

.ltn__gallery-item-inner {
  position: relative;
  overflow: hidden;
}
.ltn__gallery-item-inner h4 {
  margin-bottom: 5px;
}
.ltn__gallery-item-inner p {
  margin-bottom: 0;
  font-size: 14px;
}

.ltn__gallery-item-img {
  position: relative;
  overflow: hidden;
}
.ltn__gallery-item-img::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--ltn__primary-color);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 1;
}
.ltn__gallery-item-img img {
  -webkit-transition: all 3.5s ease 0s;
  -o-transition: all 3.5s ease 0s;
  transition: all 3.5s ease 0s;
}

.ltn__gallery-action-icon {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  z-index: 2;
}
.ltn__gallery-action-icon i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: var(--white);
  color: var(--ltn__primary-color);
  border-radius: 100%;
  margin: 3px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__gallery-action-icon i:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__gallery-item-info {
  padding: 25px 30px;
  position: relative;
  z-index: 2;
}

/* Gallery Style 1 */
.ltn__gallery-style-1 .ltn__gallery-item-info {
  border: 1px solid var(--border-color-1);
}

/* Gallery Style 2 */
.ltn__gallery-style-2 .ltn__gallery-item-info {
  position: absolute;
  bottom: -30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  background: var(--gradient-color-3);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.ltn__gallery-style-2 h4,
.ltn__gallery-style-2 p {
  color: var(--white);
}

.ltn__gallery-style-2 .ltn__gallery-item:hover .ltn__gallery-item-info {
  bottom: 0px;
  opacity: 1;
  visibility: visible;
}

.ltn__gallery-style-2 .ltn__gallery-item:hover .ltn__gallery-action-icon {
  top: 35%;
}

.ltn__gallery-info-hide .ltn__gallery-item-info {
  display: none;
}

.ltn__gallery-info-hide .ltn__gallery-item:hover .ltn__gallery-action-icon {
  top: 50%;
}

/* Lightcase Default CSS */
.lightcase-error {
  color: var(--white);
}

#lightcase-info {
  color: var(--ltn__color-1);
}

#lightcase-caption {
  color: var(--white);
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* ============================================================
>>> TABLE OF CONTENTS:
===============================================================
# Common CSS
# Section title
# Category Area
# Feature Area 
# Countdown Area
# Blog Area
# Blog Details
# Service Details
# Pagination
# Testimonial
# Banner Area
# Team Area
# CounterUp Area
# Contact Form Area
# Cart Table Area
# Cart plus minus
# Product Details
# Shoping Cart
# Custom Content
# Newsletter
# Faq Area
# 404 Area
# Coming Soon Area
# Screenshot Area
# Pricing List Area
# Checkbox
# Body Sidebar Icons
# About Us Area
# Why Choose Us Area
# Service Area
# Call To Action
# Elements Area
# Service Form 
# Get A Quote Form
# Car Dealer Form
# Video Area
# Brand Logo
# Progress Bar 
# Our Journey Area
# Google Map Locations Area
# Team Details
# Our History Area
# Appointment Form Area
# Checkout Page
# Myaccount Page
# Time Schedule Area

============================================================= */
/* ----------------------------------------------------
    Common CSS
---------------------------------------------------- */
.ltn__social-media ul {
  margin: 0;
  padding: 0;
}
.ltn__social-media ul li {
  list-style: none;
  display: inline-block;
  margin: 0 15px 0 0;
}
.ltn__social-media ul li:last-child {
  margin: 0;
}

.ltn__social-media-2 ul {
  margin: 0;
  padding: 0;
}
.ltn__social-media-2 ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.ltn__social-media-2 ul li a {
  background-color: var(--section-bg-1);
  color: var(--ltn__paragraph-color);
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.ltn__social-media-2 ul li a i {
  color: inherit;
}
.ltn__social-media-2 ul li:last-child {
  margin-right: 0;
}
.ltn__social-media-2 ul li:hover a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__social-media-3 ul {
  margin: 0;
  padding: 0;
}
.ltn__social-media-3 ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.ltn__social-media-3 ul li a {
  background-color: var(--white);
  color: var(--ltn__paragraph-color);
  border: 2px solid var(--border-color-11);
  display: block;
  width: 50px;
  height: 50px;
  line-height: 46px;
  text-align: center;
}
.ltn__social-media-3 ul li a i {
  color: inherit;
}
.ltn__social-media-3 ul li:last-child {
  margin-right: 0;
}
.ltn__social-media-3 ul li:hover a {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white);
}

.bg-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-image-top {
  background-size: auto;
  background-position: top center;
  background-repeat: no-repeat;
}

.bg-image-right-before {
  position: relative;
}
.bg-image-right-before::before {
  position: absolute;
  content: "";
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/bg/21.jpg);
  height: 100%;
  width: 48%;
  left: auto;
  right: 0;
  top: 0;
}

.bg-image-left-before {
  position: relative;
}
.bg-image-left-before::before {
  position: absolute;
  content: "";
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../img/bg/21.jpg);
  height: 100%;
  width: 48%;
  left: 0;
  right: auto;
  top: 0;
}

.nice-select .option {
  font-weight: 400;
}

/* Responsive Device under :991px. */
@media (max-width: 991px) {
  .bg-image-top {
    background-size: inherit;
  }
  .bg-image-right-before::before,
  .bg-image-left-before::before {
    display: none;
  }
}

.ltn__social-media-4 ul {
  margin: 0;
  padding: 0;
}
.ltn__social-media-4 ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.ltn__social-media-4 ul li a {
  background-color: var(--ltn__primary-color-3);
  color: var(--white);
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
}
.ltn__social-media-4 ul li:last-child {
  margin-right: 0;
}
.ltn__social-media-4 ul li:hover a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

/* ----------------------------------------
  Section title
---------------------------------------- */
.section-title-area {
  margin-bottom: 50px;
}
.section-title-area p {
  margin-bottom: 0;
  max-width: 500px;
}
.section-title-area p + p {
  margin-top: 15px;
}
.section-title-area.text-right p {
  margin-left: auto;
}
.section-title-area.text-center p {
  margin-left: auto;
  margin-right: auto;
}

.section-title {
  font-size: 44px;
  font-weight: 700;
  line-height: 1.3;
}
.section-title span {
  color: var(--ltn__secondary-color);
}
.section-title p {
  font-size: 16px;
  line-height: 1.8;
  color: var(--ltn__paragraph-color);
  margin-top: 20px;
}
.section-title.white .section-title {
  color: var(--white);
}
.section-title.white p {
  color: rgba(var(--white), 0.7);
}

/* ltn__separate-line */
.ltn__separate-line {
  position: relative;
  display: inline-block;
  min-width: 200px;
  margin-bottom: 3px;
  text-align: center !important;
}
.ltn__separate-line::before {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  height: 1px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f28bc2),
    color-stop(50%, #d8b1f2)
  );
  background: -webkit-linear-gradient(left, #f28bc2 0%, #d8b1f2 50%);
  background: -o-linear-gradient(left, #f28bc2 0%, #d8b1f2 50%);
  background: linear-gradient(90deg, #f28bc2 0%, #d8b1f2 50%);
}
.ltn__separate-line .separate-icon {
  position: relative;
  z-index: 1;
  background-color: #fff;
  padding: 0 15px;
}
.ltn__separate-line i {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  margin: 0 -4px;
  color: var(--ltn__secondary-color);
}

/* Section Title 2 */
.ltn__section-title-2 {
  margin-bottom: 40px;
}
.ltn__section-title-2 .section-subtitle {
  margin-top: 0;
}
.ltn__section-title-2 .section-title {
  font-size: 72px;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 1.3;
}
.ltn__section-title-2 p {
  padding: 0 0 0 30px;
  border-width: 0 0 0 2px;
  border-style: solid;
  border-color: var(--ltn__secondary-color);
  max-width: 450px;
}
.ltn__section-title-2.text-right p {
  padding: 0 30px 0 0;
  border-width: 0 2px 0 0;
  margin-left: auto;
}
.ltn__section-title-2.text-center p {
  padding: 0 0 0 30px;
  border-width: 0 0 0 2px;
  margin-left: auto;
  margin-right: auto;
  text-align: left !important;
}

/* Section Title 3 */
.section-title-style-3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.section-title-style-3 .section-brief-in p {
  padding: 0 30px 0 0;
  border-width: 0 2px 0 0;
  margin-left: auto;
  margin-right: 30px;
  text-align: right;
}

/* title-2 */
.title-2 {
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 600;
  border-left: 2px solid;
  border-color: var(--ltn__secondary-color);
  padding-left: 10px;
}

.section-subtitle {
  font-weight: 600;
  font-family: var(--ltn__body-font);
}

.section-subtitle-2 {
  display: inline-block;
  padding: 5px 20px;
  border-radius: 25px;
  position: relative;
}
.section-subtitle-2::before {
  position: absolute;
  content: "";
  background-color: var(--ltn__secondary-color);
  width: 100%;
  height: 100%;
  top: 50%;
  right: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
  border-radius: 25px;
  opacity: 0.1;
}

@media (max-width: 1599px) {
  .ltn__section-title-2 .section-title {
    font-size: 60px;
  }
}

@media (max-width: 1399px) {
  .ltn__section-title-2 .section-title {
    font-size: 56px;
  }
  .section-title-style-3 .section-title {
    min-width: 320px;
  }
}

@media (max-width: 1199px) {
  .section-title {
    font-size: 30px;
  }
  .ltn__section-title-2 .section-title {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .section-title {
    font-size: 26px;
  }
  .ltn__section-title-2 .section-title {
    font-size: 40px;
  }
  .ltn__section-title-2 p {
    padding: 0 0 0 15px;
  }
}

@media (max-width: 767px) {
  .section-title {
    font-size: 30px;
  }
  .ltn__section-title-2 .section-title {
    font-size: 30px;
  }
  .ltn__section-title-2 p {
    padding: 0 0 0 15px;
  }
  .section-title-style-3 {
    display: block;
  }
  .section-title-style-3 .section-title {
    min-width: 100%;
  }
  .section-title-style-3 .section-brief-in p {
    padding: 0 0px 0 15px;
    border-width: 0 0px 0 2px;
    margin-left: 0;
    margin-right: 0;
    text-align: left;
    margin-bottom: 15px;
  }
  .title-2 {
    font-size: 22px;
  }
}

@media (max-width: 575px) {
  .section-title {
    font-size: 24px;
  }
  .ltn__section-title-2 .section-title {
    font-size: 24px;
  }
}

/* ----------------------------------------------------
    Category Area
---------------------------------------------------- */
.ltn__category-item {
  margin-top: 2px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: 1px solid var(--border-color-7);
  background-color: var(--white);
}

.ltn__category-item-img {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ltn__category-item-name {
  padding: 15px 20px 5px;
}

.ltn__category-item:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
}

/* ltn__category-item-2 */
.ltn__category-item-2 {
  padding: 20px 15px 15px;
}
.ltn__category-item-2 .ltn__category-item-img {
  float: left;
  width: 35%;
}
.ltn__category-item-2 .ltn__category-item-name {
  overflow: hidden;
}
.ltn__category-item-2 .ltn__category-item-name ul {
  padding: 0;
  margin: 0;
}
.ltn__category-item-2 .ltn__category-item-name ul li {
  list-style: circle;
  margin-top: 5px;
  font-size: 14px;
}
.ltn__category-item-2 .ltn__category-item-name ul li a {
  display: block;
}
.ltn__category-item-2 h4 {
  margin-bottom: 5px;
}
.ltn__category-item-2 .category-btn {
  float: right;
  font-size: 13px;
  text-transform: uppercase;
  text-decoration: underline;
}

/* ltn__category-item-3 */
.ltn__category-item-3 {
  padding: 50px 0 25px;
  position: relative;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__category-item-3::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 4px;
  background-color: var(--ltn__secondary-color);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.ltn__category-item-3:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__category-item-3:hover::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.ltn__category-item-3:hover h5 {
  color: var(--ltn__secondary-color);
}

.ltn__category-item-4 {
  padding: 40px 30px 30px;
  position: relative;
  border: none;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border-radius: 10px;
}
.ltn__category-item-4 .ltn__category-item-img {
  width: 100px;
  height: 100px;
  line-height: 115px;
  font-size: 45px;
  border-radius: 100%;
  background-color: var(--section-bg-5);
  color: var(--ltn__secondary-color);
  text-align: center;
  margin-right: 0;
  margin-left: auto;
}
.ltn__category-item-4 .ltn__category-item-name {
  padding: 25px 0px 15px;
}
.ltn__category-item-4 .ltn__category-item-name h4 {
  font-weight: 600;
}
.ltn__category-item-4 .ltn__category-item-name p {
  margin-bottom: 0;
}
.ltn__category-item-4 .ltn__category-item-btn {
  position: absolute;
  left: 0;
  width: 100%;
  bottom: -20px;
}
.ltn__category-item-4 .ltn__category-item-btn a {
  width: 45px;
  height: 45px;
  line-height: 50px;
  background-color: var(--white);
  display: block;
  border-radius: 100%;
  text-align: center;
  font-size: 18px;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-2);
  border: 1px solid var(--border-color-8);
}
.ltn__category-item-4.text-center .ltn__category-item-img {
  margin-left: auto;
  margin-right: auto;
}
.ltn__category-item-4.text-center .ltn__category-item-btn a {
  margin-left: auto;
  margin-right: auto;
}
.ltn__category-item-4:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
}

.ltn__category-item-5 {
  border: none;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border-radius: 10px;
  margin-bottom: 50px;
}
.ltn__category-item-5 a {
  padding: 40px 30px 35px;
  display: block;
  position: relative;
}
.ltn__category-item-5 a span {
  display: block;
}
.ltn__category-item-5 a .category-icon {
  width: 100px;
  height: 100px;
  line-height: 115px;
  font-size: 45px;
  border-radius: 100%;
  background-color: var(--section-bg-5);
  color: var(--ltn__secondary-color);
  text-align: center;
  margin-bottom: 20px;
}
.ltn__category-item-5 a .category-title {
  font-size: 22px;
  font-weight: 600;
  font-family: var(--ltn__heading-font);
  color: var(--ltn__heading-color);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin-bottom: 10px;
}
.ltn__category-item-5 a .category-btn {
  width: 45px;
  height: 45px;
  line-height: 50px;
  background-color: var(--white);
  display: block;
  border-radius: 100%;
  text-align: center;
  font-size: 18px;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-2);
  border: 1px solid var(--border-color-8);
  position: absolute;
  bottom: -20px;
  right: 50%;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}
.ltn__category-item-5.text-center .category-icon {
  margin-left: auto;
  margin-right: auto;
}
.ltn__category-item-5.text-center .category-btn {
  margin-left: auto;
  margin-right: auto;
}
.ltn__category-item-5:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}
.ltn__category-item-5:hover .category-title {
  color: var(--white);
}

.ltn__category-item-5-2 a .category-icon {
  margin-right: 0;
  margin-left: auto;
}

.ltn__category-item-5-2 a .category-number {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--ltn__body-font);
  color: var(--ltn__secondary-color);
}

.ltn__category-item-5-2 a .category-brief {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ltn__category-item-5-2:hover {
  background-color: var(--ltn__secondary-color);
}
.ltn__category-item-5-2:hover .category-number,
.ltn__category-item-5-2:hover .category-brief {
  color: var(--white);
}

@media (max-width: 1200px) {
  .ltn__category-item-5 a {
    padding: 40px 20px 35px;
  }
  .ltn__category-item-5 a .category-icon {
    width: 80px;
    height: 80px;
    line-height: 90px;
    font-size: 30px;
  }
  .ltn__category-item-5 a .category-title {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .ltn__category-item-2 .ltn__category-item-img {
    display: none;
  }
  .ltn__category-item-3 {
    padding: 30px 0 5px;
  }
  .ltn__category-item-5 a {
    padding: 40px 15px 35px;
  }
  .ltn__category-item-5 a .category-icon {
    width: 60px;
    height: 60px;
    line-height: 70px;
    font-size: 25px;
  }
  .ltn__category-item-5 a .category-title {
    font-size: 13px;
  }
}

/* ----------------------------------------------------
    Feature Area 
---------------------------------------------------- */
.ltn__feature-item {
  padding: 40px 25px 10px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
}

.ltn__feature-icon {
  margin-bottom: 20px;
  font-size: 60px;
  line-height: 1;
}

/* feature-item-2 */
.ltn__feature-item-2 {
  padding: 40px 25px 10px;
}
.ltn__feature-item-2 .ltn__feature-icon {
  margin-bottom: 20px;
}
.ltn__feature-item-2 .ltn__feature-icon span {
  background: transparent;
  height: 100px;
  width: 100px;
  line-height: 109px;
  border-radius: 100%;
  position: relative;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  display: inline-block;
  text-align: center;
}
.ltn__feature-item-2 .ltn__feature-icon span::before,
.ltn__feature-item-2 .ltn__feature-icon span::after {
  position: absolute;
  content: "";
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 100%;
  -webkit-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}
.ltn__feature-item-2 .ltn__feature-icon span:before {
  height: 0%;
  width: 0%;
  background: transparent;
}
.ltn__feature-item-2 .ltn__feature-icon span:after {
  height: 100%;
  width: 100%;
  border: 1px solid;
  border-color: var(--ltn__heading-color);
}
.ltn__feature-item-2 .ltn__feature-icon i {
  font-size: 26px;
  color: var(--ltn__heading-color);
  line-height: 1;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  z-index: 1;
  position: relative;
}
.ltn__feature-item-2.active-feature .ltn__feature-icon span,
.ltn__feature-item-2:hover .ltn__feature-icon span {
  color: var(--white);
  border-color: transparent;
}
.ltn__feature-item-2.active-feature .ltn__feature-icon span:before,
.ltn__feature-item-2:hover .ltn__feature-icon span:before {
  height: 100%;
  width: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ee91cb),
    color-stop(50%, #d9b0f1)
  );
  background: -webkit-linear-gradient(left, #ee91cb 0%, #d9b0f1 50%);
  background: -o-linear-gradient(left, #ee91cb 0%, #d9b0f1 50%);
  background: linear-gradient(90deg, #ee91cb 0%, #d9b0f1 50%);
}
.ltn__feature-item-2.active-feature .ltn__feature-icon span:after,
.ltn__feature-item-2:hover .ltn__feature-icon span:after {
  border-color: var(--white);
  height: 80%;
  width: 80%;
}
.ltn__feature-item-2.active-feature .ltn__feature-icon i,
.ltn__feature-item-2:hover .ltn__feature-icon i {
  color: var(--white);
}
.ltn__feature-item-2.active-feature .ltn__feature-info h6,
.ltn__feature-item-2:hover .ltn__feature-info h6 {
  color: var(--ltn__secondary-color);
}

/* feature-item-3 */
.ltn__feature-item-3 {
  padding: 25px 22px 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border: 2px solid;
  border-color: #f4faff;
  margin-bottom: 20px;
  position: relative;
}
.ltn__feature-item-3 h1,
.ltn__feature-item-3 h2,
.ltn__feature-item-3 h3,
.ltn__feature-item-3 h4,
.ltn__feature-item-3 h5,
.ltn__feature-item-3 h6 {
  margin-bottom: 5px;
}
.ltn__feature-item-3 .ltn__feature-icon {
  margin: 0px 20px 0 0;
  font-size: 50px;
  color: var(--ltn__secondary-color);
  line-height: 1.5;
}
.ltn__feature-item-3::before {
  position: absolute;
  content: "";
  left: -2px;
  top: 50%;
  width: 4px;
  height: 0%;
  background-color: var(--ltn__secondary-color);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ltn__feature-item-3:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__feature-item-3:hover::before {
  height: 80%;
  opacity: 1;
  visibility: visible;
}
.ltn__feature-item-3.text-right {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-flow: row-reverse;
  flex-flow: row-reverse;
}
.ltn__feature-item-3.text-right .ltn__feature-icon {
  margin: 0px 0 0 20px;
}
.ltn__feature-item-3.text-right::before {
  right: -2px;
  left: auto;
}

/* feature-item-4 */
.ltn__feature-item-4 {
  padding: 50px 20px 15px;
  background-color: var(--white);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
}
.ltn__feature-item-4:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

/* feature-item-5 */
.ltn__feature-item-5 {
  padding: 70px 30px 25px;
  margin-bottom: 30px;
}
.ltn__feature-item-5 .ltn__feature-icon {
  margin-bottom: 25px;
  font-size: 80px;
  line-height: 1;
}
.ltn__feature-item-5 .ltn__feature-icon img {
  max-width: 200px;
}
.ltn__feature-item-5 .ltn__feature-icon span {
  position: relative;
}
.ltn__feature-item-5 .ltn__feature-icon span::before {
  position: absolute;
  content: "\e942";
  font-family: "icomoon";
  right: -10px;
  top: -10px;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background-color: var(--ltn__primary-color);
  color: var(--white);
  font-size: 14px;
  border-radius: 100%;
}
.ltn__feature-item-5 .btn-wrapper {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.ltn__feature-item-5 .btn-wrapper a {
  min-height: 60px;
}
.ltn__feature-item-5:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__feature-item-5:hover .btn-wrapper {
  bottom: -60px;
  opacity: 1;
  visibility: visible;
}
.ltn__feature-item-5.section-bg-2 .ltn__feature-icon {
  color: var(--ltn__secondary-color);
}
.ltn__feature-item-5.section-bg-2 .ltn__feature-icon i {
  color: var(--ltn__secondary-color);
}
.ltn__feature-item-5.section-bg-2 .ltn__feature-icon span::before {
  background-color: var(--white);
  color: var(--ltn__primary-color);
}
.ltn__feature-item-5.white-bg .ltn__feature-icon span::before {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.feature-btn a {
  display: block;
  padding: 15px 20px;
  background-color: #fff;
  text-align: center;
}

/* feature-item-6 */
.ltn__feature-item-6 {
  border: 1px solid var(--border-color-8);
  margin-bottom: 30px;
  padding: 40px 30px 35px;
}
.ltn__feature-item-6::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 4px;
  background-color: var(--ltn__secondary-color);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.ltn__feature-item-6 .ltn__feature-icon {
  color: var(--ltn__secondary-color);
}
.ltn__feature-item-6 .ltn__feature-icon i {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__feature-item-6 .ltn__feature-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.ltn__feature-item-6 .ltn__feature-info p + p {
  margin-top: 15px;
}
.ltn__feature-item-6 .ltn__feature-info .ltn__service-btn {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  color: var(--ltn__color-1);
  font-weight: 700;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 25px;
}
.ltn__feature-item-6 .ltn__feature-info .ltn__service-btn i {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__feature-item-6.active,
.ltn__feature-item-6:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__feature-item-6.active::before,
.ltn__feature-item-6:hover::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.ltn__feature-item-6.active .ltn__feature-info .ltn__service-btn,
.ltn__feature-item-6.active .ltn__feature-icon,
.ltn__feature-item-6:hover .ltn__feature-info .ltn__service-btn,
.ltn__feature-item-6:hover .ltn__feature-icon {
  color: var(--ltn__secondary-color);
}

/* feature-item-7 */
.ltn__feature-item-7 {
  background-color: var(--white);
  padding: 38px 30px 10px;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
  margin-bottom: 30px;
}
.ltn__feature-item-7 .ltn__feature-icon-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}
.ltn__feature-item-7 .ltn__feature-icon {
  margin-bottom: 0;
  margin-right: 20px;
  color: var(--ltn__secondary-color);
}
.ltn__feature-item-7 h3 {
  margin-bottom: 0;
}

.ltn__feature-item-7-color-white {
  background-color: transparent;
  border: 1px solid #203336;
}
.ltn__feature-item-7-color-white h3,
.ltn__feature-item-7-color-white p {
  color: var(--white);
}

/* feature-item-8 */
.ltn__feature-item-8 {
  padding: 40px 30px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__feature-item-8 .ltn__feature-icon {
  margin-right: 20px;
  font-size: 40px;
  width: 50px;
}
.ltn__feature-item-8 .ltn__feature-info h4 {
  margin-bottom: 5px;
}
.ltn__feature-item-8 .ltn__feature-info p {
  font-size: 15px;
}

.ltn__feature-item-box-wrap {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

.ltn__feature-item-box-wrap-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__feature-item-box-wrap-2 .ltn__feature-item-8 {
  min-width: 280px;
}

.ltn__border-between-column [class*="col"]::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 50%;
  width: 1px;
  background-color: var(--border-color-1);
}

.ltn__border-between-column [class*="col"]:first-child::before {
  display: none;
}

/* feature-item-9 */
.ltn__feature-item-9 {
  padding: 30px 25px 5px;
  border: 1px solid;
}
.ltn__feature-item-9 .ltn__feature-icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
  border-radius: 100%;
  margin-bottom: 0;
  margin-right: 20px;
  text-align: center;
  float: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 36px;
}
.ltn__feature-item-9 .ltn__feature-icon span {
  line-height: 1;
}
.ltn__feature-item-9 .ltn__feature-info {
  overflow: hidden;
}
.ltn__feature-item-9 h2 {
  margin-bottom: 5px;
}
.ltn__feature-item-9:hover {
  background-color: var(--ltn__secondary-color);
}

/* small mobile :320px. */
@media (max-width: 767px) {
  .ltn__feature-item-5 + .btn-wrapper {
    margin-bottom: 30px;
  }
  .ltn__feature-item-7 {
    padding: 38px 20px 10px;
  }
}

/* ----------------------------------------------------
    Countdown Area
---------------------------------------------------- */
.ltn__countdown {
  margin-top: 30px;
  display: inline-block;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  padding: 25px 40px 5px;
}
.ltn__countdown .single {
  display: inline-block;
  margin-right: 30px;
}
.ltn__countdown .single:last-child {
  margin-right: 0;
}

.ltn__countdown-2 {
  margin-top: 0;
  padding: 20px 30px 1px;
}
.ltn__countdown-2 .single {
  margin-right: 20px;
}
.ltn__countdown-2 .single h1 {
  font-size: 22px;
  margin-bottom: 5px;
}
.ltn__countdown-2 .single p {
  font-size: 14px;
}

.ltn__countdown-3 {
  margin-top: 0;
  padding: 20px 30px 1px;
  padding-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__countdown-3 .single {
  margin-right: 20px;
  text-align: center;
}
.ltn__countdown-3 .single h1 {
  font-size: 30px;
  margin-bottom: 5px;
  height: 100px;
  width: 100px;
  line-height: 100px;
  background-color: var(--white);
  border-radius: 100%;
  color: var(--ltn__heading-color);
}
.ltn__countdown-3 .single p {
  font-size: 20px;
  text-transform: uppercase;
}
.ltn__countdown-3 .btn-wrapper {
  margin-top: 20px;
}

.ltn__countdown-4 {
  margin-top: 0;
  padding: 20px 30px 1px;
  padding-left: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__countdown-4 .single {
  margin-right: 20px;
  text-align: center;
  width: 100px;
  background-color: var(--white);
  padding: 15px 10px;
}
.ltn__countdown-4 .single h1 {
  font-size: 30px;
  margin-bottom: 0;
}
.ltn__countdown-4 .single p {
  font-size: 18px;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .ltn__countdown-3 .single h1 {
    font-size: 20px;
    height: 50px;
    width: 50px;
    line-height: 50px;
  }
  .ltn__countdown-3 .single p {
    font-size: 14px;
  }
}

@media (min-width: 767px) {
  .ltn__countdown-1 {
    min-width: 380px;
  }
}

/* ----------------------------------------------------
    Blog Area
---------------------------------------------------- */
.ltn__blog-item {
  position: relative;
  margin-bottom: 30px;
}

.ltn__blog-img {
  position: relative;
}
.ltn__blog-img img {
  margin-bottom: 0;
}

.ltn__blog-likes {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.ltn__blog-likes ul {
  margin: 0;
  padding: 0;
}
.ltn__blog-likes li {
  list-style: none;
}
.ltn__blog-likes li a {
  height: 70px;
  width: 70px;
  font-size: 16px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--white);
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  color: var(--ltn__heading-color);
}
.ltn__blog-likes li a i {
  font-size: 18px;
  margin-bottom: 5px;
}
.ltn__blog-likes li a span {
  display: inline-block;
  line-height: 1;
  font-size: 12px;
}

.ltn__blog-brief {
  padding: 30px 0 30px;
}

.ltn__blog-title {
  margin-bottom: 20px;
  font-size: 22px;
  font-weight: 600;
}

.ltn__blog-meta {
  margin-bottom: 20px;
}
.ltn__blog-meta ul {
  padding: 0;
  margin: 0;
}
.ltn__blog-meta li {
  font-weight: 600;
  display: inline-block;
  margin-right: 25px;
  position: relative;
  font-size: 14px;
  margin-top: 0;
}
.ltn__blog-meta li:last-child {
  margin-right: 0;
}
.ltn__blog-meta li i {
  color: var(--ltn__secondary-color);
  margin-right: 5px;
}
.ltn__blog-meta li img {
  margin-bottom: 0;
}

.ltn__blog-tags a {
  margin-right: 10px;
  position: relative;
  display: inline-block;
}
.ltn__blog-tags a::before {
  position: absolute;
  content: ",";
  right: -3px;
}
.ltn__blog-tags a:last-child::before {
  display: none;
}

.ltn__blog-author img {
  border-radius: 100%;
  margin-right: 10px;
  max-width: 30px;
  display: inline-block;
}

.ltn__blog-btn {
  color: var(--ltn__heading-color);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.ltn__blog-category a {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  padding: 5px 15px 2px;
  margin-bottom: 5px;
  margin-right: 5px;
  display: inline-block;
  text-transform: uppercase;
}
.ltn__blog-category a:last-child {
  margin-right: 0;
}
.ltn__blog-category a:hover {
  background-color: var(--ltn__primary-color);
  color: var(--white);
}

.ltn__blog-meta-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__blog-meta-btn .ltn__blog-meta {
  margin-bottom: 0;
}

/* ltn__blog-item-1 */
.ltn__blog-item-1 .ltn__blog-img img {
  border-radius: 0px 0px 0px 35px;
}

.ltn__blog-item-1 .ltn__blog-meta ul li {
  position: relative;
}
.ltn__blog-item-1 .ltn__blog-meta ul li::before {
  position: absolute;
  content: "";
  width: 2px;
  height: 10px;
  top: 50%;
  background-color: var(--ltn__primary-color);
  right: -15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.2;
}
.ltn__blog-item-1 .ltn__blog-meta ul li:last-child::before {
  display: none;
}

/* ltn__blog-item-2 */
.ltn__blog-item-2 {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__blog-item-2 .ltn__blog-brief {
  padding: 30px 20px 30px;
}
.ltn__blog-item-2 .ltn__blog-meta li:before {
  height: 15px;
  width: 2px;
  top: 50%;
}
.ltn__blog-item-2 .ltn__blog-meta li:before::before {
  position: absolute;
  content: "";
  right: -15px;
  height: 15px;
  width: 2px;
  background-color: var(--ltn__heading-color);
  top: 50%;
  bottom: 6px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0.6;
}
.ltn__blog-item-2 .ltn__blog-meta li:before:last-child::before {
  display: none;
}
.ltn__blog-item-2 .ltn__blog-btn {
  opacity: 0;
  visibility: hidden;
  margin-top: -20px;
}
.ltn__blog-item-2:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__blog-item-2:hover .ltn__blog-btn {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}

/* ltn__blog-item-3 */
/* ltn__blog-item-4 */
.ltn__blog-item-3 .ltn__blog-brief,
.ltn__blog-item-4 .ltn__blog-brief {
  padding: 30px 30px 30px;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--white);
  position: relative;
}

.ltn__blog-item-3 .ltn__blog-meta,
.ltn__blog-item-4 .ltn__blog-meta {
  margin-bottom: 15px;
}

.ltn__blog-item-3 .ltn__blog-meta-btn,
.ltn__blog-item-4 .ltn__blog-meta-btn {
  border-top: 1px solid;
  border-color: var(--border-color-1);
  padding-top: 20px;
}
.ltn__blog-item-3 .ltn__blog-meta-btn .ltn__blog-meta,
.ltn__blog-item-4 .ltn__blog-meta-btn .ltn__blog-meta {
  margin-bottom: 0;
}

.ltn__blog-item-3 .ltn__blog-btn,
.ltn__blog-item-4 .ltn__blog-btn {
  font-size: 14px;
  font-weight: 600;
  color: var(--ltn__secondary-color);
  text-transform: uppercase;
}

/* ltn__blog-item-3 */
.ltn__blog-item-3 .ltn__blog-brief {
  width: calc(100% - 30px);
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
}

.ltn__blog-item-3 .ltn__blog-img + .ltn__blog-brief {
  margin-top: -50px;
}

.ltn__blog-item-3-normal .ltn__blog-item-3 {
  margin-bottom: 50px;
}
.ltn__blog-item-3-normal .ltn__blog-item-3 .ltn__blog-brief {
  width: calc(100%);
}
.ltn__blog-item-3-normal .ltn__blog-item-3 .ltn__blog-img + .ltn__blog-brief {
  margin-top: 0px;
}

.ltn__blog-item-3 .ltn__blog-img {
  overflow: hidden;
}
.ltn__blog-item-3 .ltn__blog-img img {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.ltn__blog-item-3:hover .ltn__blog-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* ltn__blog-item-4 */
.ltn__blog-item-4 {
  border: 2px solid var(--white-9);
  margin-top: 1px;
}
.ltn__blog-item-4 .ltn__blog-brief {
  margin-top: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__blog-item-4 p {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__blog-item-4::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 0%;
  background-color: var(--ltn__primary-color);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__blog-item-4:hover::before {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  visibility: visible;
}
.ltn__blog-item-4:hover .ltn__blog-brief {
  background-color: transparent;
}
.ltn__blog-item-4:hover .ltn__blog-meta,
.ltn__blog-item-4:hover .ltn__blog-title,
.ltn__blog-item-4:hover p {
  color: var(--white);
}

/* ltn__blog-item-5 */
.ltn__blog-item-5 {
  border: 2px solid var(--border-color-11);
}
.ltn__blog-item-5 .ltn__blog-brief {
  padding: 40px 30px 40px 30px;
}
.ltn__blog-item-5 .ltn__blog-meta-btn {
  padding-top: 10px;
}
.ltn__blog-item-5 .ltn__blog-author {
  font-size: 16px;
}
.ltn__blog-item-5 .ltn__blog-author img {
  max-width: 40px;
}
.ltn__blog-item-5 .ltn__blog-btn {
  font-size: 14px;
  font-weight: 700;
  font-family: var(--ltn__body-font);
}

/* blog-item-audio */
.ltn__blog-item-audio iframe {
  width: 100%;
  min-height: 300px;
}

/* blog-item-quote */
.ltn__blog-item-quote {
  border: 0;
}
.ltn__blog-item-quote .ltn__blog-meta li {
  color: var(--white);
}
.ltn__blog-item-quote .ltn__blog-meta li i {
  color: var(--white);
}
.ltn__blog-item-quote .ltn__blog-meta li:hover a {
  color: var(--white-3);
}
.ltn__blog-item-quote blockquote {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  background-color: transparent;
  font-style: normal;
  border-left: 0;
  border-color: var(--ltn__heading-color);
  padding: 60px 0 0;
  margin: 0px 0 30px 0px;
  color: var(--white);
  text-align: left;
}
.ltn__blog-item-quote blockquote::before {
  position: absolute;
  content: "\e94d";
  font-family: "icomoon";
  font-size: 30px;
  left: 0;
  right: 0;
  top: 0;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__blog-item-quote blockquote a:hover,
.ltn__blog-item-quote blockquote:hover {
  color: var(--white-3);
}

/* blog-item-6 */
.ltn__blog-item-6 {
  -webkit-box-shadow: var(--ltn__box-shadow-5);
  box-shadow: var(--ltn__box-shadow-5);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__blog-item-6:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__blog-item-6 .ltn__blog-brief {
  padding: 35px 30px 15px 35px;
}
.ltn__blog-item-6 .ltn__blog-title {
  font-size: 20px;
}
.ltn__blog-item-6 p {
  font-size: 14px;
}

/* blog-item-7 */
.ltn__blog-item-7 {
  -webkit-box-shadow: var(--ltn__box-shadow-5);
  box-shadow: var(--ltn__box-shadow-5);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__blog-item-7:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}
.ltn__blog-item-7 .ltn__blog-meta li {
  color: var(--ltn__secondary-color);
}
.ltn__blog-item-7 .ltn__blog-brief {
  padding: 30px 30px 30px 35px;
}
.ltn__blog-item-7 .ltn__blog-title {
  font-size: 18px;
  text-transform: uppercase;
}
.ltn__blog-item-7 p {
  font-size: 18px;
  line-height: 28px;
}
.ltn__blog-item-7 .ltn__blog-btn {
  font-size: 15px;
}

/* blog-list-wrap */
.ltn__blog-list-wrap .ltn__blog-item {
  margin-bottom: 40px;
}

.ltn__blog-list-wrap .ltn__blog-title {
  font-size: 30px;
  line-height: 1.2;
}

.ltn__blog-list-wrap .ltn__blog-item-5 .ltn__blog-brief {
  padding: 50px 40px 50px 45px;
}

.ltn__blog-list-wrap .ltn__blog-item-quote .ltn__blog-meta {
  padding-left: 100px;
}

.ltn__blog-list-wrap .ltn__blog-item-quote blockquote {
  font-size: 35px;
  line-height: 1.2;
  padding: 0 0 0 100px;
}
.ltn__blog-list-wrap .ltn__blog-item-quote blockquote::before {
  font-size: 80px;
  left: 0;
}
.ltn__blog-list-wrap .ltn__blog-item-quote blockquote a:hover,
.ltn__blog-list-wrap .ltn__blog-item-quote blockquote:hover {
  color: var(--white-3);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ltn__blog-list-wrap .ltn__blog-title {
    font-size: 26px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote blockquote {
    font-size: 24px;
    padding: 0 0 0 80px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote blockquote::before {
    font-size: 50px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote .ltn__blog-meta {
    padding-left: 80px;
  }
  .ltn__blog-item-6 .ltn__blog-title {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .ltn__blog-title {
    font-size: 20px;
  }
  .ltn__blog-item-3 .ltn__blog-brief {
    width: calc(100% - 30px);
  }
  .ltn__blog-list-wrap .ltn__blog-title {
    font-size: 26px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote blockquote {
    font-size: 24px;
    padding: 0 0 0 0px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote blockquote::before {
    font-size: 30px;
    display: block;
    position: relative;
    margin-bottom: 10px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote .ltn__blog-meta {
    padding-left: 0;
  }
}

@media (max-width: 767px) {
  .ltn__blog-title {
    font-size: 16px;
  }
  .ltn__blog-meta li {
    margin-right: 10px;
    font-size: 12px;
  }
  .ltn__blog-list-wrap .ltn__blog-title {
    font-size: 22px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-5 .ltn__blog-brief {
    padding: 40px 20px 35px 20px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote blockquote {
    font-size: 20px;
    padding: 0 0 0 0px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote blockquote::before {
    font-size: 30px;
    display: block;
    position: relative;
    margin-bottom: 10px;
  }
  .ltn__blog-list-wrap .ltn__blog-item-quote .ltn__blog-meta {
    padding-left: 0;
  }
  .ltn__blog-item-3 .ltn__blog-btn,
  .ltn__blog-item-4 .ltn__blog-btn {
    font-size: 12px;
  }
  .ltn__blog-item-6 .ltn__blog-title {
    font-size: 20px;
  }
  .ltn__blog-item-6 .ltn__blog-brief {
    padding: 35px 20px 15px 20px;
  }
}

/* ----------------------------------------------------
    Blog Details
---------------------------------------------------- */
.ltn__page-details-inner h1,
.ltn__page-details-inner h2,
.ltn__page-details-inner h3,
.ltn__page-details-inner h4,
.ltn__page-details-inner h5,
.ltn__page-details-inner h6 {
  margin-top: 30px;
}

.ltn__page-details-inner p {
  margin-top: 1.5em;
}

.ltn__page-details-inner .ltn__blog-title {
  margin-top: 0;
  font-size: 30px;
}

.ltn__page-details-inner .ltn__blog-img {
  margin-bottom: 40px;
}

.ltn__page-details-inner .img-radius img {
  border-radius: 0px 0px 0px 35px;
}

.ltn__page-details-inner label {
  font-size: 14px;
}

/* blog-details-wrap */
.ltn__blog-details-wrap {
  border: 2px solid var(--border-color-11);
  padding: 50px;
}

blockquote {
  font-size: 18px;
  font-family: var(--ltn__heading-font);
  line-height: 1.6;
  font-weight: 700;
  background-color: var(--section-bg-1);
  padding: 60px 50px 60px 60px;
  margin: 50px 0 50px 0px;
  font-style: normal;
  text-align: center;
  border-left: 0;
  position: relative;
  z-index: 1;
}
blockquote::before {
  position: absolute;
  content: "\e94d";
  font-family: "icomoon";
  font-size: 150px;
  right: 45px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--white);
  z-index: -1;
}

.ltn__comment-item {
  position: relative;
}
.ltn__comment-item p {
  font-size: 14px;
}

.ltn__commenter-img {
  float: left;
  margin-right: 30px;
  max-width: 100px;
}

.ltn__commenter-img img {
  border-radius: 100%;
}

.ltn__commenter-comment {
  overflow: hidden;
}

.ltn__commenter-comment h6 {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 600;
}

.ltn__commenter-comment .comment-date {
  margin-bottom: 10px;
  display: block;
  font-size: 13px;
  font-weight: 700;
  font-family: var(--ltn__heading-font);
  color: var(--ltn__secondary-color);
}

.ltn__comment-inner ul {
  margin: 0;
  padding: 0;
}

.ltn__comment-inner li {
  list-style: none;
  border-top: 1px solid var(--border-color-12);
  padding-top: 30px;
  margin-top: 8px;
}

.ltn__comment-inner > ul > li:first-child {
  border-top: 0;
  padding-top: 0;
  margin-top: 0;
}

.ltn__comment-inner ul ul {
  margin: 0 0 0 70px;
  padding: 0;
}

.ltn__comment-reply-btn {
  position: absolute;
  padding: 0 25px 0;
  border: 2px solid var(--border-color-11);
  display: inline-block;
  border-radius: 25px;
  font-size: 14px;
  font-weight: 700;
  height: 40px;
  line-height: 36px;
  top: 0;
  right: 0;
}
.ltn__comment-reply-btn i {
  margin-right: 5px;
}
.ltn__comment-reply-btn:hover {
  border-color: var(--ltn__secondary-color);
}

.ltn__comment-reply-area form {
  padding: 50px;
  background-color: var(--white-5);
}
.ltn__comment-reply-area form input[type="text"],
.ltn__comment-reply-area form input[type="email"],
.ltn__comment-reply-area form input[type="password"],
.ltn__comment-reply-area form input[type="submit"],
.ltn__comment-reply-area form textarea {
  border-color: var(--white-5);
}

.ltn__comment-inner .product-ratting ul {
  padding: 0;
  margin: 0;
}
.ltn__comment-inner .product-ratting ul li {
  padding: 0;
  border: 0;
}

.ltn__first-letter {
  font-size: 70px;
  font-weight: 700;
  float: left;
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  margin-right: 30px;
  line-height: 1;
  text-transform: uppercase;
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.ltn__blog-tags-social-media .ltn__social-media ul li {
  color: var(--ltn__color-1);
}

.ltn__prev-next-btn {
  position: relative;
}
.ltn__prev-next-btn::before {
  position: absolute;
  content: "\e958";
  font-family: "icomoon";
  left: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  font-size: 30px;
  color: var(--ltn__secondary-color);
}
.ltn__prev-next-btn h6 {
  color: var(--ltn__secondary-color);
}
.ltn__prev-next-btn .ltn__blog-title {
  font-size: 24px;
  margin-bottom: 0;
}

.ltn-author-introducing {
  padding: 40px;
  border: 2px solid var(--border-color-11);
  margin-bottom: 50px;
}
.ltn-author-introducing .author-img {
  float: left;
  max-width: 180px;
  margin-right: 40px;
}
.ltn-author-introducing .author-info {
  overflow: hidden;
}
.ltn-author-introducing .author-info h6 {
  margin-bottom: 0;
  font-size: 14px;
  color: var(--ltn__secondary-color);
}
.ltn-author-introducing .author-info h1,
.ltn-author-introducing .author-info h2,
.ltn-author-introducing .author-info h3 {
  margin-bottom: 10px;
}
.ltn-author-introducing .author-info p {
  font-size: 14px;
  margin-bottom: 0;
}
.ltn-author-introducing .author-info p + p {
  margin-top: 15px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ltn-author-introducing .author-img {
    max-width: 120px;
    margin-right: 30px;
  }
}

@media (max-width: 991px) {
  .ltn__blog-tags-social-media .ltn__social-media {
    text-align: left !important;
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .ltn__page-details-inner .ltn__blog-title {
    margin-top: 0;
    font-size: 24px;
  }
  .ltn__page-details-inner .ltn__blog-img {
    margin-bottom: 40px;
  }
  .ltn__blog-details-wrap {
    padding: 50px 20px;
  }
  blockquote {
    font-size: 16px;
    padding: 70px 20px 40px 20px;
  }
  blockquote::before {
    position: initial;
    font-size: 60px;
    display: block;
    line-height: 1;
  }
  .ltn__prev-next-btn .ltn__blog-title {
    font-size: 18px;
  }
  .blog-prev {
    margin-bottom: 40px;
  }
  .blog-next {
    margin-top: 40px;
    margin-bottom: 0;
  }
  .ltn-author-introducing {
    padding: 40px 20px;
  }
  .ltn-author-introducing .author-img {
    max-width: 100px;
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .ltn-author-introducing .author-info {
    overflow: inherit;
  }
  .ltn__commenter-img {
    float: inherit;
    margin-right: 0;
    max-width: 80px;
    margin-bottom: 20px;
  }
  .ltn__commenter-comment h6 {
    font-size: 18px;
  }
  .ltn__comment-reply-btn {
    position: inherit;
    font-size: 12px;
  }
  .ltn__comment-inner ul ul {
    margin: 0px;
  }
  .ltn__comment-inner li {
    margin-top: 30px;
  }
  .ltn__comment-reply-area form {
    padding: 50px 20px;
  }
  .ltn__first-letter {
    font-size: 40px;
    margin-right: 20px;
    width: 60px;
    height: 60px;
  }
}

/* ----------------------------------------------------
    Service Details
---------------------------------------------------- */
.ltn__service-list-menu ul {
  padding: 0;
}
.ltn__service-list-menu ul li {
  display: block;
  border-top: 2px solid #f6f6f6;
  padding: 18px 0;
  margin: 0;
  font-weight: 700;
  font-family: var(--ltn__heading-font);
}
.ltn__service-list-menu ul li:last-child {
  border-bottom: 2px solid #f6f6f6;
}
.ltn__service-list-menu ul li i {
  margin-right: 5px;
  color: var(--ltn__secondary-color);
}
.ltn__service-list-menu ul li .service-price {
  float: right;
  text-transform: capitalize;
  font-weight: 400;
  font-family: var(--ltn__body-font);
}

@media (max-width: 767px) {
  .ltn__service-list-menu ul li .service-price {
    float: none;
    display: block;
  }
}

/* ----------------------------------------------------
    Pagination
---------------------------------------------------- */
/* ltn__pagination-3 */
.ltn__pagination ul {
  margin: 0;
  padding: 0;
}
.ltn__pagination ul li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}
.ltn__pagination ul li:last-child {
  margin-right: 0px;
}
.ltn__pagination ul li a {
  height: 50px;
  width: 50px;
  line-height: 46px;
  border: 2px solid var(--border-color-11);
  text-align: center;
  display: block;
  font-weight: 700;
  border-radius: 100%;
}
.ltn__pagination ul li:hover a,
.ltn__pagination ul li.active a {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white);
}

@media (max-width: 767px) {
  .ltn__pagination ul li {
    margin-right: 5px;
    font-size: 14px;
  }
  .ltn__pagination ul li a {
    height: 40px;
    width: 40px;
    line-height: 36px;
  }
}

/* ----------------------------------------------------
    Testimonial ( 1, 2, 3, 4, 5, 6 )
---------------------------------------------------- */
.ltn__testimonial-item {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  margin-bottom: 50px;
}
.ltn__testimonial-item .ltn__testimoni-img img {
  border-radius: 100%;
}
.ltn__testimonial-item.text-center .ltn__testimoni-img {
  margin-left: auto;
  margin-right: auto;
}
.ltn__testimonial-item.text-right .ltn__testimoni-img {
  margin-left: auto;
  margin-right: 0;
}

.ltn__testimoni-img {
  max-width: 120px;
  margin-bottom: 30px;
}
.ltn__testimoni-img img {
  border-radius: 0;
}
.ltn__testimoni-img i {
  width: 50px;
  height: 50px;
  line-height: 48px;
  border: 1px solid;
  border-radius: 100%;
  font-size: 18px;
}

/* testimonial-item-2 */
.ltn__testimonial-item-2 {
  max-width: 60%;
}
.ltn__testimonial-item-2 .ltn__testimoni-img {
  max-width: 85px;
  outline: 8px solid;
  outline-color: var(--white);
  margin-top: 8px;
  position: relative;
}
.ltn__testimonial-item-2 .ltn__testimoni-img img {
  border-radius: 0;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
}
.ltn__testimonial-item-2 p {
  font-size: 24px;
}
.ltn__testimonial-item-2 .ltn__testimoni-info h4 {
  font-size: 30px;
  margin-bottom: 5px;
}
.ltn__testimonial-item-2 .ltn__testimoni-info h6 {
  color: var(--ltn__secondary-color);
}

/* testimonial-item-3 */
.ltn__testimonial-item-3 {
  max-width: 100%;
  margin-bottom: 20px;
}
.ltn__testimonial-item-3 .ltn__testimoni-info {
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  width: calc(100% - 40px);
  margin: -60px auto 0;
  background-color: var(--white);
  padding: 25px 30px 30px;
  position: relative;
  z-index: 9;
}
.ltn__testimonial-item-3 .ltn__testimoni-info p {
  margin-top: 0;
}
.ltn__testimonial-item-3 .ltn__testimoni-info h4 {
  margin-bottom: 5px;
  margin-top: 0;
}
.ltn__testimonial-item-3 .ltn__testimoni-info h6 {
  margin-bottom: 5px;
  margin-top: 0;
  color: var(--ltn__secondary-color);
}
.ltn__testimonial-item-3 .ltn__testimoni-info-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__testimonial-item-3 .ltn__testimoni-img {
  max-width: 60px;
  margin-bottom: 0;
  margin-right: 15px;
}
.ltn__testimonial-item-3 .ltn__testimoni-img img {
  border-radius: 0;
}

.ltn__testimoni-bg-icon {
  position: absolute;
  right: 15px;
  bottom: 5px;
  z-index: -1;
  opacity: 0.1;
}
.ltn__testimoni-bg-icon i {
  font-size: 110px;
  line-height: 1;
  color: var(--ltn__color-1);
}

/* testimonial-item-4 */
.ltn__testimonial-item-4 {
  max-width: 100%;
  position: relative;
  padding: 40px;
  background-color: var(--white);
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
  z-index: 2;
}
.ltn__testimonial-item-4 .ltn__testimoni-img {
  max-width: 160px;
  float: left;
  margin-right: 40px;
}
.ltn__testimonial-item-4 .ltn__testimoni-img img {
  border-radius: 0;
}
.ltn__testimonial-item-4 .ltn__testimoni-info {
  overflow: hidden;
}
.ltn__testimonial-item-4 .ltn__testimoni-info p {
  margin-bottom: 15px;
}
.ltn__testimonial-item-4 .ltn__testimoni-info h4 {
  margin-bottom: 5px;
}
.ltn__testimonial-item-4 .ltn__testimoni-info h6 {
  margin-bottom: 5px;
  color: var(--ltn__secondary-color);
}

/* testimonial-item-5 */
.ltn__testimonial-slider-4 {
  max-width: 45%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ltn__testimonial-item-5 .ltn__quote-icon {
  font-size: 240px;
  position: absolute;
  top: -15px;
  line-height: 1;
  color: var(--white-7);
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.ltn__testimonial-item-5 .ltn__testimonial-image {
  max-width: 85px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}
.ltn__testimonial-item-5 .ltn__testimonial-image img {
  outline: 8px solid #fff;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

.ltn__testimonial-item-5 .ltn__testimonial-info {
  margin-top: 30px;
}
.ltn__testimonial-item-5 .ltn__testimonial-info p {
  font-size: 24px;
}
.ltn__testimonial-item-5 .ltn__testimonial-info h4 {
  font-size: 30px;
  margin-bottom: 5px;
}
.ltn__testimonial-item-5 .ltn__testimonial-info h6 {
  color: var(--ltn__secondary-color);
}

.ltn__testimonial-quote-menu {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.ltn__testimonial-quote-menu li {
  list-style: none;
}
.ltn__testimonial-quote-menu li img {
  position: absolute;
}
.ltn__testimonial-quote-menu li:nth-child(1) img {
  width: 105px;
  top: -15%;
  left: 0;
}
.ltn__testimonial-quote-menu li:nth-child(2) img {
  width: 65px;
  top: 0;
  left: auto;
  right: 3%;
}
.ltn__testimonial-quote-menu li:nth-child(3) img {
  width: 65px;
  top: 70%;
  left: 10%;
}
.ltn__testimonial-quote-menu li:nth-child(4) img {
  width: 125px;
  top: 60%;
  left: auto;
  right: 10%;
}
.ltn__testimonial-quote-menu li:nth-child(5) img {
  width: 75px;
  top: 40%;
  left: 3%;
}
.ltn__testimonial-quote-menu li:nth-child(6) img {
  width: 75px;
  top: 25%;
  left: auto;
  right: 15%;
}
.ltn__testimonial-quote-menu li:nth-child(7) img {
  width: 55px;
  top: 20%;
  left: 15%;
}
.ltn__testimonial-quote-menu li:nth-child(8) img {
  width: 55px;
  top: 40%;
  left: auto;
  right: 3%;
}

/* testimonial-item-6 */
.ltn__testimonial-item-6 {
  padding-top: 50px;
  margin-bottom: 0;
}
.ltn__testimonial-item-6 .ltn__testimoni-img i {
  border: 0;
  color: var(--ltn__secondary-color);
}
.ltn__testimonial-item-6 p {
  font-size: 28px;
}

/* testimonial-item-7 */
.ltn__testimonial-item-7 {
  background-color: var(--white);
  padding: 30px 35px 40px;
  max-width: 100%;
  border-radius: 10px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border: 1px solid;
  border-color: var(--border-color-13);
  position: relative;
}
.ltn__testimonial-item-7 .ltn__testimonial-img {
  margin-bottom: 30px;
}
.ltn__testimonial-item-7 .ltn__testimoni-info p {
  font-size: 18px;
}
.ltn__testimonial-item-7 .ltn__testimoni-info p i {
  font-size: 30px;
  position: relative;
  top: 8px;
  margin-right: 2px;
  line-height: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__testimonial-item-7 .ltn__testimoni-info-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__testimonial-item-7 .ltn__testimoni-info-inner .ltn__testimoni-img {
  max-width: 60px;
  margin-right: 15px;
  margin-bottom: 0;
}
.ltn__testimonial-item-7
  .ltn__testimoni-info-inner
  .ltn__testimoni-name-designation
  h5 {
  font-weight: 600;
  margin-bottom: 0;
}
.ltn__testimonial-item-7
  .ltn__testimoni-info-inner
  .ltn__testimoni-name-designation
  label {
  font-size: 14px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.ltn__testimonial-item-7::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 0%;
  height: 4px;
  background-color: var(--ltn__secondary-color);
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.ltn__testimonial-item-7:hover::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.ltn__testimonial-item-7:hover .ltn__testimoni-info p i {
  color: var(--ltn__secondary-color);
}

/* testimonial-item-8 */
.ltn__testimonial-item-8 {
  padding: 35px 35px 35px;
}
.ltn__testimonial-item-8 .ltn__testimoni-info p {
  margin-bottom: 0;
}
.ltn__testimonial-item-8 .ltn__testimoni-info p + p {
  margin-top: 15px;
}

.ltn__testimoni-author-ratting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .ltn__testimonial-item-2 {
    max-width: 70%;
  }
  .ltn__testimonial-item-2 p {
    font-size: 20px;
  }
  .ltn__testimonial-item-2 .ltn__testimoni-info h4 {
    font-size: 24px;
  }
  .ltn__testimonial-slider-4 {
    max-width: 70%;
  }
  .ltn__testimonial-item-5 .ltn__testimonial-info p {
    font-size: 20px;
  }
  .quote-animated-image {
    display: none !important;
  }
  .ltn__testimoni-author-ratting {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-flow: column-reverse;
    flex-flow: column-reverse;
  }
  .ltn__testimonial-item-7 .ltn__testimoni-info p {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .ltn__testimonial-item-2 {
    max-width: 95%;
  }
  .ltn__testimonial-item-2 p {
    font-size: 16px;
  }
  .ltn__testimonial-item-2 .ltn__testimoni-info h4 {
    font-size: 20px;
  }
  .ltn__testimonial-item-3 .ltn__testimoni-info {
    width: calc(100% - 20px);
    padding: 25px 20px 30px;
  }
  .ltn__testimonial-item-3 .ltn__testimoni-info-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .ltn__testimonial-item-3 .ltn__testimoni-img {
    margin-bottom: 10px;
  }
  .ltn__testimonial-item-4 .ltn__testimoni-img {
    float: none;
  }
  .ltn__testimonial-slider-4 {
    max-width: 95%;
  }
  .ltn__testimonial-item-5 .ltn__testimonial-info p {
    font-size: 16px;
  }
  .ltn__testimonial-item-7 .ltn__testimoni-info p {
    font-size: 14px;
  }
  .ltn__testimonial-item-7 .ltn__testimoni-info-inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .ltn__testimonial-item-7 .ltn__testimoni-info-inner .ltn__testimoni-img {
    margin-bottom: 10px;
  }
}

/* ----------------------------------------------------
    Banner Area ( style: 2, 3 )
---------------------------------------------------- */
.ltn__banner-item {
  margin-bottom: 30px;
  position: relative;
}

.ltn__banner-img {
  overflow: hidden;
}
.ltn__banner-img img {
  -webkit-transition: all 3.5s ease 0s;
  -o-transition: all 3.5s ease 0s;
  transition: all 3.5s ease 0s;
}
.ltn__banner-img:hover img {
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.ltn__banner-info h4 {
  margin-bottom: 10px;
}

.ltn__banner-style-2 .ltn__banner-info {
  padding: 20px 30px;
  border: 1px solid;
  border-color: var(--border-color-1);
}

.ltn__banner-style-3 .ltn__banner-info {
  position: absolute;
  left: 35px;
  top: 30px;
}

.ltn__banner-style-3 .banner-button {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.ltn__banner-style-3 .banner-button a {
  color: var(--ltn__color-1);
  padding: 12px 25px;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
}
.ltn__banner-style-3 .banner-button a:hover {
  color: var(--white);
}

.ltn__banner-style-4 {
  position: relative;
  height: 350px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.ltn__banner-style-4 .ltn__banner-info {
  position: relative;
  padding: 30px 30px;
}
.ltn__banner-style-4 .ltn__banner-info h3 {
  margin-bottom: 10px;
}
.ltn__banner-style-4 .ltn__banner-info p {
  margin-bottom: 12px;
}
.ltn__banner-style-4 .ltn__banner-info mark {
  background-color: var(--ltn__secondary-color);
}
.ltn__banner-style-4::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background-color: var(--ltn__primary-color);
  opacity: 0.3;
}
.ltn__banner-style-4:hover::before {
  opacity: 0.15;
}

/* ----------------------------------------------------
    Team Area
---------------------------------------------------- */
.ltn__team-item {
  margin-bottom: 30px;
  border: 1px solid #f1f1f1;
  text-align: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.ltn__team-item:hover {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

.team-info {
  padding: 25px 15px;
}

/* team-item-2 */
.ltn__team-item-2 {
  position: relative;
}
.ltn__team-item-2:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--gradient-color-1);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__team-item-2 .team-info {
  position: absolute;
  width: 100%;
  top: 60%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__team-item-2:hover:before {
  opacity: 0.7;
  visibility: visible;
}
.ltn__team-item-2:hover .team-info {
  top: 50%;
  opacity: 1;
  visibility: visible;
}

/* team-item-3 */
.ltn__team-item-3 {
  padding: 45px 20px 25px;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
  border: 0;
  position: relative;
}
.ltn__team-item-3 .team-img {
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;
}
.ltn__team-item-3 .team-img img {
  border-radius: 100%;
}
.ltn__team-item-3 h6 {
  text-transform: uppercase;
}
.ltn__team-item-3 .team-info {
  padding: 0;
  margin-top: 30px;
}
.ltn__team-item-3 .ltn__social-media {
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: currentColor;
  width: 100%;
  padding: 10px 20px 20px;
  opacity: 0;
  visibility: hidden;
  border-bottom: 3px solid transparent;
}
.ltn__team-item-3:hover {
  background-color: var(--ltn__primary-color);
}
.ltn__team-item-3:hover h6,
.ltn__team-item-3:hover h4,
.ltn__team-item-3:hover .ltn__social-media ul li {
  color: var(--white);
}
.ltn__team-item-3:hover .ltn__social-media {
  bottom: -25px;
  opacity: 1;
  visibility: visible;
  z-index: 999;
  border-bottom-color: var(--ltn__secondary-color);
}

@media (max-width: 767px) {
  .ltn__team-item-3 {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* ----------------------------------------------------
    CounterUp Area
---------------------------------------------------- */
.ltn__counterup-item {
  margin-bottom: 50px;
  text-align: center;
}
.ltn__counterup-item .counter-icon {
  margin-bottom: 25px;
}
.ltn__counterup-item .counter-icon i {
  font-size: 65px;
  line-height: 1;
  color: var(--ltn__secondary-color);
}
.ltn__counterup-item h1 {
  font-weight: 700;
  font-size: 42px;
}
.ltn__counterup-item h6 {
  color: var(--ltn__color-5);
  font-family: var(--ltn__body-font);
}

/* counterup-item-2 */
.ltn__counterup-item-2 {
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ltn__counterup-item-2 .counter-icon {
  margin-bottom: 0;
  margin-right: 30px;
  text-align: center;
}
.ltn__counterup-item-2 .counter-icon i {
  font-size: 35px;
  height: 65px;
  width: 65px;
  line-height: 65px;
  border: 1px solid;
  border-radius: 5px;
  color: var(--ltn__color-1);
}
.ltn__counterup-item-2 h1,
.ltn__counterup-item-2 h6 {
  font-weight: 600;
  font-size: 36px;
  line-height: 1;
  margin-bottom: 10px;
}
.ltn__counterup-item-2 h6 {
  font-size: 20px;
}

/* counterup-item-3 */
.ltn__counterup-item-3 {
  margin-bottom: 50px;
}
.ltn__counterup-item-3 .counter-icon {
  color: var(--ltn__color-1);
}
.ltn__counterup-item-3 h1 {
  font-size: 72px;
  line-height: 1;
  margin-bottom: 5px;
}
.ltn__counterup-item-3 .counterUp-icon {
  font-size: 30px;
}
.ltn__counterup-item-3 h6 {
  font-family: var(--ltn__body-font);
}

@media (max-width: 1199px) {
  .ltn__counterup-item-3 h1 {
    font-size: 56px;
  }
}

@media (max-width: 991px) {
  .ltn__counterup-item h1 {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .ltn__counterup-area .ltn__section-title-2 {
    text-align: center;
  }
  .ltn__counterup-item h1 {
    font-size: 30px;
  }
  .ltn__counterup-item-3 {
    text-align: center;
  }
  .ltn__counterup-item-3 h1 {
    font-size: 56px;
  }
}

/* ----------------------------------------------------
    Contact Form Area
---------------------------------------------------- */
.ltn__contact-address-item {
  padding: 40px 30px 10px;
  text-align: center;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
  margin-bottom: 30px;
}
.ltn__contact-address-item i {
  background-color: transparent;
  color: var(--ltn__heading-color);
  height: 60px;
  width: 60px;
  line-height: 60px;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
  text-align: center;
  border-radius: 100%;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__contact-address-item:hover i {
  background-color: var(--ltn__primary-color);
  color: var(--white);
}

.ltn__contact-address-item-2 {
  padding: 30px 0 10px;
  margin-bottom: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__contact-address-item-2 + .ltn__contact-address-item-2 {
  border-top: 1px solid;
}

.contact-form-box {
  padding: 40px 50px 50px;
  position: relative;
  z-index: 1;
}
.contact-form-box input::-webkit-input-placeholder {
  color: var(--ltn__color-1);
}
.contact-form-box input::-moz-placeholder {
  color: var(--ltn__color-1);
}
.contact-form-box input:-ms-input-placeholder {
  color: var(--ltn__color-1);
}
.contact-form-box input:-moz-placeholder {
  color: var(--ltn__color-1);
}

.contact-form-box-2 input,
.contact-form-box-2 textarea {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  padding-left: 0;
}

.google-map {
  height: 800px;
}

.ltn__contact-address-item-3 {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid var(--border-color-11);
  padding: 50px 30px 25px;
}
.ltn__contact-address-item-3 i {
  font-size: 50px;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ltn__contact-address-item-3 .ltn__contact-address-icon {
  margin-bottom: 35px;
}
.ltn__contact-address-item-3 .ltn__contact-address-icon img {
  max-width: 80px;
}
.ltn__contact-address-item-3:hover i {
  background-color: transparent;
  color: var(--ltn__secondary-color);
}

.form-messege .error {
  color: var(--red);
}

.form-messege .success {
  color: var(--green);
}

@media (max-width: 767px) {
  .contact-form-box {
    padding: 40px 25px 50px;
  }
}

/* ----------------------------------------------------
    Cart Table Area
---------------------------------------------------- */
.table-1 table {
  border: 0;
  text-transform: uppercase;
  font-size: 13px;
  width: 100%;
}
.table-1 table tr:first-child {
  font-weight: 700;
  text-transform: uppercase;
  background-color: #e5e5e6;
}
.table-1 table tr:nth-child(odd) {
  background-color: #e5e5e6;
}
.table-1 table tr:nth-of-type(even) {
  background: #f9f9f9;
}
.table-1 table tr th {
  font-weight: 700;
  text-transform: uppercase;
  background-color: #e5e5e6;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding: 10px 0;
  display: table-cell;
  line-height: 18px;
  text-align: center;
  width: 12%;
}
.table-1 table tr td {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding: 10px 0;
  display: table-cell;
  line-height: 18px;
  text-align: center;
}

.mobile-show {
  display: none;
}

@media only screen and (max-width: 767px) {
  .mobile-none {
    display: none;
  }
  .mobile-show {
    display: block;
  }
}

/* ----------------------------------------------------
    Cart plus minus
---------------------------------------------------- */
.cart-plus-minus {
  border: 2px solid;
  height: 63px;
  line-height: 56px;
  width: 140px;
  text-align: center;
}

.qtybutton {
  height: 100%;
  width: 30%;
}

.dec.qtybutton {
  float: left;
  border-right: 2px solid;
}

.inc.qtybutton {
  float: right;
  border-left: 2px solid;
}

.cart-plus-minus,
.dec.qtybutton,
.inc.qtybutton {
  background-color: var(--white);
  border-color: var(--border-color-11);
  font-size: 20px;
  font-weight: 700;
}

input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  height: 100%;
  margin-bottom: 0;
  padding: 0;
  text-align: center;
  width: 40%;
  font-weight: 700;
}

/* ----------------------------------------------------
    Product Details
---------------------------------------------------- */
.product-details-content .product-title {
  font-size: 36px;
  font-weight: 700;
}

.product-details-content .product-price {
  font-size: 24px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.product-details-content .product-excerpt {
  margin-bottom: 30px;
}

.product-details-content .cart-plus-minus {
  display: inline-block;
  margin-right: 20px;
}

.product-details-content .product-details-cart-btn {
  display: inline-block;
  margin-top: 0;
}

.product-details-content .product-details-buy-btn .theme-btn-1 {
  padding: 10px 100px 9px;
}

/* ----------------------------------------------------
    Shoping Cart
---------------------------------------------------- */
.table tr:nth-child(odd) {
  background-color: #f7f8fa;
}

.table tr:nth-child(even) {
  background-color: #fafafa;
}

.shoping-cart-table tbody {
  border-bottom: 1px solid #dee2e6;
}

.shoping-cart-table thead th {
  vertical-align: middle;
  border-bottom: 0;
  background-color: #f7f8fa;
}

.shoping-cart-table .table tr {
  background-color: transparent;
  display: table;
  width: 100%;
}

.shoping-cart-table td {
  padding: 20px 25px;
  vertical-align: middle;
}

.cart-product-image img {
  max-width: 100px;
}

.cart-product-remove {
  cursor: pointer;
}

.cart-product-remove:hover {
  color: var(--ltn__secondary-color);
}

.cart-product-subtotal {
  font-weight: 700;
}

.cart-coupon-row {
  background-color: #f7f8fa;
  text-align: right;
}

.cart-coupon {
  text-align: left;
}

.cart-coupon input {
  max-width: 250px;
  margin-bottom: 0;
}

.submit-button-1 {
  padding: 10px 30px;
  background-color: var(--ltn__primary-color);
  color: var(--white);
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.submit-button-1:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.btn.disabled {
  cursor: not-allowed;
}

.shoping-cart-total {
  float: right;
  max-width: 450px;
  width: 100%;
}
.shoping-cart-total .table {
  margin-bottom: 0;
}
.shoping-cart-total .btn-wrapper {
  margin-top: 0;
}
.shoping-cart-total .btn-wrapper .theme-btn-1 {
  display: block;
}

@media (min-width: 768px) and (max-width: 991px) {
  .shoping-cart-table td {
    padding: 20px 10px;
  }
  .cart-product-image img {
    max-width: 80px;
  }
  .cart-product-info h4 {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .shoping-cart-table thead th {
    display: none;
  }
  .shoping-cart-table .table tr {
    display: block;
    text-align: center;
  }
  .shoping-cart-table td {
    display: block;
    padding: 20px 15px;
  }
  .cart-product-image {
    max-width: 100%;
  }
  .shoping-cart-table .cart-plus-minus {
    margin-left: auto;
    margin-right: auto;
  }
  .cart-coupon input {
    margin-bottom: 20px;
  }
  .shoping-cart-table .table tr:nth-child(even) {
    background-color: var(--section-bg-1);
  }
}

/* ----------------------------------------------------
    Custom Content
---------------------------------------------------- */
.custom-content-brief h1 {
  margin-bottom: 30px;
}

.custom-content-brief .btn-wrapper {
  margin-top: 40px;
}

/* ----------------------------------------------------
    Newsletter
---------------------------------------------------- */
.ltn__newsletter-inner .ltn__form-box {
  position: relative;
  margin-top: 30px;
}
.ltn__newsletter-inner .ltn__form-box input {
  margin-bottom: 0;
  padding-right: 140px;
}
.ltn__newsletter-inner .ltn__form-box button {
  margin-top: 0;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  height: 100%;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ltn__newsletter-inner-2 .ltn__form-box input {
  border-width: 0px 0px 1px 0px;
  padding-right: 140px;
}

.ltn__newsletter-inner-3 .ltn__form-box input {
  border-radius: 50px;
  padding-left: 30px;
}

/* ----------------------------------------------------
    Faq Area
---------------------------------------------------- */
.ltn__faq-inner .card {
  border: none;
  border-radius: 0;
  margin-bottom: 35px;
}
.ltn__faq-inner .card:last-child {
  margin-bottom: 0;
}
.ltn__faq-inner .card .ltn__card-title {
  background-color: var(--section-bg-1);
  padding: 22px 15px 22px 40px;
  cursor: pointer;
  position: relative;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 18px;
  margin-bottom: 0;
}
.ltn__faq-inner .card .ltn__card-title::before {
  position: absolute;
  content: "\e903";
  font-family: "icomoon";
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
}
.ltn__faq-inner .card .ltn__card-title[aria-expanded="true"] {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}
.ltn__faq-inner .card .ltn__card-title[aria-expanded="true"]:before {
  content: "\e904";
}
.ltn__faq-inner .card .card-body {
  padding: 20px 0 0 0;
}

/* ltn__faq-inner-2 */
.ltn__faq-inner-2 .card {
  margin: 0 0 -2px 0;
  border: 2px solid;
  border-color: var(--border-color-10);
}
.ltn__faq-inner-2 .card .ltn__card-title {
  background-color: transparent;
  padding: 20px 60px 20px 40px;
}
.ltn__faq-inner-2 .card .ltn__card-title::before {
  background-color: var(--section-bg-1);
  color: var(--ltn__primary-color);
  font-size: 16px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
}
.ltn__faq-inner-2 .card .ltn__card-title[aria-expanded="true"] {
  background-color: transparent;
  color: var(--ltn__primary-color);
}
.ltn__faq-inner-2 .card .ltn__card-title[aria-expanded="true"]:before {
  color: var(--ltn__secondary-color);
}
.ltn__faq-inner-2 .card .card-body {
  padding: 15px 40px 25px;
}

.ltn__faq-inner-3 .card {
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
}
.ltn__faq-inner-3 .card .ltn__card-title {
  padding: 20px 60px 20px 5px;
  font-weight: 600;
}
.ltn__faq-inner-3 .card .ltn__card-title i {
  width: 50px;
  height: 50px;
  line-height: 55px;
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  font-size: 26px;
}

@media (max-width: 767px) {
  .ltn__faq-inner-2 .card .ltn__card-title {
    padding: 20px 60px 20px 20px;
    font-size: 16px;
  }
  .ltn__faq-inner-2 .card .card-body {
    padding: 15px 20px 25px;
  }
  .ltn__faq-inner-3 .card .ltn__card-title {
    font-size: 14px;
  }
  .ltn__faq-inner-3 .card .ltn__card-title i {
    width: 40px;
    height: 40px;
    line-height: 45px;
    font-size: 20px;
  }
}

/* ----------------------------------------------------
    404 Area
---------------------------------------------------- */
.error-404-inner .btn-wrapper {
  margin-top: 50px;
}

.error-404-title {
  font-size: 120px;
  line-height: 1;
  margin-bottom: 40px;
}

/* 404 area 1 */
.ltn__404-area-1 .error-404-title {
  font-size: 400px;
  line-height: 1;
  color: var(--ltn__secondary-color);
  margin-bottom: 0;
}

.ltn__404-area-1 h2 {
  font-size: 60px;
}

/* 404 area 2 */
.ltn__404-area-2 {
  min-height: 90vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__404-area-2 .error-404-inner {
  padding: 150px 0 170px;
}

@media (max-width: 1199px) {
  .ltn__404-area-1 .error-404-title {
    font-size: 200px;
  }
  .ltn__404-area-1 h2 {
    font-size: 30px;
  }
}

@media (max-width: 991px) {
  .ltn__404-area-2 .error-404-inner {
    padding: 80px 0 110px;
  }
  .error-404-inner p br {
    display: none;
  }
  .error-404-title {
    font-size: 80px;
  }
  .ltn__404-area-1 .error-404-title {
    font-size: 100px;
  }
  .ltn__404-area-1 h2 {
    font-size: 20px;
  }
}

/* ----------------------------------------------------
    Coming Soon Area
---------------------------------------------------- */
.ltn__coming-soon-area {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.coming-soon-inner {
  padding: 120px 0 100px;
}
.coming-soon-inner .ltn__countdown {
  margin-top: 0;
}
.coming-soon-inner .ltn__countdown .single {
  border: 1px solid var(--border-color-3);
  margin-right: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  min-width: 120px;
  padding-top: 15px;
}
.coming-soon-inner .btn-wrapper {
  margin-top: 50px;
}
.coming-soon-inner p {
  max-width: 380px;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon-inner .ltn__form-box {
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
}
.coming-soon-inner .ltn__form-box input {
  background-color: transparent;
  color: var(--white);
  border: 1px solid var(--border-color-3);
}
.coming-soon-inner .ltn__form-box input[type="text"]::-webkit-input-placeholder,
.coming-soon-inner
  .ltn__form-box
  input[type="email"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--white);
}

@media (min-width: 767px) and (max-width: 991px) {
  .coming-soon-inner .ltn__countdown .single {
    min-width: 110px;
  }
}

@media (max-width: 991px) {
  .coming-soon-inner {
    padding: 80px 0 110px;
  }
  .coming-soon-inner p br {
    display: none;
  }
}

@media (max-width: 767px) {
  .coming-soon-inner .ltn__countdown {
    padding: 25px 20px 5px;
  }
  .coming-soon-inner .ltn__countdown .single {
    margin-right: 20px;
    min-width: 90px;
  }
}

/* ----------------------------------------------------
    Screenshot Area
---------------------------------------------------- */
/* img-slide-item-1 */
.ltn__img-slide-item-1 {
  margin: 10px 0 20px 0;
  padding: 0 0;
}

.slick-current .ltn__img-slide-item-1 {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
}

/* img-slide-item-2 */
.ltn__img-slide-item-2 {
  margin-bottom: 30px;
}

/* img-slide-item-3 */
.ltn__img-slide-item-3 {
  position: relative;
  margin-bottom: 85px;
}
.ltn__img-slide-item-3 .ltn__img-slide-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 35px;
  background-color: white;
  position: absolute;
  bottom: 0;
  width: calc(100% - 60px);
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
}
.ltn__img-slide-item-3 .ltn__img-slide-info-brief {
  padding-right: 20px;
}
.ltn__img-slide-item-3 h6 {
  margin-bottom: 5px;
  color: var(--ltn__secondary-color);
}
.ltn__img-slide-item-3 h1 {
  margin-bottom: 0;
  font-size: 30px;
}
.ltn__img-slide-item-3 .btn-wrapper {
  margin-top: 0;
}
.ltn__img-slide-item-3 .btn-wrapper .btn {
  padding: 15px 20px 13px;
}
.ltn__img-slide-item-3:hover .ltn__img-slide-info {
  bottom: -65px;
  opacity: 1;
  visibility: visible;
}

.slick-current .ltn__img-slide-item-3 .ltn__img-slide-info {
  bottom: -65px;
  opacity: 1;
  visibility: visible;
}

.ltn__img-slide-item-4 {
  position: relative;
  margin-bottom: 30px;
}
.ltn__img-slide-item-4 .ltn__img-slide-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 35px;
  position: absolute;
  bottom: 5px;
  width: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  background: var(--gradient-color-3);
  opacity: 0;
  visibility: hidden;
}
.ltn__img-slide-item-4 .ltn__img-slide-info-brief {
  padding-right: 20px;
}
.ltn__img-slide-item-4 h6 {
  margin-bottom: 5px;
  color: var(--white);
  font-weight: 400;
}
.ltn__img-slide-item-4 h1 {
  margin-bottom: 0;
  font-size: 26px;
  color: var(--white);
  font-weight: 500;
}
.ltn__img-slide-item-4 .btn-wrapper {
  margin-top: 0;
}
.ltn__img-slide-item-4 .btn-wrapper .btn {
  padding: 15px 20px 13px;
}
.ltn__img-slide-item-4:hover .ltn__img-slide-info {
  bottom: 0;
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1399px) {
  .ltn__img-slide-item-3 .ltn__img-slide-info {
    padding: 30px 20px;
    width: calc(100% - 30px);
  }
  .ltn__img-slide-item-3 .ltn__img-slide-info-brief {
    padding-right: 15px;
  }
  .ltn__img-slide-item-3 h1 {
    font-size: 18px;
  }
  .ltn__img-slide-item-3 .btn-wrapper .btn {
    padding: 10px 10px 8px;
  }
  .ltn__img-slide-item-4 .ltn__img-slide-info {
    padding: 30px 20px;
  }
  .ltn__img-slide-item-4 h1 {
    font-size: 18px;
  }
  .ltn__img-slide-item-4 .btn-wrapper .btn {
    padding: 10px 10px 8px;
  }
}

/* ----------------------------------------------------
    Pricing List Area
---------------------------------------------------- */
.ltn__pricing-plan-item {
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  overflow: hidden;
}
.ltn__pricing-plan-item .pricing-badge {
  color: var(--white);
  background-color: var(--ltn__secondary-color);
  padding: 4px 20px;
  text-transform: uppercase;
  font-size: 8px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  display: table;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 18px;
  position: absolute;
  right: -28px;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.ltn__pricing-plan-item .pricing-title {
  margin: 0;
  height: 100px;
  line-height: 100px;
}
.ltn__pricing-plan-item .pricing-price {
  background-color: var(--section-bg-1);
  height: 85px;
  line-height: 85px;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__pricing-plan-item .pricing-price h2 {
  margin-bottom: 0;
  line-height: inherit;
  font-size: 48px;
}
.ltn__pricing-plan-item .pricing-price sup,
.ltn__pricing-plan-item .pricing-price sub {
  font-size: 24px;
}
.ltn__pricing-plan-item .pricing-price sup {
  top: -0.9em;
}
.ltn__pricing-plan-item .pricing-price sub {
  bottom: -0.2em;
}
.ltn__pricing-plan-item ul {
  margin: 35px 0;
  padding: 0;
}
.ltn__pricing-plan-item ul li {
  list-style: none;
  font-size: 18px;
}
.ltn__pricing-plan-item .btn-wrapper {
  padding-bottom: 50px;
}
.ltn__pricing-plan-item.active-price,
.ltn__pricing-plan-item.active {
  -webkit-box-shadow: var(--ltn__box-shadow-6);
  box-shadow: var(--ltn__box-shadow-6);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  overflow: hidden;
  position: relative;
}
.ltn__pricing-plan-item.active-price .pricing-price h2,
.ltn__pricing-plan-item.active .pricing-price h2 {
  color: var(--ltn__secondary-color);
}
.ltn__pricing-plan-item.active-price .btn,
.ltn__pricing-plan-item.active .btn {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

@media (max-width: 767px) {
  .ltn__pricing-plan-item.active-price,
  .ltn__pricing-plan-item.active {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

/* ----------------------------------------------------
    Checkbox
---------------------------------------------------- */
/* checkbox-item */
.checkbox-item {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkbox-item .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: var(--white);
  border: 1px solid;
  border-color: #93959e;
}

/* On mouse-over, add a grey background color */
.checkbox-item:hover input ~ .checkmark {
  background-color: var(--ltn__secondary-color);
}

/* When the checkbox is checked, add a blue background */
.checkbox-item input:checked ~ .checkmark {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkbox-item .checkmark:after {
  position: absolute;
  content: "";
  display: none;
}

/* Show the checkmark when checked */
.checkbox-item input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-item .checkmark:after {
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* ----------------------------------------------------
    Body Sidebar Icons
---------------------------------------------------- */
.body-sidebar-icons {
  position: fixed;
  top: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 9;
  -webkit-transition: -webkit-transform 0.3s ease 1s;
  transition: -webkit-transform 0.3s ease 1s;
  -o-transition: transform 0.3s ease 1s;
  transition: transform 0.3s ease 1s;
  transition: transform 0.3s ease 1s, -webkit-transform 0.3s ease 1s;
  transition: transform 0.3s ease 1s, -webkit-transform 0.3s ease 1s;
  right: 0;
  -webkit-transform: translate3d(100%, -50%, 0);
  transform: translate3d(100%, -50%, 0);
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}
.body-sidebar-icons a {
  background-color: #ddd;
  text-align: center;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: auto;
  height: 40px;
  min-width: 40px;
  margin: 0;
  overflow: hidden;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.body-sidebar-icons a i {
  width: 40px;
  line-height: 40px;
  font-size: 14px;
  vertical-align: middle;
}
.body-sidebar-icons a .icon-name {
  white-space: nowrap;
  max-width: 0;
  padding: 0;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: padding 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.15),
    max-width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.15);
  -o-transition: padding 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.15),
    max-width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.15);
  transition: padding 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.15),
    max-width 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.15);
}
.body-sidebar-icons a.facebook-icon {
  background-color: var(--facebook);
}
.body-sidebar-icons a.twitter-icon {
  background-color: var(--twitter);
}
.body-sidebar-icons a.pinterest-icon {
  background-color: var(--pinterest);
}
.body-sidebar-icons a.instagram-icon {
  background-color: var(--instagram);
}
.body-sidebar-icons a.dribbble-icon {
  background-color: var(--dribbble);
}
.body-sidebar-icons a.behance-icon {
  background-color: var(--behance);
}
.body-sidebar-icons a.google-plus-icon {
  background-color: var(--google-plus);
}
.body-sidebar-icons a.linkedin-icon {
  background-color: var(--linkedin);
}
.body-sidebar-icons a.youtube-icon {
  background-color: var(--youtube);
}
.body-sidebar-icons a.vk-icon {
  background-color: var(--vk);
}
.body-sidebar-icons a.wechat-icon {
  background-color: var(--wechat);
}
.body-sidebar-icons a.email-icon {
  background-color: var(--email);
}
.body-sidebar-icons a:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.body-sidebar-icons a:hover .icon-name {
  padding-left: 15px;
  max-width: 220px;
}
.body-sidebar-icons.left-side {
  left: 0;
  right: auto;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.body-sidebar-icons.left-side a {
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
  -ms-flex-direction: unset;
  flex-direction: unset;
}
.body-sidebar-icons.left-side a:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.body-sidebar-icons.left-side a:hover .icon-name {
  padding-right: 15px;
  padding-left: 0;
}

/* ----------------------------------------------------
    About Us Area
---------------------------------------------------- */
.about-us-img-wrap {
  position: relative;
}
.about-us-img-wrap img {
  margin: 0;
}

.about-us-img-info {
  width: 310px;
  height: 310px;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
  position: absolute;
  top: 70%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
}
.about-us-img-info h1 {
  margin: 0;
  font-size: 120px;
  line-height: 0.8;
  color: var(--ltn__secondary-color);
}
.about-us-img-info h1 .counter {
  font-size: 120px;
  line-height: 0.8;
  color: var(--ltn__secondary-color);
}
.about-us-img-info h1 span {
  font-size: 20px;
  color: var(--ltn__heading-color);
}
.about-us-img-info h6 {
  margin: 0;
}
.about-us-img-info h6 span {
  font-size: 20px;
  color: var(--ltn__secondary-color);
}
.about-us-img-info .btn-wrapper {
  margin-top: 50px;
}
.about-us-img-info .dots-bottom {
  height: 25px;
  width: 25px;
  background-color: var(--ltn__secondary-color);
  display: inline-block;
  position: absolute;
  bottom: -10px;
  left: 12px;
}

.about-us-img-info-2 {
  width: 190px;
  height: 190px;
  background-color: var(--ltn__secondary-color);
  border: 15px solid;
  border-color: var(--white);
  bottom: 0;
  top: auto;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  margin-right: 0;
}
.about-us-img-info-2 h1 {
  font-size: 50px;
  color: var(--white);
}
.about-us-img-info-2 h1 .counter {
  font-size: 50px;
  color: var(--white);
}
.about-us-img-info-2 h1 span {
  font-size: 20px;
  color: var(--white);
}
.about-us-img-info-2 h6 {
  color: var(--white);
}

.about-us-img-info-3 {
  right: auto;
  left: 10px;
  border: 0;
  height: inherit;
  width: inherit;
  max-width: 300px;
  bottom: 70px;
  background-color: transparent;
}
.about-us-img-info-3 .ltn__video-img::before {
  opacity: 0.1;
}

.about-us-info-wrap {
  position: relative;
}
.about-us-info-wrap .btn-wrapper {
  margin-top: 40px;
}
.about-us-info-wrap hr {
  margin-top: 40px;
  margin-bottom: 40px;
}
.about-us-info-wrap .ltn__video-play-btn {
  width: 80px;
  height: 80px;
  line-height: 84px;
  font-size: 20px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -100px;
}

.about-us-info-devide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.about-us-info-devide .list-item-with-icon {
  margin-left: 30px;
  margin-bottom: 30px;
}

.list-item-with-icon {
  min-width: 300px;
}

.about-us-img-info-inner {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
}

.list-item-with-icon-2 ul,
.list-item-with-icon ul {
  padding: 0;
  margin: 0;
}
.list-item-with-icon-2 ul li,
.list-item-with-icon ul li {
  position: relative;
  list-style: none;
  padding-left: 60px;
  font-weight: 700;
  margin-bottom: 25px;
}
.list-item-with-icon-2 ul li:last-child,
.list-item-with-icon ul li:last-child {
  margin-bottom: 0;
}
.list-item-with-icon-2 ul li::before,
.list-item-with-icon ul li::before {
  position: absolute;
  content: "\f00c";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  left: 0;
  top: -5px;
  background-color: var(--section-bg-1);
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 12px;
  color: var(--ltn__secondary-color);
}

.list-item-with-icon-2 ul li {
  padding-left: 30px;
  font-weight: 400;
  margin-bottom: 0;
}
.list-item-with-icon-2 ul li::before {
  height: inherit;
  line-height: inherit;
  width: inherit;
  background-color: transparent;
  color: var(--ltn__heading-color);
  top: 5px;
}

.about-img-left {
  margin-right: 30px;
}

.about-img-right {
  margin-left: 30px;
}

.ltn__img-shape-left,
.ltn__img-shape-right {
  position: relative;
}
.ltn__img-shape-left::before,
.ltn__img-shape-right::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 85%;
  width: 30px;
  background-color: var(--ltn__secondary-color);
}

.ltn__img-shape-left {
  padding-left: 30px;
}
.ltn__img-shape-left::before {
  left: 0;
}

.ltn__img-shape-right {
  padding-right: 30px;
}
.ltn__img-shape-right::before {
  left: auto;
  right: 0;
}

.about-call-us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.about-call-us .call-us-icon {
  font-size: 50px;
  margin-right: 20px;
  color: var(--ltn__secondary-color);
  line-height: 1;
}
.about-call-us .call-us-info p {
  margin-bottom: 10px;
}
.about-call-us .call-us-info h2 {
  margin-bottom: 0px;
}

.ltn__list-item-half {
  margin-top: 0;
  padding-left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.ltn__list-item-half li {
  width: 50%;
  float: left;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-right: 20px;
}
.ltn__list-item-half li img {
  max-width: 50px;
  margin-right: 10px;
}
.ltn__list-item-half li i {
  font-size: 20px;
  color: var(--ltn__secondary-color);
  margin-right: 15px;
  height: 45px;
  width: 45px;
  line-height: 45px;
  background-color: var(--ltn__color-6);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 45px;
  flex: 0 0 45px;
  border-radius: 100%;
}

.ltn__list-item-half-2 li i {
  font-size: 14px;
  height: 32px;
  width: 32px;
  line-height: 32px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32px;
  flex: 0 0 32px;
}

.ltn__list-item-1 {
  padding-left: 0;
}
.ltn__list-item-1 li {
  list-style: none;
}

.ltn__list-item-1-before {
  padding-left: 30px;
}
.ltn__list-item-1-before li {
  position: relative;
}
.ltn__list-item-1-before li::before {
  position: absolute;
  content: "";
  left: -30px;
  width: 15px;
  height: 1px;
  background-color: var(--ltn__secondary-color);
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.ltn__list-item-2 {
  padding-left: 0;
}
.ltn__list-item-2 li {
  list-style: none;
  display: inline-block;
  position: relative;
  margin-right: 30px;
  max-width: 160px;
  font-size: 14px;
}
.ltn__list-item-2 li span {
  display: block;
  font-weight: 700;
}
.ltn__list-item-2 li:last-child {
  margin-right: 0;
}

.ltn__list-item-2-before li {
  position: relative;
}
.ltn__list-item-2-before li::before {
  position: absolute;
  content: "";
  right: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 1px;
  height: 100%;
  background-color: var(--ltn__primary-color);
  opacity: 0.2;
}
.ltn__list-item-2-before li:last-child::before {
  display: none;
}

.ltn__list-item-2-img li {
  margin-right: 10px;
  width: 30%;
}

.ltn__callout {
  padding: 20px;
  margin: 25px 0;
  border-left: 4px solid;
  border-color: var(--ltn__secondary-color);
}
.ltn__callout p {
  margin-bottom: 0;
}
.ltn__callout p + p {
  margin-top: 20px;
}

.list-item-margin li {
  margin-top: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .about-us-info-devide {
    display: block;
  }
  .about-us-info-devide .list-item-with-icon {
    margin-left: 0;
  }
  .list-item-with-icon {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .about-img-left {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .about-img-right {
    margin-left: 0;
    margin-bottom: 40px;
  }
  .about-us-info-wrap .ltn__video-play-btn {
    position: inherit;
    -webkit-transform: inherit;
    -ms-transform: inherit;
    transform: inherit;
  }
}

@media (max-width: 767px) {
  .custom-content-brief {
    margin-bottom: 30px;
  }
  .about-us-img-info {
    height: 200px;
    width: 220px;
  }
  .about-us-img-info h1 .counter {
    font-size: 80px;
  }
  .about-us-img-info-2 {
    width: 150px;
    height: 150px;
  }
  .about-us-img-info-2 h1 .counter {
    font-size: 30px;
  }
  .about-us-info-devide {
    display: block;
  }
  .about-us-info-devide .list-item-with-icon {
    margin-left: 0;
  }
  .list-item-with-icon {
    margin-top: 30px;
    margin-bottom: 40px;
  }
  .list-item-with-icon {
    min-width: 275px;
  }
  .ltn__list-item-half li {
    width: 100%;
    padding-right: 0px;
  }
  .ltn__flat-info li {
    margin-right: 20px;
  }
  .ltn__list-item-2-before li::before {
    right: -12px;
  }
}

/* ----------------------------------------------------
    Why Choose Us Area
---------------------------------------------------- */
.why-choose-us-feature-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  max-width: 480px;
}
.why-choose-us-feature-item .why-choose-us-feature-icon {
  margin-right: 20px;
  font-size: 70px;
  line-height: 1.2;
  color: var(--ltn__secondary-color);
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}
.why-choose-us-feature-item .why-choose-us-feature-brief h3 {
  margin-bottom: 5px;
}

.why-choose-us-img-wrap img {
  max-width: 65%;
}

.why-choose-us-img-2 {
  margin-top: -15%;
}
.why-choose-us-img-2 img {
  border: 15px solid var(--white);
}

/* ----------------------------------------------------
    Service Area
---------------------------------------------------- */
.ltn__service-item-1 {
  background-color: var(--white);
  margin-bottom: 50px;
}
.ltn__service-item-1 .service-item-img {
  position: relative;
  overflow: hidden;
}
.ltn__service-item-1 .service-item-img img {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.ltn__service-item-1 .service-item-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70px;
  height: 70px;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 40px;
  color: var(--ltn__secondary-color);
}
.ltn__service-item-1 .service-item-brief {
  padding: 32px 40px 10px;
}
.ltn__service-item-1 .service-item-brief h3 {
  margin-bottom: 5px;
}
.ltn__service-item-1:hover .service-item-img img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.ltn__service-item-2 {
  padding: 0px 45px 30px;
  margin-bottom: 50px;
  margin-top: 35px;
}
.ltn__service-item-2 .service-item-icon {
  font-size: 80px;
  line-height: 1;
  top: -35px;
  position: relative;
  color: var(--ltn__secondary-color);
}
.ltn__service-item-2 .service-item-brief {
  padding-top: 50px;
}
.ltn__service-item-2 .service-item-icon + .service-item-brief {
  padding-top: 0;
}
.ltn__service-item-2 ul {
  margin-bottom: 10px;
  padding: 0;
}
.ltn__service-item-2 ul li {
  list-style: none;
  margin-top: 10px;
}
.ltn__service-item-2 ul li span {
  color: var(--ltn__color-1);
}
.ltn__service-item-2 hr {
  margin-top: 25px;
  margin-bottom: 25px;
}

@media (max-width: 1199px) {
  .ltn__service-item-2 {
    padding: 0px 30px 30px;
  }
}

@media (max-width: 767px) {
  .ltn__service-item-1 .service-item-brief {
    padding: 30px 22px 10px;
  }
}

/* ----------------------------------------------------
    Call To Action 1, 2, 3, 4, 5
---------------------------------------------------- */
/* call-to-action-2 */
.call-to-action-2 {
  background-color: var(--white-3);
}

.call-to-action-inner-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.call-to-action-inner-2 h2 {
  margin: 0;
}
.call-to-action-inner-2 .btn-wrapper {
  margin-top: 0;
}

/* call-to-action-3 */
.get-a-free-service-margin {
  margin-bottom: -180px;
}

.get-a-free-service-inner {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: var(--ltn__box-shadow-1);
  box-shadow: var(--ltn__box-shadow-1);
  padding-right: 80px;
  padding-left: 30px;
}
.get-a-free-service-inner .call-to-img img {
  max-width: 350px;
  position: absolute;
  left: 35px;
  bottom: 0;
  z-index: 2;
}
.get-a-free-service-inner .call-to-action-inner-content {
  position: relative;
  z-index: 3;
}

.call-to-circle-1,
.call-to-circle-2 {
  height: 265px;
  width: 265px;
  display: inline-block;
  background-color: var(--ltn__secondary-color);
  position: absolute;
  left: 60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 100%;
  z-index: 1;
}

.call-to-circle-2 {
  height: 80px;
  width: 80px;
  left: 300px;
  top: 10%;
  -webkit-transform: inherit;
  -ms-transform: inherit;
  transform: inherit;
  -webkit-animation: wave 8s 0.1s infinite linear;
  animation: wave 8s 0.1s infinite linear;
}

.call-to-bg-icon {
  position: absolute;
  font-size: 220px;
  right: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  color: var(--ltn__color-1);
  opacity: 0.1;
}

.get-a-free-service-inner .call-to-circle-2 {
  -webkit-animation: wave 8s 0.1s infinite linear;
  animation: wave 8s 0.1s infinite linear;
}

/* call-to-action-4 */
.ltn__call-to-action-4 {
  position: relative;
}
.ltn__call-to-action-4 .call-to-action-inner-4 {
  position: relative;
  z-index: 99;
}
.ltn__call-to-action-4 .call-to-action-inner-4 h2 {
  font-style: italic;
}
.ltn__call-to-action-4 .call-to-action-inner-4 .h1 {
  font-size: 50px;
}
.ltn__call-to-action-4 .call-to-action-inner-4 p {
  font-size: 18px;
}

.ltn__call-to-4-img-1 {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  max-width: 34%;
}

.ltn__call-to-4-img-2 {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 27%;
}

/* call-to-action-5 */
.call-to-action-inner-5 a:hover {
  text-decoration: underline;
  color: var(--white);
}

/* call-to-action-6 */
.call-to-action-inner-6 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 50px 60px;
}
.call-to-action-inner-6 .coll-to-info p {
  margin-bottom: 0;
}
.call-to-action-inner-6 .coll-to-info p + p {
  margin-top: 15px;
}
.call-to-action-inner-6 .btn-wrapper {
  margin-top: 0;
}

/* Device :991px. */
@media (max-width: 991px) {
  .ltn__call-to-4-img-1,
  .ltn__call-to-4-img-2 {
    display: none;
  }
  .ltn__call-to-action-4 .call-to-action-inner-4 .h1 {
    font-size: 30px;
  }
  .ltn__call-to-action-4 .call-to-action-inner-4 p {
    font-size: 14px;
  }
  .call-to-action-inner-6 {
    padding: 50px 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .call-to-action-inner-6 .btn {
    margin-top: 30px;
  }
}

/* small mobile :320px. */
@media (max-width: 767px) {
  .call-to-action-inner-2 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .call-to-action-inner-2 .btn-wrapper {
    margin-top: 15px;
  }
  .get-a-free-service-inner {
    display: block;
    padding-right: 0;
    padding-left: 0px;
  }
  .get-a-free-service-inner .call-to-img {
    margin-bottom: 40px;
  }
  .get-a-free-service-inner .call-to-img img {
    max-width: 100%;
    position: relative;
    left: 0;
  }
  .call-to-circle-1,
  .call-to-circle-2 {
    display: none;
  }
  .ltn__call-to-action-4 .call-to-action-inner-4 .h1 {
    font-size: 24px;
  }
}

/* ----------------------------------------------------
    Elements Area
---------------------------------------------------- */
.ltn__elements-area {
  background-color: var(--section-bg-1);
  padding: 50px 0;
  margin: 50px 0;
}

.elements-title-area {
  text-align: center;
}

.elements-title-inner {
  background-color: var(--white-3);
  display: inline-block;
  padding: 10px 30px;
  outline: 10px solid;
  outline-offset: 10px;
  outline-color: var(--white-3);
  margin: 20px;
}
.elements-title-inner p {
  margin-bottom: 0;
  padding-top: 5px;
  border-top: 1px solid #ddd;
  margin-top: 5px;
}

.elements-title {
  display: inline-block;
  margin: 0;
}

/* ----------------------------------------------------
    Service Form 
---------------------------------------------------- */
.ltn__service-form-box {
  margin: 0;
  padding: 0 30px;
}
.ltn__service-form-box > ul {
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ltn__service-form-box > ul > li {
  display: inline-block;
  margin-right: 20px;
  list-style: none;
}
.ltn__service-form-box .nice-select {
  margin: 0;
  height: 60px;
  line-height: 58px;
  padding-right: 40px;
  padding-left: 20px;
  border-radius: 0;
  min-width: 200px;
  font-size: 16px;
  font-weight: 700;
  font-family: var(--ltn__heading-font);
}
.ltn__service-form-box .nice-select:after {
  right: 20px;
}
.ltn__service-form-box .nice-select UL li {
  display: block;
}
.ltn__service-form-box input {
  margin: 0;
}
.ltn__service-form-box input[type="date"] {
  margin: 0;
  height: 60px;
  padding: 0 20px;
  border: 1px solid;
  font-weight: 700;
  font-family: var(--ltn__heading-font);
  text-transform: uppercase;
}
.ltn__service-form-box .btn-wrapper {
  margin: 0;
}

.ltn__service-form-color-white .ltn__service-form-box .nice-select {
  background-color: transparent;
  border: 2px solid;
  border-color: var(--border-color-3);
}
.ltn__service-form-color-white .ltn__service-form-box .nice-select .current {
  color: var(--white);
  text-transform: uppercase;
}
.ltn__service-form-color-white .ltn__service-form-box .nice-select::after {
  border-bottom: 2px solid;
  border-right: 2px solid;
  border-color: var(--border-color-3);
}

.ltn__service-form-color-white .ltn__service-form-box input {
  background-color: transparent;
  color: var(--white);
  border-color: var(--border-color-3);
  font-weight: 700;
  height: 60px;
}

.ltn__service-form-color-white .ltn__service-form-box input[type="date"] {
  background-color: transparent;
  color: var(--white);
}

.ltn__service-form-color-white
  .ltn__service-form-box
  input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.ltn__service-form-color-white
  .ltn__service-form-box
  input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--white);
}

.ltn__service-form-color-white .ltn__service-form-box input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--white);
}

.ltn__service-form-color-white
  .ltn__service-form-box
  input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--white);
}

.ltn__service-form-color-white .ltn__service-form-box input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--white);
}

.ltn__service-form-color-white .input-item-date.ltn__custom-icon::before {
  color: var(--white);
  top: 50%;
}

.input-item-date {
  margin-bottom: 30px;
}
.input-item-date input[type="date"] {
  background-color: transparent;
  border: 2px solid var(--border-color-1);
  height: 65px;
  width: 100%;
  padding: 0 20px;
}
.input-item-date input[type="date"]::-webkit-calendar-picker-indicator {
  -webkit-filter: invert(0%);
  filter: invert(0%);
}

.ltn__service-form-1 .ltn__service-form-brief {
  padding-left: 100px;
}

.ltn__service-form-margin {
  margin-top: -160px;
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .ltn__service-form-box {
    padding: 0;
  }
  .ltn__service-form-box .input-item {
    max-width: 200px;
  }
}

@media (max-width: 1199px) {
  .ltn__service-form-box > ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .ltn__service-form-box > ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
}

@media (max-width: 575px) {
  .ltn__service-form-1 .ltn__service-form-brief {
    padding-left: 20px;
  }
}

/* ----------------------------------------------------
    Get A Quote Form
---------------------------------------------------- */
.get-a-quote-wrap {
  padding: 40px 50px 50px;
  border: 2px solid;
  border-color: var(--border-color-1);
}

.get-a-quote-form {
  margin: 0;
}
.get-a-quote-form .btn-wrapper {
  padding: 0 40px;
}
.get-a-quote-form .btn {
  width: 100%;
}
.get-a-quote-form input::-webkit-input-placeholder {
  color: var(--ltn__color-1);
}
.get-a-quote-form input::-moz-placeholder {
  color: var(--ltn__color-1);
}
.get-a-quote-form input:-ms-input-placeholder {
  color: var(--ltn__color-1);
}
.get-a-quote-form input:-moz-placeholder {
  color: var(--ltn__color-1);
}

.input-item .nice-select {
  border: 2px solid;
  border-color: var(--border-color-1);
  border-radius: 0;
  font-size: 14px;
  font-weight: 400;
  height: 65px;
  line-height: 60px;
  width: 100%;
  margin-bottom: 30px;
}
.input-item .nice-select .current {
  font-weight: 700;
}
.input-item .nice-select::after {
  display: none;
}
.input-item .nice-select::before {
  content: "\f063";
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  color: var(--ltn__secondary-color);
}

.input-item::after {
  display: block;
  clear: both;
  content: "";
}

@media (max-width: 1199px) {
  .get-a-quote-form .btn-wrapper {
    padding: 0 0px;
  }
}

@media (max-width: 767px) {
  .get-a-quote-wrap {
    padding: 40px 30px 50px;
  }
}

/* ----------------------------------------------------
    Car Dealer Form
---------------------------------------------------- */
.ltn__car-dealer-form-tab .ltn__tab-menu {
  margin-bottom: 0;
}
.ltn__car-dealer-form-tab .ltn__tab-menu .active {
  border-color: var(--ltn__secondary-color);
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__car-dealer-form-tab .tab-content {
  padding: 40px;
  background-color: var(--section-bg-1);
}

.ltn__car-dealer-form-box .ltn__car-dealer-form-item {
  position: relative;
  display: inline-block;
  margin-bottom: 30px;
}

.ltn__car-dealer-form-box .nice-select {
  margin: 0;
  height: 60px;
  line-height: 58px;
  padding-right: 40px;
  padding-left: 20px;
  border-radius: 0;
  min-width: 200px;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--ltn__heading-font);
  width: 100%;
}
.ltn__car-dealer-form-box .nice-select .list {
  width: 100%;
  z-index: 99;
  max-height: 250px;
  overflow-y: auto;
}

.ltn__car-dealer-form-box .ltn__custom-icon::before {
  display: none;
}

.ltn__car-dealer-form-box .ltn__custom-icon .nice-select::before {
  content: "\f063";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  margin-right: 10px;
  color: var(--ltn__secondary-color);
}

.ltn__car-dealer-form-box
  .ltn__custom-icon.ltn__icon-calendar
  .nice-select::before {
  content: "\f073";
}

.ltn__car-dealer-form-box .ltn__custom-icon.ltn__icon-car .nice-select::before {
  content: "\f1b9";
}

.ltn__car-dealer-form-box
  .ltn__custom-icon.ltn__icon-meter
  .nice-select::before {
  content: "\f3fd";
}

.ltn__car-dealer-form-box
  .ltn__custom-icon.ltn__icon-ring
  .nice-select::before {
  content: "\f1cd";
}

.ltn__car-dealer-form-box .ltn__custom-icon.ltn__icon-cog .nice-select::before {
  content: "\f013";
}

.ltn__car-dealer-form-box
  .ltn__custom-icon.ltn__icon-transgender
  .nice-select::before {
  content: "\f225";
}

.car-price-filter-range .price_filter .ui-widget-content {
  height: 8px;
  background-color: var(--white);
  margin-top: 20px;
}

.car-price-filter-range .price_filter .ui-state-default:last-child::before {
  position: absolute;
}

.car-price-filter-range .price_filter .ui-state-default:last-child::after {
  position: absolute;
  content: "";
  top: -30px;
  left: -5px;
  background-image: url(../img/icons/car.png);
  z-index: 99;
  width: 125px;
  height: 50px;
  background-repeat: no-repeat;
}

.car-price-filter-range .price_slider_amount > input[type="text"],
.car-price-filter-range .price_slider_amount > input[type="submit"] {
  font-weight: 700;
}

@media (max-width: 767px) {
  .ltn__car-dealer-form-tab .tab-content {
    padding: 40px 25px;
  }
  .ltn__car-dealer-form-box .nice-select .list {
    max-height: 200px;
    overflow-y: auto;
  }
}

/* ----------------------------------------------------
    Video Area
---------------------------------------------------- */
/* car home 3 */
.ltn__video-popup-margin {
  margin-bottom: -180px;
}

/* Service page */
.ltn__video-popup-margin-2 {
  margin-top: -295px;
}

/* ----------------------------------------------------
    Brand Logo
---------------------------------------------------- */
.ltn__brand-logo-item {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  margin: 10px 0;
}
.ltn__brand-logo-item img {
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.ltn__brand-logo-item:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}
.ltn__brand-logo-item:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* ----------------------------------------------------
    Progress Bar 
---------------------------------------------------- */
.ltn__progress-bar-wrap {
  margin-bottom: 50px;
}

.ltn__progress-bar-item {
  overflow: hidden;
  margin-bottom: 10px;
}
.ltn__progress-bar-item > p {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.ltn__progress-bar-item .progress {
  background: #ebeeee none repeat scroll 0 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
  height: 3px;
  letter-spacing: 1px;
  margin-bottom: 15px;
  margin-top: 12px;
  overflow: visible;
  text-transform: uppercase;
  position: relative;
}
.ltn__progress-bar-item .progress-bar {
  background-color: var(--ltn__secondary-color);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--ltn__secondary-color);
  font-size: 11px;
  overflow: visible;
  height: 7px;
  margin-top: -2px;
  text-align: left;
  position: relative;
}
.ltn__progress-bar-item .progress-bar span {
  border-radius: 15px;
  display: inline-block;
  height: 25px;
  letter-spacing: 0;
  line-height: 24px;
  min-width: 25px;
  padding: 0 3px;
  position: absolute;
  right: 0;
  text-align: center;
  bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

@media (max-width: 767px) {
  .ltn__progress-bar-item > p {
    font-size: 14px;
  }
}

/* ----------------------------------------------------
    Our Journey Area
---------------------------------------------------- */
.ltn__our-journey-wrap ul {
  margin-left: 0;
  padding-left: 0;
}

.ltn__our-journey-wrap > ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  position: relative;
}
.ltn__our-journey-wrap > ul::before {
  position: absolute;
  content: "";
  background-color: #d13724;
  height: 4px;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ltn__our-journey-wrap > ul li {
  list-style: none;
  margin: 0;
}
.ltn__our-journey-wrap > ul > li {
  display: inline-block;
  position: relative;
  margin: 0 10px;
}
.ltn__our-journey-wrap > ul > li:nth-last-child(1) ul,
.ltn__our-journey-wrap > ul > li:nth-last-child(2) ul {
  left: auto;
  right: 0;
}
.ltn__our-journey-wrap > ul > li::before {
  position: absolute;
  content: "";
  top: 15px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #fff;
  left: 0;
  right: 0;
  margin: auto;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.ltn__our-journey-wrap > ul > li ul {
  position: absolute;
  bottom: 100%;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  opacity: 0;
  visibility: hidden;
}
.ltn__our-journey-wrap > ul > li.active .ltn__journey-icon,
.ltn__our-journey-wrap > ul > li:hover .ltn__journey-icon {
  background-color: var(--white);
  color: var(--ltn__secondary-color);
}
.ltn__our-journey-wrap > ul > li.active ul,
.ltn__our-journey-wrap > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
}
.ltn__our-journey-wrap > ul > li.active::before,
.ltn__our-journey-wrap > ul > li:hover::before {
  opacity: 1;
  visibility: visible;
}

.ltn__our-journey-wrap .ltn__journey-icon {
  font-size: 20px;
  font-weight: 700;
  padding: 40px;
  background-color: var(--ltn__primary-color);
  color: var(--white);
  display: block;
  margin: 30px 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
  text-align: center;
}

.ltn__our-journey-wrap .dropdown-menu {
  padding: 0;
}

.ltn__journey-history-item-info {
  background-color: var(--white);
  padding: 20px;
  display: block;
  width: 550px;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
  position: relative;
}
.ltn__journey-history-item-info .ltn__journey-history-img {
  float: left;
  margin-right: 20px;
  max-width: 150px;
}
.ltn__journey-history-item-info .ltn__journey-history-info {
  overflow: hidden;
}
.ltn__journey-history-item-info h3 {
  margin-bottom: 5px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ltn__journey-history-item-info {
    width: 500px;
  }
}

@media (max-width: 1199px) {
  .ltn__our-journey-wrap .ltn__journey-icon {
    font-size: 18px;
    padding: 20px;
  }
}

@media (max-width: 991px) {
  .ltn__journey-history-item-info {
    width: 350px;
  }
  .ltn__journey-history-item-info .ltn__journey-history-img {
    float: none;
    margin-bottom: 20px;
    max-width: 100px;
  }
}

@media (max-width: 767px) {
  .ltn__our-journey-wrap > ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .ltn__our-journey-wrap > ul > li ul {
    left: 30px;
  }
  .ltn__our-journey-wrap > ul > li:nth-last-child(1) ul,
  .ltn__our-journey-wrap > ul > li:nth-last-child(2) ul {
    left: 30px;
    right: auto;
  }
}

@media (max-width: 575px) {
  .ltn__journey-history-item-info {
    width: 250px;
  }
  .ltn__journey-history-item-info .ltn__journey-history-img {
    float: none;
    margin-bottom: 20px;
  }
}

/* ----------------------------------------------------
    Google Map Locations Area
---------------------------------------------------- */
.ltn__google-map-locations-area #gmap {
  height: 90vh;
}
.ltn__google-map-locations-area #gmap .gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}
.ltn__google-map-locations-area #gmap .gm-style .gm-style-iw-c {
  padding: 25px 8px 5px 25px !important;
  border-radius: 0;
}

.ltn__map-item {
  padding: 30px 25px 35px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
}
.ltn__map-item .ltn__location-name {
  border-bottom: 2px solid var(--border-color-1);
  margin-bottom: 25px;
  color: var(--ltn__secondary-color);
  font-size: 20px;
}
.ltn__map-item .ltn__location-single-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
.ltn__map-item .ltn__location-single-info i {
  margin-right: 10px;
  background: var(--ltn__secondary-color);
  color: var(--white);
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.ltn__map-item .btn-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ltn__map-item .btn {
  padding: 10px 10px;
  font-size: 13px;
  margin-right: 10px;
}

.ltn__google-map-locations-list-area .ltn__map-item {
  margin-bottom: 50px;
}

.ltn__location-search h3 {
  float: left;
  margin-right: 30px;
  margin-bottom: 0;
  line-height: 63px;
  color: var(--white);
}

.ltn__location-search .input-item {
  float: left;
  width: 300px;
}

.ltn__location-search form {
  padding: 50px 50px 20px;
  background-color: var(--ltn__secondary-color);
}

.ltn__state-location-title {
  padding: 15px 30px;
  background-color: var(--ltn__secondary-color);
  color: var(--white);
  margin: 50px 0;
}

.ltn__map-product-item {
  max-width: 260px;
}
.ltn__map-product-item img {
  max-width: 100%;
  margin-bottom: 25px;
}
.ltn__map-product-item .ltn__map-product-title {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
}
.ltn__map-product-item .ltn__map-product-price {
  color: var(--ltn__secondary-color);
}
.ltn__map-product-item .ltn__map-product-location {
  font-weight: 500;
}
.ltn__map-product-item .ltn__map-product-location i {
  color: var(--ltn__secondary-color);
  margin-right: 2px;
}
.ltn__map-product-item .ltn__map-product-info {
  font-weight: 500;
}
.ltn__map-product-item .ltn__map-product-info span {
  margin-right: 10px;
}

/* ----------------------------------------------------
    Team Details
---------------------------------------------------- */
.ltn__team-details-member-info .team-details-img {
  margin-bottom: 30px;
  max-width: 350px;
}

.ltn__team-details-member-info.text-right .team-details-img {
  margin-left: auto;
  margin-right: 0;
}

.ltn__team-details-member-info.text-center .team-details-img {
  margin-left: auto;
  margin-right: auto;
}

.ltn__team-details-member-about ul {
  margin: 0;
}
.ltn__team-details-member-about ul li {
  list-style: none;
  display: block;
}
.ltn__team-details-member-about ul li strong {
  min-width: 120px;
  display: inline-block;
}

.ltn__team-details-member-about > ul {
  padding: 0;
}

/* ----------------------------------------------------
    Our History Area
---------------------------------------------------- */
.ltn__our-history-inner .ltn__tab-menu .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.ltn__our-history-inner .ltn__tab-menu .nav::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
  background-color: var(--white-6);
  z-index: -1;
}

.ltn__our-history-inner .ltn__tab-menu a {
  background-color: var(--white);
  border: 2px solid var(--white-6);
}

.ltn__our-history-inner .ltn__tab-menu .active {
  border-color: var(--ltn__secondary-color);
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.ltn__history-icon {
  position: absolute;
  bottom: 40px;
  right: 0;
  font-size: 160px;
  line-height: 1;
  color: var(--ltn__secondary-color);
}
.ltn__history-icon i {
  margin: 0;
}

.ltn__our-history-inner .about-img-left {
  padding-right: 70px;
}

.ltn__our-history-inner .about-img-right {
  padding-left: 70px;
}

.ltn__our-history-inner-2 .ltn__tab-menu {
  margin-bottom: 20px;
}
.ltn__our-history-inner-2 .ltn__tab-menu .nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  position: relative;
}
.ltn__our-history-inner-2 .ltn__tab-menu .nav::before {
  display: none;
}
.ltn__our-history-inner-2 .ltn__tab-menu a {
  background-color: transparent;
  border: 2px solid transparent;
  font-size: 22px;
  padding: 5px 20px;
}
.ltn__our-history-inner-2 .ltn__tab-menu .active {
  border-color: transparent;
  background-color: transparent;
  color: var(--ltn__secondary-color);
}

@media (max-width: 767px) {
  .ltn__history-icon {
    bottom: 10px;
    right: 10px;
    font-size: 80px;
  }
  .ltn__our-history-inner .about-img-left {
    padding-right: 0px;
  }
  .ltn__our-history-inner .about-img-right {
    padding-left: 0px;
  }
}

/* ----------------------------------------------------
    Appointment Form Area
---------------------------------------------------- */
.ltn__appointment-inner .alert {
  border-radius: 0;
  padding: 20px 30px;
  margin-bottom: 30px;
}

.ltn__appointment-inner .alert-danger {
  font-weight: 600;
  font-size: 14px;
}

/* ----------------------------------------------------
    Checkout Page
---------------------------------------------------- */
.ltn__checkout-single-content {
  margin-bottom: 30px;
}
.ltn__checkout-single-content h5 {
  background-color: var(--section-bg-1);
  padding: 20px 30px;
}

.ltn__checkout-single-content-info {
  padding: 30px;
  border: 1px solid var(--border-color-1);
}

.ltn__checkout-payment-method .card {
  padding: 20px;
  border-radius: 0;
}

.ltn__checkout-payment-method .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.ltn__checkout-payment-method .card-body {
  padding: 15px 30px;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  margin-top: 15px;
  position: relative;
}
.ltn__checkout-payment-method .card-body::before {
  position: absolute;
  top: -3px;
  left: 25px;
  width: 10px;
  height: 10px;
  content: "";
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #fff;
}

.ltn__checkout-payment-method .ltn__card-title {
  position: relative;
  padding-left: 30px;
  margin-bottom: 0;
}
.ltn__checkout-payment-method .ltn__card-title::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 16px;
  height: 16px;
  margin-top: 2px;
  content: "";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px solid #333;
  border-radius: 50%;
}
.ltn__checkout-payment-method .ltn__card-title::after {
  position: absolute;
  top: 50%;
  left: 2px;
  width: 11px;
  height: 11px;
  margin-top: 2px;
  content: "";
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
  border-radius: 50%;
  background-color: #333;
}
.ltn__checkout-payment-method .ltn__card-title img {
  height: 45px;
  margin-left: 10px;
}

.ltn__checkout-payment-method .ltn__card-title[aria-expanded="true"]::after {
  opacity: 1;
}

.ltn__checkout-payment-method p {
  font-size: 14px;
}
.ltn__checkout-payment-method p:last-child {
  margin-bottom: 0;
}

/* ----------------------------------------------------
    Myaccount Page
---------------------------------------------------- */
.ltn__tab-menu-list .nav {
  display: block;
  margin-right: 30px;
  border: 1px solid #eee;
}
.ltn__tab-menu-list .nav a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid var(--border-color-1);
}
.ltn__tab-menu-list .nav a:last-child {
  border-bottom: 0;
}
.ltn__tab-menu-list .nav a i {
  font-size: 14px;
}
.ltn__tab-menu-list .nav a.active {
  background-color: var(--section-bg-2);
  color: var(--white);
}

.ltn__myaccount-tab-content-inner > p {
  padding: 20px;
  background-color: var(--section-bg-1);
}

/* ----------------------------------------------------
    Time Schedule Area
---------------------------------------------------- */
.ltn__time-schedule-widget {
  padding: 50px 40px;
  margin-top: -10px;
}
.ltn__time-schedule-widget h3 {
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
  margin-bottom: 25px;
}
.ltn__time-schedule-widget ul {
  padding: 0;
  margin: 0;
}
.ltn__time-schedule-widget ul li {
  display: block;
}
.ltn__time-schedule-widget ul li span {
  float: right;
}

/* ----------------------------------------------------
    Contact Feature
---------------------------------------------------- */
.ltn__contact-feature-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 25px 30px 20px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  margin-bottom: 30px;
  background-color: var(--white);
}

.ltn__contact-feature-icon {
  font-size: 50px;
  margin-right: 20px;
  color: var(--ltn__secondary-color);
  line-height: 1;
  max-width: 50px;
}

.ltn__contact-feature-info .h6,
.ltn__contact-feature-info .h2 {
  font-family: var(--ltn__body-font);
}

.ltn__contact-feature-info .h6 {
  color: var(--ltn__color-3);
}

@media (max-width: 1400px) {
  .ltn__contact-feature-icon {
    font-size: 40px;
    margin-right: 15px;
  }
  .ltn__contact-feature-info .h6 {
    font-size: 14px;
  }
  .ltn__contact-feature-info .h2 {
    font-size: 18px;
  }
}

/* ----------------------------------------------------
    Image Slide (Screenshot)
---------------------------------------------------- */
.ltn__img-slide-item-4 > a,
.ltn__img-slide-item-3 > a,
.ltn__img-slide-item-2 > a {
  display: block;
  overflow: hidden;
}

.ltn__img-slide-item-4 img,
.ltn__img-slide-item-3 img,
.ltn__img-slide-item-2 img {
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.ltn__img-slide-item-4:hover img,
.ltn__img-slide-item-3:hover img,
.ltn__img-slide-item-2:hover img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

/* ----------------------------------------------------
    Background Video
---------------------------------------------------- */
/* Video From Local (src="myFolderName/videoName.mp4") */
/*
#myVideo {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}
*/
/*
#myVideo {
    position: absolute;
    left: 0;
    right: auto;
    bottom: 0;
    width: 100%;
    height: auto;
    min-width: 100%; 
    min-height: 100%;
}
*/
/*
#myVideo {
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%; 
    min-height: 100%;
}
*/
#myVideo {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100vw;
  min-width: 100%;
  height: 100%;
}

/* Video From Link (src="website.com/videoUrl") */
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.ltn__video-play-btn {
  margin-top: 0;
  width: 60px;
  height: 60px;
  line-height: 65px;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  -webkit-box-shadow: var(--ltn__box-shadow-2);
  box-shadow: var(--ltn__box-shadow-2);
}

/* YouTube Video */
/*
.ltn__youtube-video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 1;
}
*/
.ltn__youtube-video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}

/* ----------------------------------------------------
    Newsletter Popup 2
---------------------------------------------------- */
.ltn__newsletter-popup-2 .modal-header .close {
  background-color: var(--section-bg-1);
  color: var(--ltn__heading-color);
  border-radius: 0px 0px 0px 20px;
}

.ltn__newsletter-popup-2 .modal-body {
  padding: 0;
}

.ltn__newsletter-popup-2 .ltn__newsletter-popup-info {
  padding: 30px;
}

/* ----------------------------------------------------
    Newsletter Popup
---------------------------------------------------- */
.ltn__newletter-popup-active {
  max-width: 850px;
  background: #fff;
  top: 50% !important;
  margin-top: -179px;
  position: fixed !important;
  display: none;
}

.ltn__newletter-popup {
  position: relative;
  padding: 30px;
}
.ltn__newletter-popup p {
  font-size: 14px;
}
.ltn__newletter-popup .subscribe-bottom {
  font-size: 14px;
}

.ltn__newsletter-close {
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  z-index: 999;
}

.ltn__newsletter-close:hover {
  color: var(--ltn__secondary-color);
}

.ltn__newsletter-close-btn-left {
  right: auto;
  left: 0;
}

.ltn__newsletter-popup-info {
  padding: 15px;
}

.text-center .ltn__newsletter-popup-info input[type="email"] {
  text-align: center;
}

.ltn__newletter-popup-3 {
  padding: 0;
}
.ltn__newletter-popup-3 .ltn__newsletter-popup-info {
  padding: 30px;
}

.ltn__newletter-popup-4 {
  padding: 0;
}
.ltn__newletter-popup-4 .ltn__newsletter-popup-info {
  padding: 30px 30px 30px 10px;
}

@media (max-width: 991px) {
  .ltn__newletter-popup-active {
    max-width: 400px;
    max-height: 450px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .ltn__newsletter-popup-img {
    display: none;
  }
}

@media (max-width: 768px) {
  .ltn__newletter-popup-active {
    max-width: 300px;
  }
}

/* ----------------------------------------------------
    Apartments Plan Area
---------------------------------------------------- */
.apartments-plan-info {
  padding: 70px;
}

.apartments-plan-info,
.apartments-plan-img {
  margin-bottom: 50px;
}

.apartments-info-list > ul {
  padding: 0;
  margin: 0;
}
.apartments-info-list > ul li {
  list-style: none;
  margin-top: 10px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.apartments-info-list > ul li::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: 1px dashed;
  border-color: var(--section-bg-5);
  z-index: 1;
}
.apartments-info-list > ul li a {
  display: block;
}
.apartments-info-list > ul li a span {
  float: right;
}
.apartments-info-list > ul li label {
  padding-right: 10px;
  background-color: var(--white);
  position: relative;
  z-index: 2;
}
.apartments-info-list > ul li span {
  float: right;
  padding-left: 10px;
  background-color: var(--white);
  position: relative;
  z-index: 2;
}

.apartments-info-list-color > ul li::before {
  border-color: var(--section-bg-5);
}

.apartments-info-list-color > ul li label {
  background-color: var(--ltn__secondary-color);
}

.apartments-info-list-color > ul li span {
  background-color: var(--ltn__secondary-color);
}

.section-bg-1 .apartments-info-list-color > ul li::before {
  border-color: var(--ltn__color-1);
}

.section-bg-1 .apartments-info-list-color > ul li label {
  background-color: var(--section-bg-1);
}

.section-bg-1 .apartments-info-list-color > ul li span {
  background-color: var(--section-bg-1);
}

@media (max-width: 767px) {
  .apartments-plan-info {
    padding: 30px;
  }
  .apartments-info-list ul li {
    font-size: 14px;
  }
}

/* ----------------------------------------------------
    Upcoming Project Area
---------------------------------------------------- */
.ltn__upcoming-project-item {
  padding: 30px 45px 30px 30px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  margin-top: 10px;
  margin-bottom: 50px;
  background-color: var(--white);
}
.ltn__upcoming-project-item .ltn__upcoming-project-img {
  margin-top: 0;
}
.ltn__upcoming-project-item .ltn__upcoming-project-info {
  padding: 50px 20px;
}

@media (max-width: 991px) {
  .ltn__upcoming-project-item {
    padding: 30px 30px 30px 30px;
  }
  .ltn__upcoming-project-item .ltn__upcoming-project-img {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .ltn__upcoming-project-item .ltn__upcoming-project-info {
    padding: 50px 0px;
  }
}

/* ----------------------------------------------------
    Search By Place Area
---------------------------------------------------- */
.ltn__search-by-place-item {
  padding: 15px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  background-color: var(--white);
  margin-bottom: 50px;
}
.ltn__search-by-place-item .search-by-place-img {
  position: relative;
}
.ltn__search-by-place-item .search-by-place-img .search-by-place-badge {
  position: absolute;
  top: 0;
  left: 10px;
}
.ltn__search-by-place-item .search-by-place-img .search-by-place-badge ul {
  padding: 0;
  margin: 0;
}
.ltn__search-by-place-item .search-by-place-img .search-by-place-badge ul li {
  display: inline-block;
  background-color: var(--white);
  padding: 6px 15px 2px;
  border-radius: 25px;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 600;
}
.ltn__search-by-place-item .search-by-place-info {
  padding: 30px 5px 10px;
}
.ltn__search-by-place-item .search-by-place-info h6 {
  font-family: var(--ltn__body-font);
  color: var(--ltn__color-5);
  font-weight: 400;
}
.ltn__search-by-place-item .search-by-place-info h4 {
  font-weight: 600;
}
.ltn__search-by-place-item .search-by-place-info .search-by-place-btn a {
  color: var(--ltn__secondary-color);
}
.ltn__search-by-place-item .search-by-place-brief {
  font-size: 14px;
  margin-top: 10px;
}

/* ----------------------------------------------------
    Select Availability Table Area
---------------------------------------------------- */
.ltn__select-availability-table-wrap .tower-name {
  text-transform: uppercase;
}

.ltn__select-availability-table ul {
  padding: 0 0 15px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ltn__select-availability-table ul li {
  list-style: none;
  display: inline-block;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
}

.ltn__select-availability-table .ltn__select-availability-table-head {
  border-bottom: 2px solid;
  border-color: var(--border-color-1);
}

.ltn__select-availability-table
  .ltn__select-availability-table-row:nth-child(odd) {
  background-color: var(--section-bg-1);
}

.ltn__select-availability-table-responsive
  .ltn__select-availability-table-row-responsive-item {
  padding: 10px 0 25px;
  margin: 0;
  text-align: center;
}
.ltn__select-availability-table-responsive
  .ltn__select-availability-table-row-responsive-item:nth-child(odd) {
  background-color: var(--section-bg-1);
}
.ltn__select-availability-table-responsive
  .ltn__select-availability-table-row-responsive-item
  li {
  display: block;
  list-style: none;
}
.ltn__select-availability-table-responsive
  .ltn__select-availability-table-row-responsive-item
  li::after {
  display: block;
  clear: both;
  content: "";
}
.ltn__select-availability-table-responsive
  .ltn__select-availability-table-row-responsive-item
  li
  span {
  width: 50%;
  float: left;
}

/* ----------------------------------------------------
    Neighbour Area
---------------------------------------------------- */
.ltn__neighbour-tab-wrap {
  position: relative;
}
.ltn__neighbour-tab-wrap .ltn__tab-menu-4 {
  position: absolute;
  z-index: 2;
  bottom: 10px;
  left: 20px;
}

@media (max-width: 991px) {
  .ltn__neighbour-tab-wrap .ltn__tab-menu-4 {
    position: inherit;
    margin-bottom: 30px;
    left: inherit;
    bottom: inherit;
  }
  .ltn__neighbour-tab-wrap .ltn__tab-menu-4 a img {
    max-width: 70px;
  }
}

/* ----------------------------------------------------
    LightCase
---------------------------------------------------- */
#lightcase-overlay {
  z-index: 9999;
  background: var(--section-bg-2);
}

#lightcase-case {
  z-index: 99999;
}

/* ----------------------------------------------------
    my-properties-table
---------------------------------------------------- */
.ltn__my-properties-table {
  margin-top: 0;
}
.ltn__my-properties-table .table thead th {
  border-bottom: 1px solid var(--border-color-1) !important;
}
.ltn__my-properties-table tr,
.ltn__my-properties-table .table tr:nth-child(odd),
.ltn__my-properties-table .table tr:nth-child(even) {
  background-color: transparent;
}
.ltn__my-properties-table td {
  vertical-align: middle;
  padding-top: 30px;
  padding-bottom: 30px;
}

.ltn__my-properties-img {
  width: 180px;
}

@media (max-width: 767px) {
  .ltn__my-properties-table {
    margin-top: 0;
  }
  .ltn__my-properties-table tr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
  }
  .ltn__my-properties-table .table tr:nth-child(odd) {
    background-color: transparent;
  }
  .ltn__my-properties-table .table tr:nth-child(even) {
    background-color: var(--section-bg-1);
  }
  .ltn__my-properties-table td {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ltn__my-properties-img {
    width: 100%;
  }
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* ----------------------------------------------------
    Footer Default Style
---------------------------------------------------- */
.footer-widget .footer-title {
  font-size: 22px;
}

/* footer-2 */
.ltn__footer-2 .footer-widget .footer-title {
  font-size: 24px;
}

.ltn__footer-2 .footer-widget p,
.ltn__footer-2 .footer-widget li {
  font-size: 16px;
}

.ltn__footer-2 .footer-about-widget .footer-logo {
  position: relative;
  top: -20px;
  margin-bottom: 10px;
}

.ltn__footer-2 .footer-menu ul li {
  margin-bottom: 30px;
  font-weight: 600;
}

.ltn__footer-2 .footer-widget .ltn__blog-meta li,
.footer-widget .ltn__blog-meta li {
  font-size: 14px;
}

/* footer-color-1 */
.ltn__footer-color-1 p {
  color: var(--ltn__color-2);
}
.ltn__footer-color-1 p a {
  color: var(--white);
}

.ltn__footer-color-1 .ltn__footer-timeline-widget-1 li span {
  color: var(--ltn__color-1);
}

.ltn__footer-color-1 .ltn__footer-timeline-widget-1 li:last-child span {
  color: var(--white);
}

.ltn__footer-color-1 .footer-menu ul li {
  color: var(--ltn__color-2);
}

.ltn__footer-color-1 .ltn__footer-blog-item {
  border-color: var(--border-color-6);
}
.ltn__footer-color-1 .ltn__footer-blog-item .ltn__blog-meta li {
  color: var(--ltn__color-2);
}

.ltn__footer-color-1 .ltn__copyright-area {
  background-color: var(--ltn__primary-color-2);
}

.ltn__footer-color-1 .get-support-info h6 {
  color: var(--ltn__color-4);
}

.ltn__footer-color-1 .get-support-info h4 {
  color: var(--white);
}

.ltn__footer-color-1 .ltn__copyright-menu ul li {
  color: var(--white);
}

/* footer-color-2 */
.ltn__footer-color-2 .ltn__footer-timeline-widget-1 {
  background-color: var(--white);
}
.ltn__footer-color-2 .ltn__footer-timeline-widget-1 li {
  border-color: transparent;
}
.ltn__footer-color-2 .ltn__footer-timeline-widget-1 li:last-child span {
  color: var(--ltn__secondary-color);
}

.ltn__footer-color-2 .ltn__footer-blog-item {
  border-color: var(--border-color-7);
}

.ltn__footer-color-2 .ltn__copyright-area {
  background-color: var(--white-4);
}

/*-------------------------
    Footer Area
-------------------------*/
.footer-top-area {
  padding-top: 92px;
  padding-bottom: 20px;
}

.footer-widget {
  margin-bottom: 60px;
}

.footer-logo {
  position: relative;
  top: -10px;
}

.footer-title {
  font-size: 20px;
  margin-bottom: 25px;
}

/* ----------------------------------------------------
    Footer About Widget
---------------------------------------------------- */
/* footer-address */
.footer-address ul {
  margin: 0;
  padding: 0;
}
.footer-address ul li {
  list-style: none;
  display: block;
  margin-top: 8px;
}
.footer-address ul li:after {
  display: block;
  clear: both;
  content: "";
}
.footer-address ul li .footer-address-icon {
  float: left;
  margin-right: 15px;
}
.footer-address ul li .footer-address-info {
  overflow: hidden;
}
.footer-address ul li .footer-address-info p {
  margin-bottom: 0;
}

/* ----------------------------------------------------
    Footer Menu Widget
---------------------------------------------------- */
/* footer-menu */
.footer-menu ul {
  margin: 0;
  padding: 0;
}
.footer-menu ul li {
  list-style: none;
  margin-top: 0px;
  margin-bottom: 15px;
  position: relative;
}
.footer-menu ul li a {
  position: relative;
}
.footer-menu ul li a::before {
  position: absolute;
  content: "//";
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: inherit;
  -o-transition: inherit;
  transition: inherit;
  opacity: 0;
  visibility: hidden;
  margin-left: -20px;
  color: var(--ltn__secondary-color);
}
.footer-menu ul li:hover a {
  padding-left: 20px;
}
.footer-menu ul li:hover a::before {
  opacity: 1;
  visibility: visible;
  margin-left: 0;
}

.footer-menu ul ul {
  margin-left: 15px;
}

/* footer-menu-widget-2-column */
.footer-menu-widget-2-column {
  padding-left: 30px;
}
.footer-menu-widget-2-column .footer-menu {
  width: 50%;
  float: left;
}
.footer-menu-widget-2-column .footer-menu:nth-child(odd) {
  padding-left: 20px;
}
.footer-menu-widget-2-column ul {
  margin: 0;
}
.footer-menu-widget-2-column ul li {
  font-weight: 600;
}

/* ----------------------------------------------------
    Footer Newsletter Widget
---------------------------------------------------- */
.footer-newsletter form {
  position: relative;
}

.footer-newsletter input[type="email"] {
  margin: 0;
  padding-right: 50px;
}

.footer-newsletter .btn-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  height: 100%;
}
.footer-newsletter .btn-wrapper .btn {
  padding: 0 18px;
  height: 100%;
}

/* ----------------------------------------------------
    Footer Timeline Widget
---------------------------------------------------- */
.ltn__footer-timeline-widget {
  padding: 50px 50px 40px;
  border-bottom: 5px solid;
  border-color: var(--border-color-5);
  margin-top: -92px;
}
.ltn__footer-timeline-widget .footer-title {
  font-size: 36px;
}
.ltn__footer-timeline-widget ul {
  margin: 0;
  padding: 0;
}
.ltn__footer-timeline-widget ul li {
  list-style: none;
  font-family: var(--ltn__heading-font);
  font-weight: 700;
  border-bottom: 1px solid;
  border-color: var(--border-color-6);
  padding-bottom: 10px;
  margin-top: 10px;
}
.ltn__footer-timeline-widget ul li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}
.ltn__footer-timeline-widget ul li:last-child span {
  font-weight: 700;
}
.ltn__footer-timeline-widget ul li span {
  float: right;
  font-weight: 600;
}

/* ----------------------------------------------------
    Footer Blog Widget
---------------------------------------------------- */
.ltn__footer-blog-item {
  border-bottom: 1px solid;
  border-color: var(--border-color-6);
  margin-bottom: 20px;
}
.ltn__footer-blog-item:last-child {
  border-bottom: 0;
  margin-bottom: 0;
}
.ltn__footer-blog-item .ltn__blog-meta {
  margin-bottom: 5px;
}
.ltn__footer-blog-item .ltn__blog-meta i {
  color: var(--ltn__secondary-color);
  margin-right: 5px;
}
.ltn__footer-blog-item .ltn__blog-title {
  font-size: 20px;
}

/* ----------------------------------------------------
    Copyright Area
---------------------------------------------------- */
.ltn__copyright-area {
  padding-top: 25px;
  padding-bottom: 25px;
}
.ltn__copyright-area .payment-method {
  text-align: right;
}

.ltn__copyright-design h6,
.ltn__copyright-design h4 {
  font-size: 14px;
  font-family: var(--ltn__body-font);
  font-weight: 700;
}

.ltn__copyright-design p {
  margin-bottom: 0;
}

.ltn__copyright-menu ul {
  margin: 0;
  padding: 0;
}
.ltn__copyright-menu ul li {
  list-style: none;
  display: inline-block;
  margin-top: 0;
  margin-right: 20px;
  font-size: 14px;
  font-family: var(--ltn__heading-font);
  font-weight: 600;
}
.ltn__copyright-menu ul li:last-child {
  margin-right: 0;
}
.ltn__copyright-menu ul li a {
  margin: 0;
}

/* ----------------------------------------------------
    Responsive
---------------------------------------------------- */
@media (max-width: 991px) {
  .ltn__footer-timeline-widget {
    padding: 50px 25px 40px;
  }
  .ltn__copyright-area {
    text-align: center;
  }
  .ltn__copyright-area .payment-method {
    text-align: center;
    margin-top: 15px;
  }
  .ltn__copyright-area .site-logo-wrap {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ltn__copyright-area .ltn__copyright-menu {
    text-align: center !important;
  }
  #scrollUp {
    font-size: 16px;
    height: 30px;
    width: 30px;
    bottom: 50px;
  }
  #scrollUp i {
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  .ltn__footer-timeline-widget {
    padding: 50px 25px 40px;
  }
  .footer-menu-widget-2-column {
    padding-left: 0;
  }
  .ltn__copyright-menu {
    margin-top: 25px;
  }
  .ltn__footer-2 .footer-widget p,
  .ltn__footer-2 .footer-widget li {
    font-size: 14px;
  }
  .ltn__footer-2 .footer-widget .ltn__blog-meta li,
  .footer-widget .ltn__blog-meta li {
    font-size: 12px;
  }
  .ltn__footer-2 .footer-menu ul li {
    margin-bottom: 20px;
  }
  .ltn__footer-blog-item .ltn__blog-title {
    font-size: 18px;
  }
}

@media (max-width: 575px) {
  .ltn__footer-timeline-widget {
    padding: 50px 25px 40px;
  }
  .footer-menu-widget-2-column .footer-menu {
    width: 100%;
  }
  .footer-menu-widget-2-column .footer-menu:nth-child(odd) {
    padding-left: 0px;
  }
  .ltn__copyright-menu {
    margin-top: 25px;
  }
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */
/* -------------------------
    ## Preloader Css
--------------------------- */
.pre-wrap {
  position: fixed;
  content: "";
  -webkit-transform: translate(-100%, -240%);
  -ms-transform: translate(-100%, -240%);
  transform: translate(-100%, -240%);
  font-size: 62px;
}

.preloader-inner {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  background-color: #071c1f;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.preloader-inner .cancel-preloader {
  position: absolute;
  bottom: 30px;
  right: 30px;
}
.preloader-inner .cancel-preloader a {
  background-color: var(--white);
  font-weight: 600;
  text-transform: capitalize;
  color: var(--ltn__primary-color);
  width: 200px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 30px;
  display: block;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.preloader-inner .cancel-preloader a:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.spinner {
  margin: 120px auto;
  width: 60px;
  height: 60px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
}

.dot1,
.dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: var(--ltn__secondary-color);
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.dot2 {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

/*----------------------------------------
  Search Popup
----------------------------------------*/
/* ---------------------------------------
    ## Button
--------------------------------------- */
.btn-wrapper {
  display: block;
  margin-top: 30px;
}

.btn {
  border-radius: 0;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  font-family: var(--ltn__heading-font);
  padding: 17px 40px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  z-index: 1;
  margin-right: 15px;
}
.btn:last-child {
  margin-right: 0;
}

.theme-btn-1 {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}
.theme-btn-1:hover {
  background-color: var(--ltn__primary-color);
  color: var(--white);
}

.theme-btn-2 {
  background-color: var(--ltn__primary-color);
  color: var(--white);
}
.theme-btn-2:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.theme-btn-3 {
  background-color: transparent;
  border: 2px solid var(--border-color-9);
}
.theme-btn-3:hover {
  background-color: var(--ltn__secondary-color);
  border-color: var(--ltn__secondary-color);
  color: var(--white);
}

.reverse-color {
  background-color: var(--ltn__secondary-color);
}
.reverse-color:hover {
  background-color: var(--ltn__primary-color);
}

.btn-white {
  background-color: var(--white);
  color: var(--ltn__primary-color);
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
}
.btn-white:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.btn-black {
  background-color: var(--black);
  color: var(--white);
}
.btn-black:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.btn-transparent {
  background-color: transparent;
  -webkit-box-shadow: var(--ltn__box-shadow-3);
  box-shadow: var(--ltn__box-shadow-3);
  border-color: #ddd;
}
.btn-transparent:hover {
  background-color: var(--ltn__secondary-color);
  color: var(--white);
}

.btn-round {
  border-radius: 50px;
}

.btn-opacity-6 {
  opacity: 0.6;
}
.btn-opacity-6:hover {
  opacity: 1;
}

.btn-opacity-7 {
  opacity: 0.7;
}
.btn-opacity-7:hover {
  opacity: 1;
}

.btn-opacity-8 {
  opacity: 0.8;
}
.btn-opacity-8:hover {
  opacity: 1;
}

.btn-opacity-9 {
  opacity: 0.9;
}
.btn-opacity-9:hover {
  opacity: 1;
}

.btn:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-effect-1:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--white);
}

.btn-effect-1:hover {
  color: var(--ltn__heading-color);
}
.btn-effect-1:hover::after {
  width: 100%;
}

.btn-effect-2:after {
  width: 0%;
  height: 100%;
  top: 0;
  right: 0;
  background: var(--white);
}

.btn-effect-2:hover {
  color: var(--ltn__heading-color);
}
.btn-effect-2:hover::after {
  width: 100%;
}

.btn-effect-3:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--ltn__primary-color);
}

.btn-effect-3:hover {
  color: var(--white);
}
.btn-effect-3:hover::after {
  width: 100%;
}

.btn-effect-4:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--ltn__secondary-color);
}

.btn-effect-4:hover {
  color: var(--white);
}
.btn-effect-4:hover::after {
  width: 100%;
}

.btn-full-width {
  display: block;
}

.btn-full-width-2 {
  display: block;
  width: 100%;
}

.btn-border {
  border: 2px solid var(--border-color-10);
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .btn {
    padding: 12px 25px;
  }
  [type="submit"].btn {
    padding: 15px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  [type="submit"].btn {
    padding: 12px 30px;
  }
}

.quarter-overlay {
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  left: 0;
  display: none;
  background: #222;
}
.cv-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner {
  width: 40px;
  height: 40px;
  border: 4px #ddd solid;
  border-top: 4px solid;
  border-radius: 50%;
  animation: sp-anime 0.8s infinite linear;
  border-top-color: var(--ltn__secondary-color);
}
@keyframes sp-anime {
  100% {
    transform: rotate(360deg);
  }
}
.is-hide {
  display: none;
}

/* ----------------------------------------------------
    END
---------------------------------------------------- */

/*# sourceMappingURL=style.css.map */
