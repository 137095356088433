/* -------------------------------------
    Google fonts
------------------------------------- */
/*
 @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700|Roboto:400,500,700&display=swap');
/*
font-family: 'Open Sans', sans-serif;
font-family: 'Roboto', sans-serif;
*/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Rajdhani:wght@400;500;600;700&display=swap');
/*
font-family: 'Open Sans', sans-serif;
font-family: 'Rajdhani', sans-serif;
*/

/* ----------------------------------------------------
    Normalize
---------------------------------------------------- */
:root {
    --ltn__primary-color: #071c1f;
    --ltn__secondary-color: #e53e29;
    --ltn__heading-color: #071c1f;
    --ltn__paragraph-color: #071c1f;
    
    --ltn__heading-font: 'Rajdhani', sans-serif;
    --ltn__body-font: 'Open Sans', sans-serif;

    /* Background Colors */
    --section-bg-1: #F2F6F7; /* White */
    --section-bg-2: #071c1f; /* Black */

    /* Border Colors */ 
    --border-color-1: #e5eaee;
    --border-color-2: #1e2021;
    --border-color-3: #ced4da;

    /* Footer  */
    --ltn__color-1: #8cb2b2;

    /* Common Colors */ 
    --black: #000000;
    --white: #fff;
    --white-2: #F2F6F7;
    --white-3: #e8edee;
    --red: #FF0000;
    --gray: #808080;
    --maroon: #800000;
    --yellow: #FFFF00;
    --green: #008000;
    --blue: #0000FF;
    --navy: #000080;
    --pink: #FFC0CB;
    --orange: #ffa500;

}

/* -------------------------------------
    Typography
------------------------------------- */
html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    font-family: var(--ltn__body-font);
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -moz-osx-font-smoothing: grayscale;
    /* Firefox */
    -webkit-font-smoothing: antialiased;
    /* WebKit  */
}
body {
    color: var(--ltn__paragraph-color);
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    font-family: var(--ltn__body-font);
    line-height: 1.8;
    margin: 0 auto;
}

h1 {
    font-size: 36px;
    line-height: 1.2;
}
h2 {
    font-size: 30px;
}
h3 {
    font-size: 24px;
}
h4 {
    font-size: 20px;
}
h5 {
    font-size: 18px;
}
h6 {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--ltn__heading-color);
    clear: both;
    font-family: var(--ltn__heading-font);
    font-weight: 700;
    line-height: 1.3;
    margin-bottom: 15px;
}

p {
    color: var(--ltn__paragraph-color);
    margin-bottom: 1.5em;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
a {
    color: inherit;
    text-decoration: none;
    color: var(--ltn__paragraph-color);
    transition: all 0.3s ease 0s;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
    color: inherit;
}
a:hover {
    color: var(--ltn__secondary-color);
}
button,
input,
optgroup,
select,
textarea {
    font-family: var(--ltn__body-font);
}
pre,
code,
kbd,
tt,
var,
samp {
    font-family: var(--ltn__body-font);
}
pre {
    word-break: break-word;
}

a i {
    padding: 0 2px;
}

img {
    max-width: 100%;
}
pre {
    background-color: #f5f5f5;
    padding: 35px 0 10px;
}
ul li,
ol li {
    margin-top: 10px;
}

/* ol {
    counter-reset: counter;
    padding-left: 0;
}

ol li {
    list-style: none;
    margin-top: 1rem;
}

ol li:before {
    counter-increment: counter;
    content: counter(counter);
    font-weight: 500;
    margin-right: 10px;
} */
/* ol ul {
    list-style-type: circle;
    margin-block-start: 0px;
    margin-block-end: 0px;
} */
.text-decoration {
    text-decoration: underline;
}
.ltn__language-html {
    padding: 20px;
    margin: 25px 0;
    border-left: 4px solid;
    border-color: var(--ltn__secondary-color); 
    background-color: rgba(229, 62, 41, 0.05);
}
.ltn__language-html p {
    margin-bottom: 0; 
}
.ltn__language-html p + p {
    margin-top: 20px; 
}
/* ----------------------------------------------
00.  Scrollbar
---------------------------------------------- */
.ltn__scrollbar {
    overflow-y: auto; 
}  
.ltn__scrollbar::-webkit-scrollbar {
    width: 2px;
    background-color: #f5f5f5;
    border-radius: 30px;
}    
.ltn__scrollbar::-webkit-scrollbar-thumb {
    background-color: #ddd; 
}

/* -------------------------------------
    Custom Class
------------------------------------- */
.section-bg-1 {
    background-color: var(--section-bg-1);
}

.ltn__separate-line .separate-icon {
    background-color: var(--section-bg-1);
}


.section-bg-2 {
    background-color: var(--section-bg-2);
}
.section-bg-2 h1,
.section-bg-2 h2,
.section-bg-2 h3,
.section-bg-2 h4,
.section-bg-2 h5,
.section-bg-2 h6,
.section-bg-2 p,
.section-bg-2 li,
.section-bg-2 i,
.section-bg-2 span,
.section-bg-2 tr,
.section-bg-2 td {
    color: var(--white);
}
.section-bg-2 .ltn__separate-line .separate-icon {
    background-color: var(--black-2);
}
.ltn__primary-color {
    color: var(--ltn__primary-color) !important;
}
.ltn__secondary-color {
    color: var(--ltn__secondary-color) !important;
}

/* ----------------------------------------------------
    Menu in Sidebar
---------------------------------------------------- */
.btn-wrapper  {
    display: block;
    margin-top: 30px;
}
.btn {
    border-radius: 0;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    font-family: var(--ltn__heading-font);
    padding: 17px 40px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 1;
    margin-right: 15px;
}
.btn:last-child {
    margin-right: 0;
}
.theme-btn-1 {
    background-color: var(--ltn__secondary-color);
    color: var(--white);
}
.theme-btn-1:hover{
    background-color:  var(--ltn__primary-color);
    color: var(--white);
}
.theme-btn-2 {
    background-color:  var(--ltn__primary-color);
    color: var(--white);
}
.theme-btn-2:hover{
    background-color: var(--ltn__secondary-color);
    color: var(--white);
}
/* ----------------------------------------------------
    Menu in Sidebar
---------------------------------------------------- */
.ltn__utilize {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: auto;
    left: 0;
    display: block;
    overflow: hidden;
    width: 400px;
    height: 100vh;
    padding: 50px 40px;
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    background-color: var(--white-2);
    -webkit-box-shadow: none;
    box-shadow: none;
	z-index: 99999;
    /* box-shadow: rgba(23, 44, 82, 0.1) 0px 5px 20px 0px; */
    border-right: 1px solid var(--border-color-3);
}
@media only screen and (max-width: 479px) {
    .ltn__utilize {
        width: 300px;
    }
}
/* .ltn__utilize.ltn__utilize-mobile-menu {
    right: auto;
    left: 0;
    padding: 50px 40px;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
} */
.ltn__utilize-menu-inner {
    position: relative;
    z-index: 9;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    touch-action: auto;
    overflow-x: hidden !important;
}
.ltn__utilize-menu-search-form {
    margin-bottom: 30px;
    position: relative;    
}
.ltn__utilize-menu-search-form input[type="text"] {
	margin-bottom: 0;
}
.ltn__utilize-menu-search-form button {
	background-color: transparent;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	height: 100%;
	padding: 0 15px;
}

.ltn__utilize-menu>ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ltn__utilize-menu>ul>li {
    position: relative;
    border-bottom: 1px solid var(--border-color-1);
    margin-top: 0;
}
.ltn__utilize-menu>ul>li .menu-expand {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    width: 24px;
    height: 44px;
    cursor: pointer;
    background-color: transparent;
}
.ltn__utilize-menu>ul>li .menu-expand::before, 
.ltn__utilize-menu>ul>li .menu-expand::after {
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 7px);
    width: 14px;
    height: 2px;
    content: "";
    -webkit-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    transition: all .5s ease 0s;
    -webkit-transform: scale(0.75);
    -ms-transform: scale(0.75);
    transform: scale(0.75);
    background-color: #7e7e7e;
}
.ltn__utilize-menu>ul>li .menu-expand::after {
    -webkit-transform: rotate(90deg) scale(0.75);
    -ms-transform: rotate(90deg) scale(0.75);
    transform: rotate(90deg) scale(0.75);
}
.ltn__utilize-menu>ul>li>a {
    display: block;
    padding: 8px 24px 8px 0;
    text-transform: uppercase;
    color: var(--ltn__primary-color);
    font-size: 14px;
    font-weight: 700;
}
.ltn__utilize-menu>ul>li .sub-menu {
    position: static;
    top: auto;
    display: none;
    visibility: visible;
    width: 100%;
    min-width: auto;
    padding: 0;
    padding-left: 15px;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
}
.ltn__utilize-menu>ul>li .sub-menu li {
    line-height: inherit;
    position: relative;
    list-style: none;
}
.ltn__utilize-menu>ul>li .sub-menu li:last-child {
    margin-bottom: 15px;
}
.ltn__utilize-overlay {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    background-color: rgba(0,0,0,0.5);
}


/* .ltn__utilize.ltn__utilize-open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}
.ltn__utilize.ltn__utilize-mobile-menu.ltn__utilize-open {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
} */


.mobile-menu-toggle {
    margin-right: 15px;
}
.mobile-menu-toggle>a {
    width: 24px;
    height: 32px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #333;
    margin-left: auto;
}
.ltn__utilize-toggle span {
	display: block;
}
.mobile-menu-toggle svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 60px;
    margin-top: -2px;
    margin-left: -2px;
    cursor: pointer;
    -webkit-transform: translate3d(-50%,-50%,0);
    transform: translate3d(-50%,-50%,0);
}
.mobile-menu-toggle svg path {
    -webkit-transition: stroke-dashoffset .5s cubic-bezier(0.25,-0.25,0.75,1.25),stroke-dasharray .5s cubic-bezier(0.25,-0.25,0.75,1.25);
    -o-transition: stroke-dashoffset .5s cubic-bezier(0.25,-0.25,0.75,1.25),stroke-dasharray .5s cubic-bezier(0.25,-0.25,0.75,1.25);
    transition: stroke-dashoffset .5s cubic-bezier(0.25,-0.25,0.75,1.25),stroke-dasharray .5s cubic-bezier(0.25,-0.25,0.75,1.25);
    fill: none;
    stroke: #333;
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-width: 30px;
}
.mobile-menu-toggle svg path#top, .mobile-menu-toggle svg path#bottom {
    stroke-dasharray: 240px 950px;
}
.mobile-menu-toggle svg path#middle {
    stroke-dasharray: 240px 240px;
}
.mobile-menu-toggle .close svg path#top, .mobile-menu-toggle .close svg path#bottom {
    stroke-dasharray: -650px;
    stroke-dashoffset: -650px;
}
.mobile-menu-toggle .close svg path#middle {
    stroke-dasharray: 1px 220px;
    stroke-dashoffset: -115px;
}
.ltn__utilize-menu-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-bottom: 15px;
    border-bottom: 1px solid #eceaea;
}
.ltn__utilize-menu-head .ltn__utilize-menu-title {
	font-weight: 700;
}
.ltn__utilize-menu-head .ltn__utilize-close {
	background-color: transparent;
	font-size: 30px;
	padding: 0 15px;
}
.ltn__utilize-buttons {
    border-top: 1px solid var(--border-color-1);
    padding: 5px 0px 20px;
    margin: 30px 0;
    border-bottom: 1px solid var(--border-color-1);
}
.ltn__utilize-buttons ul {
	padding: 0;
	margin: 0;
}
.ltn__utilize-buttons li {
	display: inline-block;
}
.ltn__utilize-buttons .utilize-btn-icon {
	width: 50px;
	display: inline-block;
	height: 50px;
	border: 2px solid var(--border-color-1);
	line-height: 46px;
	text-align: center;
	margin-right: 10px;
}

@media (max-width: 991px) {
	.ltn__utilize {
		-webkit-transform: translateX(-100%);
		-ms-transform: translateX(-100%);
		transform: translateX(-100%);
	}
	.ltn__utilize.ltn__utilize-open {
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		transform: translateX(0);
    }
}


/* ----------------------------------------------------
   END
---------------------------------------------------- */